import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { numberToCurrency } from "../lib/money";
import { makeStyles } from "@material-ui/core/styles";
import EditableLabel from "components/editable-label";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "./product-selection.css";

const ProductSelection = ({ productPrice, priceList, onCoverageChange, onTypeChange }) => {
  const classes = useStyles();
  const coverageLimit = productPrice?.coverageLimit ?? 0;
  
  const productPrices = useMemo(() => {
      return priceList.filter(p => p.productType === productPrice.productType);
  }, [productPrice, priceList]);
  
  const proprtyTypes = [
    { value: "RESIDENTIAL", display: "Residence" },
    { value: "BUSINESS", display: "Business" },
  ];
  
  const handlePropertyTypeChange = (ev) => {
    onTypeChange(ev.target.value);
  }

  const handleCoverageChange = (ev) => {
    const coverageId = ev.target.value;
    const selected = priceList.find(x => x.id === coverageId);
    onCoverageChange(selected);
  };

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography data-test="coverage-amount__text" className={classes.desc} variant="body1">
                Coverage Amount
              </Typography>
            </TableCell>
            <TableCell align="right">
              {priceList.length > 1 && (
                <FormControl
                  className="hide-legend"
                  variant="outlined"
                  hiddenLabel={true}
                >
                  {/* <InputLabel>Coverage</InputLabel> */}
                  <Select
                    className="tighter-padding"
                    data-test="coverage-amount__dropdown"
                    value={productPrice.id}
                    onChange={handleCoverageChange}
                    label="Coverage"
                    inputProps={{
                      "aria-label": "Coverage select",
                    }}
                  >
                    {productPrices.map((p) => (
                      <MenuItem data-test={"coverage-amount__" + p.coverageLimit} key={p.id} value={p.id}>
                        {numberToCurrency(p.coverageLimit)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {productPrices.length === 1 && (
                <>
                  {numberToCurrency(productPrice.coverageLimit, {
                    truncateZeroCents: true,
                  })}{" "}
                  of coverage
                </>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography className={classes.desc} data-test="deductible__title" variant="body1">
                Deductible
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className={classes.value} data-test="deductible__value" variant="body1">
                $0
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography data-test="property-type__title" className={classes.desc} variant="body1">
                Property Type
              </Typography>
            </TableCell>
            <TableCell align="right">
              <FormControl
                className="hide-legend"
                variant="outlined"
                hiddenLabel={true}
              >
                <Select
                  className="tighter-padding select-width"
                  data-test="property-type__dropdown"
                  value={productPrice.productType}
                  onChange={handlePropertyTypeChange}
                  label="Coverage"
                  inputProps={{
                    "aria-label": "Coverage select",
                  }}
                >
                  {proprtyTypes.map(p => (
                    <MenuItem data-test={"property-type__" + p.display} key={p.value} value={p.value}>
                      {p.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProductSelection.propTypes = {
  productPrice: PropTypes.object,
  priceList: PropTypes.array,
  onCoverageChange: PropTypes.func,
  onTypeChange: PropTypes.func,
};

const useStyles = makeStyles(() => ({
  cityZip: {
    position: "relative",
  },
  zip: {
    position: "absolute",
    bottom: "-6px",
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    width: "100%",
  },
  city: {
    display: "block",
    margin: "0 auto",
    width: "140px",
  },
  banner: {
    display: "block",
    margin: "-10px auto 0",
  },
  table: {
    maxWidth: "360px",
    margin: "0 auto",
  },
  desc: {
    fontWeight: "bold",
  },
}));

export default ProductSelection;
