import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "actions/onboarding";
import { setSession } from "actions/session";
import { setQuote } from "actions/quote";
import Slide from "onboarding/components/slide";
import { PATHS } from "../onboarding-app";
import AddressForm from "components/address-form";
import { apiClient, useClientRequest } from "api";
import { Box, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { HelpAlert } from "components/help-alert";

const Address = ({ history, location, nextPath }) => {
  const { actions, state } = useStateMachine({
    setOnboarding,
    setSession,
    setQuote,
  });
  const [loading, setLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const toPath = location.state?.toPath ?? nextPath;

  // GET session
  const { data: sessionData, loading: getSessionLoading } = useClientRequest(
    "/api/v2/auth/getSession",
    {
      name: "get_session",
      onComleted: (data) => {
        if (!data) {
          return;
        }

        actions.setSession(data);
      },
    }
  );

  const meta = useMemo(() => {
    if (!sessionData || !sessionData.Meta) {
      return {};
    }

    return JSON.parse(sessionData.Meta);
  }, [sessionData]);

  const onSubmitSuccess = async (address) => {
    window.heap && window.heap.addUserProperties({ "Zip Code": address.zip });
    setLoading(true);

    if (compareAddress(state.onboarding.address, address)) {
      history.push(toPath);
      return;
    }

    // update state
    actions.setOnboarding({
      address: {
        id: state.session.id,
        address1: address.address1,
        address2: address.address2,
        addressType: address.addressType,
        city: address.city,
        countryCode: address.countryCode,
        provinceCode: address.provinceCode,
        zip: address.zip,
      },
    });

    try {
      // Update `meta` address info
      const response = await apiClient.post(
        "/api/v2/jumpstart/setSessionData",
        {
          ...meta,
          address1: address.address1,
          address2: address.address2 || null,
          addressType: address.addressType,
          city: address.city,
          state: address.provinceCode,
          zip: address.zip,
          paymentInterval: state.quote?.paymentInterval ?? "Monthly",
        }
      );

      if (
        response.status !== 200 ||
        !Object.prototype.hasOwnProperty.call(response.data, "Meta")
      ) {
        throw "Error updating session";
      }

      const updatedMeta = JSON.parse(response.data.Meta);

      // create quote using `address`
      const createResponse = await apiClient.post("/api/v2/jumpstart/quote");
      if (
        createResponse.status !== 200 ||
        !Object.prototype.hasOwnProperty.call(
          createResponse.data,
          "quote_number"
        ) ||
        !createResponse.data.quote_number
      ) {
        throw "Error creating quote";
      }

      // Update quote using `meta` - excluding address data
      const updatePayload = buildPayload(updatedMeta, {
        addr1: address.address1,
        addr2: address.address2,
        city: address.city,
        state: address.provinceCode,
        zip: address.zip,
      });

      const updateResponse = await apiClient.put("/api/v4/direct/quotes", {
        ...createResponse.data,
        ...updatePayload,
      });

      if (
        updateResponse.status !== 200 ||
        !Object.prototype.hasOwnProperty.call(
          updateResponse.data,
          "quote_number"
        ) ||
        !updateResponse.data.quote_number
      ) {
        throw "Error updating quote";
      }

      // update state
      actions.setQuote(updateResponse.data);
      setLoading(false);
      // go next or to price change
      if (state.onboarding.quoteZip !== updateResponse.data.zip) {
        history.push(`/${PATHS.BASE}/${PATHS.PRICE_CHANGE}`, {
          toPath: toPath,
          prevPath: location.pathname,
        });
      } else {
        history.push(toPath);
      }
    } catch (e) {
      setUpdateError(typeof e === "string" ? e : e.toString());
      setLoading(false);
    }
  };

  const addressType = useMemo(() => {
    if (!state.onboarding.address) {
      return state.onboarding.checkoutPrice?.productType;
    }

    return state.onboarding.address.addressType;
  }, [state.onboarding.address, state.onboarding.checkoutPrice]);

  return (
    <Slide
      title={
        <div className="flex flex-col items-center justify-center" data-test="address__title">
          <div>Thanks, {state.onboarding.firstName}.</div>
          <div>Now, what address would you like to insure?</div>
        </div>
      }
    >
      <AddressForm
        loading={getSessionLoading || loading}
        error={updateError}
        address={state.onboarding.address}
        addressType={addressType}
        onSubmitSuccess={onSubmitSuccess}
        submitText="Next"
        userId={state.onboarding.userId}
      />
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={640}
        mx="auto"
        mt={4}
      >
        {updateError && (
          <>
            <Box>
              <Alert severity="error">
                <>
                  <AlertTitle>Error</AlertTitle>
                  <Typography>{updateError}</Typography>
                </>
              </Alert>
            </Box>
            <Box my={2}>
              <HelpAlert />
            </Box>
          </>
        )}
      </Box>
    </Slide>
  );
};

// buildPayload takes current sessionData.Meta
// along with a previously stored quote in state
// and builds a new payload used for updating a newly created quote
function buildPayload(meta, quote) {
  const {
    firstName: first_name,
    lastName: last_name,
    email,
    phone,
    coverageAmount,
    paymentInterval,
  } = meta;
  const { addr1, addr2, city, state, zip } = quote;
  let updatePayload = {
    first_name,
    last_name,
    email,
    phone,
    mailing_addr1: addr1,
    mailing_addr2: addr2,
    mailing_city: city,
    mailing_state: state,
    mailing_zip: zip,
    is_mailing_address_as_property: false,
  };

  if (quote?.earthquakeCoverage || coverageAmount) {
    updatePayload = {
      ...updatePayload,
      earthquakeCoverage: quote?.earthquakeCoverage ?? coverageAmount,
    };
  }

  if (quote?.paymentInterval || paymentInterval) {
    updatePayload = {
      ...updatePayload,
      paymentInterval: quote?.paymentInterval ?? paymentInterval,
    };
  }

  if (quote?.companyName) {
    updatePayload = {
      ...updatePayload,
      companyName: quote.companyName,
    };
  }

  if (quote?.addr2 || meta?.address2) {
    updatePayload = {
      ...updatePayload,
      addr2: quote?.addr2 ?? meta.address2,
    };
  }

  return updatePayload;
}

// compareAddress returns true if addressA
// and addressB are equal
function compareAddress(addressA, addressB) {
  if (!addressA || !addressB) {
    return false;
  }

  return (
    addressA.address1 === addressB.address1 &&
    addressA.address2 === addressB.address2 &&
    addressA.city === addressB.city &&
    addressA.city === addressB.city &&
    addressA.state === addressB.state &&
    addressA.zip === addressB.zip
  );
}

Address.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  nextPath: PropTypes.string,
};

export default Address;
