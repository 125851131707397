import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

export const NextButton = ({
  children,
  component,
  disabled,
  onClick,
  to,
  type,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, className)}
      component={component ? component : to ? RouterLink : "button"}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      size="large"
      to={to}
      type={type || undefined}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
};

NextButton.propTypes = {
  children: PropTypes.node,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  tyoe: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "11px 70px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export const PrimaryButton = ({
  children,
  disabled,
  onClick,
  to,
  type,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, className)}
      component={"button"}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      size="large"
      to={to}
      type={type || undefined}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.node,
  component: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  tyoe: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};
