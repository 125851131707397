import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { Redirect } from "react-router-dom";
import { setOnboarding } from "../../actions/onboarding";
import { PATHS } from "../onboarding-app";

const CoverageLimitDeeplink = ({ history, location, coverageLimit }) => {
  const { actions, state } = useStateMachine({ setOnboarding });

  useEffect(() => {
    actions.setOnboarding({
      productId: null,
      productPrices: null,
      checkoutPrice: null,
      coverageLimit,
    });
  });

  return <Redirect to={`/${PATHS.BASE}`} />;
};

CoverageLimitDeeplink.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  coverageLimit: PropTypes.number,
};

export default CoverageLimitDeeplink;
