import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { find } from "lodash";

import Slide from "onboarding/components/slide";
import { numberToCurrency } from "lib/money";
import ZipIcon from "components/zip-icon";
import PriceDisplay from "components/price-result";
import { useClientMutation, useClientRequest } from "api";
import { setJumpstartSession, setSession } from "actions/session";
import { setOnboarding } from "actions/onboarding";

import { Box, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { HelpAlert } from "components/help-alert";
import { getProductKey } from "lib/hash-code";

const PriceResult = ({ nextPath }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [screenError, setScreenError] = useState(null);
  const { actions, state } = useStateMachine({
    setJumpstartSession,
    setOnboarding,
    setSession,
  });

  const coverageValue = useMemo(() => {
    if (!state.onboarding.checkoutPrice) {
      return find(state.onboarding.productPrices, {
        productId: state.onboarding.productId,
      });
    }

    return {
      ...state.onboarding.checkoutPrice,
      id: getProductKey(state.onboarding.checkoutPrice),
    };
  }, [
    state.onboarding.checkoutPrice,
    state.onboarding.productId,
    state.onboarding.productPrices,
  ]);

  useClientRequest("/api/v2/jumpstart/getSessionData", {
    name: "get_account_session",
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      actions.setJumpstartSession(data);
    },
    onError: () => {
      setScreenError(
        "We were unable to retrieve your session data. Please try again in a few minutes."
      );
    },
  });

  const { request: setSessionData } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      name: "set_session_coverage",
      method: "POST",
      onCompleted: (data) => {
        if (!data) {
          return;
        }

        actions.setSession(data);
        setLoading(false);
        history.push(nextPath);
      },
      onError: (e) => {
        if (e?.name === "NetworkError") {
          setScreenError(
            "There was an issue encountered with your network. Please check your connection and try again"
          );
        } else if (e?.name === "TypeError") {
          setScreenError(
            "There was an unexpected issue encountered. Please check your connection and try again"
          );
        } else {
          setScreenError(e.toString());
        }
      },
    }
  );

  const onCoverageChange = (coverage) => {
    actions.setOnboarding({ checkoutPrice: coverage });
    actions.setOnboarding({ checkoutPrice: coverage });
  };

  const onTypeChange = (propertyType) => {
    if (propertyType !== coverageValue.productType) {
      const newValue = state.onboarding.productPrices
        .filter((p) => p.productType === propertyType)
        .reduce((a, b) => (a.coverageLimit < b.coverageLimit ? a : b));
      actions.setOnboarding({ checkoutPrice: newValue });
    }
  };

  const onNext = async () => {
    setLoading(true);
    actions.setOnboarding({
      checkoutPrice: coverageValue,
    });

    await setSessionData({
      body: {
        ...state.session,
        coverageAmount: coverageValue.coverageLimit,
      },
    });
  };

  return (
    <Slide
      data-test="result__title"
      title={
        <>
          Your monthly price is{" "}
          {numberToCurrency(coverageValue.monthlyTotal, {
            truncateZeroCents: true,
          })}
          <sup>*</sup>
        </>
      }
      iconComponent={<ZipIcon zip={state.onboarding.quoteZip} />}
    >
      <Box
        mb={6}
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>
          You can modify the coverage options below and the price will update
          automatically
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" maxWidth={640} mx="auto">
        {screenError && (
          <>
            <Box>
              <Alert severity="error">
                <>
                  <AlertTitle>Error</AlertTitle>
                  <Typography>{screenError}</Typography>
                </>
              </Alert>
            </Box>
            <Box my={2}>
              <HelpAlert />
            </Box>
          </>
        )}
      </Box>
      <PriceDisplay
        loading={loading}
        nextPath={nextPath}
        productPrice={coverageValue}
        productPrices={state.onboarding.productPrices}
        onCoverageChange={onCoverageChange}
        onTypeChange={onTypeChange}
        onNext={onNext}
      />
    </Slide>
  );
};

PriceResult.propTypes = {
  nextPath: PropTypes.string,
};

export default PriceResult;
