import React, {useContext} from 'react'
import {Redirect} from 'react-router-dom'
import {PATHS} from 'onboarding'
import ConfigContext from 'config-context'

const Chat = props => {
  const config = useContext(ConfigContext)
  const pathname = config.redirectFaq ? `/${PATHS.BASE}` : '/faq'

  return <Redirect to={{pathname, state: { chat: true }}} />
}

export default Chat