import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import CheckboxFormControlLabel from "./checkbox-form-control-label";
import { NextButton } from "./buttons";
import { format as formatDate } from "date-fns";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "lodash";

const useStyles = makeStyles((theme) => ({
  fullWidthFormItem: {
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  baseError: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

const EndorsementForm = ({
  address,
  onSubmitSuccess,
  policy,
  productPrice,
  user,
}) => {
  const { register, handleSubmit, errors, formState } = useForm();
  const { isSubmitting } = formState;
  const classes = useStyles();
  const [baseError, setBaseError] = useState(null);

  const onSubmit = (form) => {
    let variables = {
      input: {
        attributes: {
          addressId: address.id,
          name: form.name,
          policyId: policy.id,
          productId: productPrice.productId,
          signature: form.signature,
          userId: user.id,
        },
      },
    };

    // createEndorsement({ variables });
  };

  const isBusiness = address.addressType === "BUSINESS";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        <TextField
          className={classes.fullWidthFormItem}
          error={!!errors.name}
          defaultValue={policy.name}
          label={isBusiness ? "Name of business" : "Name on policy"}
          name="name"
          helperText={
            isBusiness
              ? "Please enter the name of the business at this address."
              : "Please enter the name of the policy holder."
          }
          inputRef={register({ required: true, minLength: 1 })}
          type="text"
          variant="outlined"
        />
      </Box>
      <Box mb={2}>
        <TextField
          error={!!errors.signature}
          defaultValue={policy.signature}
          label="Signature"
          name="signature"
          helperText="Please enter your legal name (e.g. the name on your bank account)."
          inputRef={register({ required: true, minLength: 1 })}
          type="text"
          variant="outlined"
          className={classes.fullWidthFormItem}
        />
      </Box>
      <Box width="100%">
        <FormControl
          required
          error={!!errors.ackSignature}
          component="fieldset"
        >
          <CheckboxFormControlLabel
            control={
              <Checkbox
                inputRef={register({ required: true })}
                name="ackSignature"
                color="primary"
              />
            }
            label={`Signed today, ${formatDate(new Date(), "MMM do, yyyy")}.`}
          />
          {!!errors.ackSignature && (
            <FormHelperText>You must sign!</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box width="100%">
        <FormControl
          required
          error={!!errors.ackElectronicSignature}
          component="fieldset"
        >
          <CheckboxFormControlLabel
            control={
              <Checkbox
                inputRef={register({ required: true })}
                name="ackElectronicSignature"
                color="primary"
              />
            }
            label="I agree to provide my signature electronically, and that my electronic signature is the same as my handwritten signature for the purposes of validity, enforceability, and admissibility."
          />
          {!!errors.ackElectronicSignature && (
            <FormHelperText>You must acknowledge!</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box mt={5} textAlign="center">
        <NextButton
          disabled={isSubmitting}
          type="submit"
        >
          Submit
        </NextButton>
        {baseError && (
          <FormHelperText error classes={{ root: classes.baseError }}>
            {baseError}!
          </FormHelperText>
        )}
      </Box>
    </form>
  );
};

EndorsementForm.propTypes = {
  address: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  policy: PropTypes.object,
  productPrice: PropTypes.object,
  user: PropTypes.object,
};

export default EndorsementForm;
