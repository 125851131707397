import React from 'react'
import PropTypes from 'prop-types'
import {Link} from '@material-ui/core'
import qs from 'query-string'

const SamplePolicyLink = ({params}) => {
  return (
    <Link href={`/sample-policy.pdf`} target="_blank">
      Read the full policy
    </Link>
  )
}

SamplePolicyLink.propTypes = {
  params: PropTypes.object
}

export default SamplePolicyLink