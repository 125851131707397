import React, {useContext} from 'react'
import PropTypes from "prop-types";
import Link from './link'
import ConfigContext from '../config-context'

const FaqLink = React.forwardRef((props, ref) => {
  const config = useContext(ConfigContext)
  const {children, to, ...rest} = props
  const newTo = config.redirectFaq ? undefined : to
  const href = newTo ? undefined : `//${config.marketingHost}${to}`
  const target = href ? "_blank" : undefined

  return (
    <Link to={newTo} href={href} target={target} aria-label="faq" {...rest} ref={ref}>
      {children}
    </Link>
  )
});

FaqLink.displayName = "FaqLink";

FaqLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
}

export default FaqLink