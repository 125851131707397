import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { NextButton } from "../components/buttons";
import { makeStyles } from "@material-ui/core/styles";
import HomeLink from "components/home-link";


export const EmailSent = ({ email, onResend }) => {
  const classes = useStyles();
  return (
    <>
        <Box mb={3}>
          <Box mb={5}>
            <Typography align="center" component="h1" variant="h4">
              Check your inbox!
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography
              align="center"
              component="h2"
              variant="h6"
              className="mvm"
            >
              We&apos;ve sent an email to:
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mb={2}>
            <Box
              border={1}
              p={2}
              borderColor="primary.main"
              className={classes.emailSent}
              textAlign="center"
            >
              <Typography align="center" component="p" variant="h5">
                {email}
              </Typography>
            </Box>
          </Box>
          <Box mb={5}>
            <Typography align="center" component="p">
              Click the link in the email to log in and access your account.
            </Typography>
          </Box>
          <Box textAlign="center">
            <NextButton component={HomeLink} underline="none">
              OK
            </NextButton>
          </Box>
        </Box>
        <Box mt={3} textAlign="center">
          <Button color="primary" onClick={() => onResend()}>
            Re-send email
          </Button>
        </Box>
      </>
  );
}

EmailSent.propTypes = {
  email: PropTypes.string,
  onResend: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  emailSent: {
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

