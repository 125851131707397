import React from "react";
import PropTypes from "prop-types";
import { Box, ButtonBase, FormHelperText, Typography } from "@material-ui/core";
import clsx from "clsx";
import { omit } from "lodash";
import { BusinessRounded, HomeRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useClientMutation } from "api";

const RESIDENTIAL = "RESIDENTIAL";
const BUSINESS = "BUSINESS";

const PropertyTypeForm = ({ address, error, loading, sessionData, onSubmitSuccess }) => {
  const classes = useStyles();

  const { request: updateSessionProduct } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      name: "update_product_type_session",
      method: "POST",
      onCompleted: (data) => {
        const parsed = JSON.parse(data.Meta);
        const addressObj = {
          address1: parsed.address1,
          address2: parsed.address2,
          addressType: parsed.addressType,
          city: parsed.city,
          id: data.Id,
          provinceCode: parsed.state,
          zip: parsed.zip,
        };
        // if (Array.isArray(data.userErrors) && data.userErrors.length > 0) {
        //   console.error('Error updating address!', data.addressUpdate.userErrors);
        // } else {
        onSubmitSuccess(addressObj);
        // }
      },
    }
  );

  const setAddressType = async (type) => {
    // const body = {
    //   ...omit(address, 'localities', 'secondaryDesignator', 'secondaryNumber'),
    //   addressType: type,
    // };

    await updateSessionProduct({
      body: {
        ...sessionData,
        addressType: type,
      },
    });
  };

  return (
    <>
      <Box display="flex" mt={5} alignItems="center" justifyContent="center">
        <ButtonBase
          disabled={loading}
          className={clsx(classes.residential, classes.option, {
            [classes.active]: address.addressType === RESIDENTIAL,
          })}
          focusVisibleClassName={classes.optionFocused}
          onClick={() => setAddressType(RESIDENTIAL)}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <HomeRounded className={classes.buildingIcon} />
            <Typography variant="h6">Residence</Typography>
          </Box>
        </ButtonBase>

        <ButtonBase
          disabled={loading}
          className={clsx(classes.business, classes.option, {
            [classes.active]: address.addressType === BUSINESS,
          })}
          focusVisibleClassName={classes.optionFocused}
          onClick={() => setAddressType(BUSINESS)}
        >
          <Box
            className={classes.wrapper}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <BusinessRounded className={classes.buildingIcon} />
            <Typography variant="h6">Business</Typography>
          </Box>
        </ButtonBase>
      </Box>
      {error && (
        <Box display="flex" mt={5} alignItems="center" justifyContent="center">
          <Typography color="error">{error.toString()}</Typography>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  option: {
    border: "1px solid #ccc",
    borderRadius: 16,
    [theme.breakpoints.down("xs")]: {
      width: 150,
      height: 150,
    },
    [theme.breakpoints.up("sm")]: {
      width: 175,
      height: 200,
    },
  },
  optionFocused: {
    borderColor: "red",
  },
  buildingIcon: {
    color: theme.palette.primary.main,
    fontSize: 72,
  },
  residential: {
    marginRight: 10,
  },
  business: {
    marginLeft: 10,
  },
  active: {
    borderColor: theme.palette.primary.main,
  },
}));

PropertyTypeForm.propTypes = {
  address: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  error: PropTypes.object,
  loading: PropTypes.bool,
  sessionData: PropTypes.object,
};

export default PropertyTypeForm;
