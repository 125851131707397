import React from 'react'
import {Box, Container, Grid, Link, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import BeckPuzzle from 'images/bounce-beck-puzzle.png'
import BeckPhone from 'images/bounce-beck-on-phone.png'
import BeckCelebrate from 'images/bounce-beck-arms-up-celebrate.png'
import Image from 'material-ui-image'

const BounceNoPaperwork = () => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
    >
      <Grid item xs={12}>
        <Container maxWidth="md" classes={{ maxWidthMd: classes.maxWidthMd }}>
          <Box py={8}>
            <Typography variant="h3" component="h2">
              Proactive claims.<br/>
              No delays, no excess, & low hassle.
            </Typography>
          </Box>
        </Container>

        <Container maxWidth="md">
          <Box pb={8}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Box mb={3}>
                  <Image style={{ marginTop: 60, paddingTop: 210 }} imageStyle={{ height: 210, width: 'inherit', left: '50%', transform: 'translate(-50%)' }} src={BeckPhone} aspectRatio={300/392} color="rgb(255,255,255,0)"/>
                </Box>
                <Typography>Affected by an earthquake exceeding the seismic<sup>*</sup> trigger? Bounce sends you a text message to confirm.</Typography>
              </Grid>
              <Grid item sm={4}>
                <Box mb={3}>
                  <Image style={{ paddingTop: 270 }} imageStyle={{ height: 270, width: 'inherit', left: '50%', transform: 'translate(-50%)' }} src={BeckPuzzle} aspectRatio={300/533} color="rgb(255,255,255,0)"/>
                </Box>
                <Typography>No need to jump through hoops to make a claim.</Typography>
              </Grid>
              <Grid item sm={4}>
                <Box mb={3}>
                  <Image style={{ marginTop: 60, paddingTop: 210 }} imageStyle={{ height: 210, width: 'inherit', left: '50%', transform: 'translate(-50%)' }} src={BeckCelebrate} aspectRatio={300/366} color="rgb(255,255,255,0)"/>
                </Box>
                <Typography>Once done, funds deposited into your bank account within days.</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box pb={8}>
            <Typography align="center" variant="body2"><sup>*</sup>A quake with a Peak Ground Velocity (PGV) of at least 20cm per second. <Link component={RouterLink} to={{pathname: "/faq", hash: "#how-do-you-determine-payment-eligibility"}} target="_blank">Read more</Link></Typography>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.light,
      borderTop: `1px solid ${theme.palette.primary.borderLight}`
    },
    maxWidthMd: {
      maxWidth: 640,
    },
  })
)

export default BounceNoPaperwork
