import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, Checkbox, FormControl } from '@material-ui/core'
import CheckboxFormControlLabel from './checkbox-form-control-label'
import { NextButton } from './buttons'
import Spinner from 'components/spinner'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTheme } from '@material-ui/core/styles'
import { useClientMutation, useClientRequest } from 'api'
import { Alert, AlertTitle } from '@material-ui/lab'

const PaymentForm = ({
  premium,
  price,
  interval,
  submitText,
  transactionFee,
  onSubmitSuccess,
  onSetSetupIntent,
  onClearIntent,
}) => {
  const [formLoading, setFormLoading] = useState(true)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [stripeError, setStripeError] = useState(false)
  const [card, setCard] = useState(null)
  const [invalidPaymentType, setInvalidPaymentType] = useState(false)

  const theme = useTheme()
  const location = useLocation()

  const stripe = useStripe()
  const elements = useElements()

  // setStripePaymentMethod
  const {
    request: setStripePaymentMethod,
    loading: setPaymentMethodLoading,
  } = useClientMutation('/api/v2/jumpstart/setStripePaymentMethod', {
    name: 'set_payment_method',
    method: 'PUT',
  })

  const handleCardChange = async (event) => {
    if (event.error) {
      setStripeError(event.error.message)
      return
    } else {
      setStripeError(false)
    }

    if (event.value.type === 'us_bank_account') {
      setInvalidPaymentType(true)
    } else {
      setInvalidPaymentType(false)
    }
  }

  const onSubmit = async (ev) => {
    ev.stopPropagation()
    ev.preventDefault()

    if (stripeLoading || formLoading || setPaymentMethodLoading) {
      return
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: location.href,
      },
    })

    if (error) {
      let message
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        message = error.message
        if (
          error.type === 'invalid_request_error' &&
          error.code === 'setup_intent_unexpected_state'
        ) {
          message += ' Please try again.'
          onClearIntent()
        }
      } else {
        message =
          'An unexpected error was encountered. Please try again or contact support'
      }

      setStripeError(message)
      setStripeLoading(false)
      return
    }

    const paymentElement = elements.getElement('payment')
    paymentElement.update({ readOnly: true })
    onSetSetupIntent(setupIntent)

    const { data } = await setStripePaymentMethod({
      args: {
        pmtId: setupIntent.payment_method,
      },
      headers: {
        'Content-Length': 0,
      },
    })

    const {
      cardBrand,
      cardExpirationMonth,
      cardExpirationYear,
      cardLast4,
      cardCountry,
    } = data

    const card = {
      brand: cardBrand,
      default: true,
      expMonth: cardExpirationMonth,
      expYear: cardExpirationYear,
      last4: cardLast4,
      zip: 'EMPTY',
      country: cardCountry,
    }

    setCard(card)

    setStripeError(null)
    // setup intent (API might set up and store in session)
    setStripeLoading(true)
    // collect payment details

    setStripeLoading(false)
    onSubmitSuccess(card)
  }

  const onFormReady = (el) => {
    setFormLoading(false)
    el.focus()
  }

  const loading = useMemo(() => {
    return stripeLoading || formLoading || setPaymentMethodLoading
  }, [stripeLoading, formLoading, setPaymentMethodLoading])

  return (
    <form onSubmit={onSubmit}>
      <label>
        {formLoading && <Spinner />}
        {invalidPaymentType && (
          <Box mb={2}>
            <Alert severity="warning">
              <AlertTitle data-test="phone__alert">
                Invalid payment type
              </AlertTitle>
              We currently do not support bank account payments for Jumpstart
              policies. Please select a different payment type.
            </Alert>
          </Box>
        )}
        <PaymentElement
          // className={classes.cardElement}
          onChange={handleCardChange}
          onReady={onFormReady}
          style={{ base: { fontSize: `${theme.typography.htmlFontSize}px` } }}
        />
        {stripeError && <FormHelperText error>{stripeError}</FormHelperText>}
      </label>
      <Box mt={5} textAlign="center">
        <NextButton
          data-test="payment__next"
          disabled={stripeLoading || formLoading || invalidPaymentType}
          type="submit"
        >
          {loading ? (
            <Spinner data-test="payment__spinner" size={26} color="inherit" />
          ) : (
            submitText
          )}
        </NextButton>
      </Box>
    </form>
  )
}

PaymentForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  submitText: PropTypes.string,
  userId: PropTypes.string,
  showCouponHint: PropTypes.bool,
  premium: PropTypes.number,
  setupIntent: PropTypes.object,
  transactionFee: PropTypes.number,
  price: PropTypes.number,
  onClearIntent: PropTypes.func,
  onSetSetupIntent: PropTypes.func,
  interval: PropTypes.string,
}

export default PaymentForm
