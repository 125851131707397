import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "actions/onboarding";
import { getSession, setSession, setJumpstartSession } from "actions/session";
import { setQuote } from "actions/quote";
import Slide from "onboarding/components/slide";
import { NextButton } from "components/buttons";
import PhoneVerificationWizard from "components/phone-verification-wizard";
import { useClientMutation, useClientRequest } from "api";

const PhoneVerification = ({ history, location, nextPath }) => {
  const { actions, state } = useStateMachine({setOnboarding, setSession, setJumpstartSession, setQuote});
  const toPath = location.state?.toPath ?? nextPath;
  const updatePhone = location.state?.updatePhone;

  // GET session
  const { data: sessionData } = useClientRequest(
    "/api/v2/jumpstart/getSessionData",
    {
      name: "get_account_session",
      onCompleted: (data) => {
        actions.setJumpstartSession(data);
      },
    }
  );

  // UPDATE session
  const { request: updateSession } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      name: "set_jumpstart_session",
      method: "POST",
      onCompleted: (data) => {
        actions.setSession(data);
      }
    }
  );

  // UPDATE quote
  const { request: updateQuote, errors: updateErrors } = useClientMutation(
    "/api/v4/direct/quotes",
    {
      method: "PUT",
      name: "update_quote",
      onCompleted: (data) => {
        if (!data.quote_number) {
          return;
        }

        actions.setQuote(data);
      },
    }
  );

  const onSubmitSuccess = (phone) => {
    actions.setOnboarding({ phone });
  };

  const onVerifySuccess = async (phone) => {
    // update phone on session
    await updateSession({
      body: {
        ...getSession(state),
        phone,
      },
    });

    // update phone on quote
    await updateQuote({
      body: {
        ...state.quote,
        first_name: sessionData.firstName,
        last_name: sessionData.lastName,
        email: sessionData.email,
        phone,
      },
    });
    
    actions.setOnboarding({ phoneVerified: true });
    history.push(toPath);
  };

  return (
    <Slide data-test="phone__title" title="Verify your mobile phone number">
      <Box mt={5}>
        <Box mb={5}>
          <Typography data-test="phone__subtitle" align="center" component="p" variant="h6">
            When a quake occurs in your area, we&apos;ll send you a text
            message.
          </Typography>
        </Box>
        <Box>
          <PhoneVerificationWizard
            phone={state.onboarding.phone}
            phoneVerified={state.onboarding.phoneVerified}
            userId={state.onboarding.userId}
            onSubmitSuccess={onSubmitSuccess}
            onVerifySuccess={onVerifySuccess}
            saveWithoutChangesComp={
              <Box mt={5}>
                <NextButton to={toPath}>Next</NextButton>
              </Box>
            }
            showUpdatePhone={updatePhone}
          />
        </Box>
      </Box>
    </Slide>
  );
};

PhoneVerification.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  nextPath: PropTypes.string,
};

export default PhoneVerification;
