import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { isEmail, isPhone } from "lib/form-validation";
import { makeStyles } from "@material-ui/core/styles";
import { NextButton } from "components/buttons";
import { TextMaskCustom } from "components/text-mask-custom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ConfigContext from "../config-context";
import { Help } from "@material-ui/icons";
import { clear } from "lib/storage";

export const EmailRequestForm = ({
  countryCode,
  email,
  error,
  loading,
  productName,
  onCancel,
  onSubmit,
}) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const { register, handleSubmit, errors, isSubmitting } = useForm();

  const validatePhone = (value) => {
    return isPhone(value.trim(), config.phonePattern);
  };

  const _onSubmit = (form) => {
    onSubmit(form);
  };

  return (
    <Box>
      <Box mb={5}>
        <Typography align="center" component="h1" variant="h4">
          Log in to your {productName} account
        </Typography>
      </Box>
      <Box mb={5}>
        <Typography align="center" component="h2" variant="h6">
          {productName} uses a passwordless log in to keep your account secure.
          <br />
          Please provide us with your email and phone number and <br />
          we&apos;ll email you a log in link.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Box textAlign="center">
          <TextField
            autoFocus
            className={classes.email}
            error={!!errors.email}
            defaultValue={email}
            helperText={!!errors.email && errors.email.message}
            label="Email"
            name="email"
            inputRef={register({
              required: {
                value: true,
                message: "Please enter a valid email",
              },
              minLength: 3,
              validate: {
                isEmail: (v) => isEmail(v) || "please enter a valid email",
              },
            })}
            type="text"
            variant="outlined"
          />
        </Box>
        <Box textAlign="center">
          <TextField
            className={classes.phone}
            error={!!errors.phone}
            helperText={
              errors.phone && `Must be a valid ${countryCode} mobile number`
            }
            label="Phone Number"
            name="phone"
            inputRef={register({
              required: true,
              validate: {
                isPhone: (v) =>
                  validatePhone(v) || "Please enter a valid phone number",
              },
            })}
            variant="outlined"
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
          />
        </Box>
        <Box mt={5} textAlign="center">
          <NextButton disabled={isSubmitting || loading} type="submit">
            Email Log In Link
          </NextButton>
        </Box>
        <Box mt={3} textAlign="center">
          <Button color="primary" onClick={() => onCancel()}>
            Cancel
          </Button>
        </Box>
        {error && (
          <Box className={classes.errorContainer}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <Typography color="error">
                {typeof error === "string" ? error : error.message}
              </Typography>
            </Alert>
            <Alert
              severity="info"
              className={classes.supportAlert}
              icon={<Help />}
            >
              <AlertTitle>Need assistance?</AlertTitle>
              <Typography>
                Contact our support team at{" "}
                <a
                  href={`mailto:${config.supportEmail}`}
                  className={classes.supportLink}
                >
                  {config.supportEmail}
                </a>{" "}
                or{" "}
                <a
                  href={`tel:${config.supportPhone}`}
                  className={classes.supportLink}
                >
                  {config.supportPhone}
                </a>
              </Typography>
            </Alert>
          </Box>
        )}
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  email: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  phone: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  errorContainer: {
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      maxWidth: "70%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
    },
  },
  supportAlert: {
    marginTop: theme.spacing(2),
  },
  supportLink: {
    color: "#000",
    textDecoration: "underline",
  },
}));

EmailRequestForm.propTypes = {
  countryCode: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  email: PropTypes.string,
  loading: PropTypes.bool,
  productName: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
