// buildURL takes a baseURL and a dictionary of
// arguements and returns a URL with the appropriate
// query string parameters
export function buildURL(base, args) {
  // if there are not args passed in or
  // an empty object is passed in just return the base
  if (
    !args ||
    Object.keys(args).length === 0
  ) {
    return base;
  }


  // build URL
  const params = new URLSearchParams(args);
  return `${base}?${params.toString()}`;
}

export const buildHeaders = (defaultHeaders,  inputHeaders, skipDefault) => {
  if (!inputHeaders && skipDefault) {
    return undefined;
  } else if (!inputHeaders) {
    return defaultHeaders
  } else if (skipDefault) {
    return inputHeaders;
  }

  return {
    ...defaultHeaders,
    ...inputHeaders,
  };
};
