import React, {useState} from 'react'
import PhoneVerificationWizard from '../components/phone-verification-wizard'
import {useStateMachine} from 'little-state-machine'
import {setUser} from '../actions/user'
import {useSnackbar} from 'notistack'

const Phone = () => {
  const { action, state } = useStateMachine(setUser)
  const [phone, setPhone] = useState(state.user.phone)
  const {enqueueSnackbar} = useSnackbar()

  const handleCancelSucces = () => {
    setPhone(state.user.phone)
  }

  const handleSubmitSuccess = (phone) => {
    setPhone(phone)
  }

  const handleVerifySuccess = (phone) => {
    action({phone, phoneVerifiedAt: new Date().toISOString()})
    enqueueSnackbar('Phone successfully updated!', {variant: 'success'})
  }

  return (
    <PhoneVerificationWizard
      phone={phone}
      userId={state.user.id}
      onCancelSuccess={handleCancelSucces}
      onSubmitSuccess={handleSubmitSuccess}
      onVerifySuccess={handleVerifySuccess}
    />
  )
}

export default Phone