import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Check } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import Slide from "../components/slide";
import ConfigContext from "../../config-context";

// eslint-disable-next-line
const CalculatePrice = ({ nextPath }) => {
  const config = useContext(ConfigContext);
  const history = useHistory();
  const classes = useStyles();

  const MESSAGES = [
    config.priceSearchText,
    "Determining likelihood of a major earthquake",
    "Calculating your monthly cost",
  ];

  const NUM_MESSAGES = MESSAGES.length;
  const MILLISECONDS_PER_MESSAGE = 1500;
  const TOTAL_CALCULATION_TIME = MILLISECONDS_PER_MESSAGE * NUM_MESSAGES;

  const [counter, setCounter] = useState(0);
  const [completed, setCompleted] = useState(5);

  useEffect(() => {
    // Update message and progress bar
    const interval = setInterval(() => {
      setCounter((counter1) => {
        return Math.min(counter1 + 1, NUM_MESSAGES - 1);
      });

      setCompleted((completed1) => {
        if (completed1 === 100) {
          return 100;
        }
        const diff = 100 / NUM_MESSAGES;
        return Math.min(completed1 + diff, 100);
      });
    }, MILLISECONDS_PER_MESSAGE);

    // Navigate to results page after a certain amount of time
    const timer = setTimeout(() => {
      history.push(nextPath);
    }, TOTAL_CALCULATION_TIME + 750);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  return (
    <Slide title="We’re looking up your price now" data-test="calculate__title">
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          <Box width={20} height={28}>
            {completed === 100 ? (
              <Check color="action" />
            ) : (
              <CircularProgress size={20} disableShrink />
            )}
          </Box>
          <Box className={classes.progressTextContainer}>
            <Typography align="center" className="mlm" variant="h6">
              {MESSAGES[counter]}
            </Typography>
          </Box>
        </Box>
        <LinearProgress variant="determinate" value={completed} />
      </Container>
    </Slide>
  );
};

const useStyles = makeStyles((theme) => ({
  progressTextContainer: {
    minHeight: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default CalculatePrice;
