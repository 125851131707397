export const setQuote = (state, payload) => ({
  ...state,
  quote: {
    ...state.quote,
    ...payload
  },
});

export const clearQuote = (state) => ({
  ...state,
  quote: {},
});
