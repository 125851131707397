import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@material-ui/core";
import Slide from "onboarding/components/slide";
import { PATHS } from "onboarding/onboarding-app";
import LoginChooser from "components/login-chooser";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "actions/onboarding";
import ConfigContext from "config-context";

const ExistingAccount = ({ location, history }) => {
  const config = useContext(ConfigContext);
  const { action, state } = useStateMachine(setOnboarding);

  return (
    <Slide title="Welcome back!">
      <Box mb={3}>
        <Typography variant="h6" align="center">
          Looks like you&apos;ve already started a {config.productName}{" "}
          application.
          <br />
          To pick up where you left off, log in to your account first.
        </Typography>
      </Box>
      <LoginChooser email={location.state?.email} />
      <Box mb={3} textAlign="center">
        <Button
          color="primary"
          onClick={() => {
            history.push(`/${PATHS.BASE}/${PATHS.NAME}`);
            action({ email: null });
          }}
        >
          Use a different email address
        </Button>
      </Box>
    </Slide>
  );
};

ExistingAccount.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default ExistingAccount;
