import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Box, TextField } from '@material-ui/core'
import { useStateMachine } from 'little-state-machine'
import { makeStyles } from '@material-ui/core/styles'
import titleize from 'titleize'

import { isZip } from '../../lib/form-validation'
import { setOnboarding, setCheckoutPrice } from '../../actions/onboarding'
import { getSession, setSession } from '../../actions/session'
import { clearQuote } from '../../actions/quote'
import { PATHS } from '../../onboarding/onboarding-app'
import { NextButton } from '../../components/buttons'
import ConfigContext from '../../config-context'
import { getPreferredProductPrice } from 'lib/product-prices'
import { apiClient, useClientMutation, useClientRequest } from '../../api'
import { getProductKey } from 'lib/hash-code'
import { defaultOnboardingState } from '../../app'
import Spinner from 'components/spinner'

const QuoteForm = ({ history, nextPath }) => {
  const config = useContext(ConfigContext)
  const classes = useStyles()
  const { actions, state } = useStateMachine({
    clearQuote,
    setOnboarding,
    setSession,
    setCheckoutPrice,
  })
  const [initLoading, setInitLoading] = useState(true)

  useEffect(() => {
    if (!state.onboarding.multiPolicy && state.quote) {
      actions.clearQuote()
      actions.setOnboarding({ ...defaultOnboardingState, userId: null })
    }

    if (!state.onboarding.multiPolicy && state.onboarding.userId) {
      actions.clearQuote()
      actions.setOnboarding({ ...defaultOnboardingState, userId: null })
    }

    setInitLoading(false)
  }, [])

  const { register, handleSubmit, errors } = useForm()
  // create session hook
  const { request: createSession, loading: sessionLoading } = useClientMutation(
    '/api/v2/auth/createSession',
    {
      method: 'POST',
      onCompleted: async (sd) => {
        if (!sd || !sd.SessionId) {
          return
        }

        apiClient.setSessionId(sd.SessionId)
        actions.setSession(sd)
      },
      onError: async () => {
        apiClient.clearSessionId()
      },
    }
  )

  // UPDATE quote
  const { request: updateSession } = useClientMutation(
    '/api/v2/jumpstart/setSessionData',
    {
      method: 'POST',
      name: 'update_session',
      onCompleted: (data) => {
        if (!data) {
          return
        }

        actions.setSession(data)
      },
    }
  )

  const { loading: pricesLoading, request: getTritonPrices } = useClientRequest(
    '/api/v2/jumpstart/price/list',
    {
      lazy: true,
      onCompleted: async (data) => {
        if (
          !data ||
          !data.pricing ||
          (Array.isArray(data.pricing) && !data.pricing.length)
        ) {
          throw new Error('No pricing data available')
        }

        const productPrices = data.pricing
          .filter((x) => Boolean(x.coverageLimit))
          .map((x) => ({
            ...x,
            // id is a hash of the products type, productId and coverage limit
            id: getProductKey(x),
          }))

        const productPrice = getPreferredProductPrice(
          config.pricing,
          productPrices,
          state.onboarding.address?.addressType,
          state.onboarding.coverageLimit
        )

        actions.setOnboarding({ monthlyTotal: productPrice.monthlyTotal })
        actions.setOnboarding({ productPrices })
        actions.setOnboarding({ productId: productPrice.productId })
        actions.setCheckoutPrice(productPrice)

        history.push(nextPath)
      },
      // eslint-disable-next-line no-unused-vars
      onError: async (e) => {
        actions.setOnboarding({ monthlyTotal: '' })
        history.push(PATHS.UNAVAILABLE)
      },
    }
  )

  const onSubmit = async (form) => {
    const zip = form.zip.substring(0, 5)
    // legacy
    // getConsumerPrice({ variables: { zip } });

    actions.setOnboarding({ quoteZip: zip })

    if (state.session) {
      const sessionData = getSession(state)
      const payload = {
        ...sessionData,
        zip,
      }

      await updateSession({
        body: payload,
      })
    } else {
      // createSession
      await createSession({
        body: {
          zip,
        },
      })
    }

    // get pricing
    await getTritonPrices({
      args: {
        zip,
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={5} textAlign="center">
        {initLoading ? (
          <Spinner />
        ) : (
          <TextField
            className={classes.zip}
            data-test="price-quote__input"
            error={!!errors.zip}
            defaultValue={state.onboarding.quoteZip}
            helperText={
              errors.zip && `Must be a valid ${config.postalCodeName}`
            }
            label={titleize(config.postalCodeName || 'Zip Code')}
            name="zip"
            inputRef={register({
              required: true,
              validate: (value) => isZip(value, config.postalCodePattern),
            })}
            variant="outlined"
            inputProps={{
              maxLength: 5,
            }}
            onInput={(e) => {
              e.target.value = [...e.target.value]
                .filter((x) => /^\d$/.test(x))
                .join('')
            }}
          />
        )}
      </Box>
      <Box mt={5} textAlign="center">
        <NextButton
          disabled={pricesLoading || sessionLoading}
          type="submit"
          data-test="price-quote__next"
        >
          Next
        </NextButton>
      </Box>
    </form>
  )
}

QuoteForm.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  nextPath: PropTypes.string,
}

const useStyles = makeStyles((theme) => ({
  zip: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export default QuoteForm
