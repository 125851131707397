import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { find, minBy } from "lodash";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "actions/onboarding";
import { setQuote } from "actions/quote";
import Slide from "onboarding/components/slide";
import PropertyTypeForm from "components/property-type-form";
import { useClientMutation, useClientRequest } from "api";
import { getSession, setSession, setJumpstartSession } from "actions/session";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { HelpAlert } from "components/help-alert";

import { Box, Typography } from "@material-ui/core";
import Spinner from "components/spinner";

const BuildingType = ({ history, nextPath }) => {
  const { actions, state } = useStateMachine({
    setOnboarding,
    setSession,
    setJumpstartSession,
    setQuote,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const toPath = location.state?.toPath ?? nextPath;

  // session
  const { data: sessionData } = useClientRequest(
    "/api/v2/jumpstart/getSessionData",
    {
      name: "get_account_session",
      onCompleted: (data) => {
        actions.setJumpstartSession(data);
      },
      onError: (err) => {
        setError(err);
      },
    }
  );

  // UPDATE quote
  const { request: updateSession } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      method: "POST",
      name: "update_session",
      onCompleted: (data) => {
        if (!data) {
          return;
        }

        actions.setSession(data);
      },
    }
  );

  // UPDATE quote
  const { request: updateQuote, error: updateError } = useClientMutation(
    "/api/v4/direct/quotes",
    {
      method: "PUT",
      name: "update_quote",
      onCompleted: (data) => {
        if (!data.quote_number) {
          return;
        }

        actions.setQuote(data);
        setLoading(false);
        history.push(toPath);
      },
      onError: (e) => {
        setError(typeof e === "string" ? e : e.toString());
      }
    }
  );

  const { request: getCheckoutPriceTriton } = useClientRequest(
    `/api/v2/jumpstart/price/check`,
    {
      lazy: true,
      onCompleted: async (data) => {
        if (!Object.prototype.hasOwnProperty.call(data, "productPricing")) {
          throw "Unable to fetch prices";
        }
        const filtered = data.productPricing.filter((x) => {
          return (
            Object.prototype.hasOwnProperty.call(x, "coverageLimit") &&
            x.coverageLimit > 0
          );
        });

        // get price with lowest coverage limit
        const min = filtered.reduce((a, b) => {
          return Math.min(a.coverageLimit, b.coverageLimit) === a.coverageLimit
            ? a
            : b;
        });
        const fallback = min?.coverageLimit;
        const earthquakeCoverage = state.quote?.earthquakeCoverage;
        let productType;
        if (state.quote?.product_no) {
          productType =
            state.quote.product_no === 5 ? "BUSINESS" : "RESIDENTIAL";
        } else {
          productType = state.onboarding.address.addressType;
        }

        const checkoutPrice = filtered.find(
          (x) =>
            x.coverageLimit === earthquakeCoverage &&
            x.productType === productType
        );

        await updateJumpstartSession({
          body: {
            ...state.session,
            addressType: min.productType,
            coverageAmount: earthquakeCoverage ?? fallback,
          },
        });

        actions.setOnboarding({ checkoutPrice: checkoutPrice ?? min });
      },
      onError: (err) => {
        setError(err);
      },
    }
  );

  const { request: updateJumpstartSession } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      name: "set_jumpstart_session",
      method: "POST",
      onCompleted: (data) => {
        actions.setSession(data);
      },
      onError: (e) => {
        setError(typeof e === "string" ? e : e.toString());
      },
    }
  );

  const onSubmitSuccess = async (address) => {
    setLoading(true);
    let productPrice = find(state.onboarding.productPrices, {
      productId: state.onboarding.productId,
    });

    if (productPrice.productType !== address.addressType) {
      productPrice = minBy(
        state.onboarding.productPrices.filter(
          (p) => p.productType === address.addressType
        ),
        "coverageLimit"
      );
    }

    const { productId } = productPrice;

    actions.setOnboarding({ address, productId });

    const { data } = await getCheckoutPriceTriton({
      args: {
        zip: state.onboarding.quoteZip,
        product: productId,
        couponCode: state.onboarding.couponCode,
      },
    });

    const isResidential = address.addressType === "RESIDENTIAL";
    const { paymentInterval } = state.session;
    const fallback = isResidential ? 10000 : 20000;
    const currentEarthquakeCoverage = state.quote?.earthquakeCoverage;
    const newCoverage = data.productPricing.find(
      (x) => x.coverageLimit === currentEarthquakeCoverage
    );
    let earthquakeCoverage = newCoverage?.coverageLimit ?? fallback;

    if (earthquakeCoverage !== currentEarthquakeCoverage) {
      await updateSession({
        body: {
          ...sessionState,
          coverageAmount: earthquakeCoverage,
        },
      });
    }

    await updateQuote({
      body: {
        ...state.quote,
        addressType: address.addressType,
        productType: address.addressType,
        paymentInterval: paymentInterval ?? "Monthly",
        earthquakeCoverage,
        product_no: isResidential ? 4 : 5,
      },
    });
  };

  const sessionState = useMemo(() => {
    return getSession(state);
  }, [state.session]);

  return (
    <Slide title={`Please confirm the address type you are insuring`}>
      <PropertyTypeForm
        loading={loading}
        error={updateError || error}
        address={state.onboarding.address}
        sessionData={sessionState}
        onSubmitSuccess={onSubmitSuccess}
      />
      {loading && (
        <Box m={4}>
          <Spinner />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={640}
        mx="auto"
        mt={4}
      >
        {error && (
          <>
            <Box>
              <Alert severity="error">
                <>
                  <AlertTitle>Error</AlertTitle>
                  <Typography>{error}</Typography>
                </>
              </Alert>
            </Box>
            <Box my={2}>
              <HelpAlert />
            </Box>
          </>
        )}
      </Box>
    </Slide>
  );
};

BuildingType.propTypes = {
  history: PropTypes.object,
  nextPath: PropTypes.string,
};

export default BuildingType;
