import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@material-ui/core";
import Slide from "onboarding/components/slide";
import MailingListForm from "onboarding/components/mailing-list-form";
import { PATHS } from "../onboarding-app";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "../../actions/onboarding";
import { getSession, setSession } from "../../actions/session";
import { setUser } from "../../actions/user";
import ConfigContext from "config-context";
import { useClientMutation } from "api";

const Unavailable = ({ history, nextPath }) => {
  const config = useContext(ConfigContext);
  const { actions, state } = useStateMachine({
    setOnboarding,
    setSession,
    setUser,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { request: updateSession } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      name: "update_session",
      method: "POST",
      onCompleted: (data) => {
        if (!data) {
          return;
        }

        actions.setSession(data);
      },
      onError: (e) => {
        let message = typeof e === "string" ? e : e.toString();
        if (message.toLowerCase().includes("expected 200")) {
          message =
            "an unexpected error occurred. Please try again in a moment or contact our support team.";
        }

        throw message;
      },
    }
  );

  const onSubmit = async (email) => {
    try {
      setLoading(true);
      // update store
      actions.setOnboarding({
        email,
      });

      actions.setUser({
        email,
      });

      // update session zip and email
      await updateSession({
        body: {
          ...getSession(state),
          email,
          zip: state.onboarding.quoteZip,
        },
      });

      // next
      setLoading(false);
      history.push(nextPath);
    } catch (e) {
      const message = typeof e === "string" ? e : e.toString();
      setError(message);
      setLoading(false);
    }
  };

  const tryDifferent = () => {
    actions.setOnboarding({
      quoteZip: null,
    });

    history.push(`/${PATHS.BASE}/${PATHS.PRICE_QUOTE}`);
  };

  return (
    <Slide
      title={`${config.productName} is not available in your area at the moment.`}
    >
      <MailingListForm
        onSubmitSuccess={() => history.push(nextPath)}
        error={error}
        onSubmit={onSubmit}
        zip={state.onboarding.quoteZip}
        loading={loading}
      />
      <Box mt={3} textAlign="center">
        <Button color="primary" data-test="unavailable__try-different" onClick={tryDifferent}>
          Try a different {config.postalCodeName}
        </Button>
      </Box>
    </Slide>
  );
};

Unavailable.propTypes = {
  history: PropTypes.object,
  nextPath: PropTypes.string,
};

export default Unavailable;
