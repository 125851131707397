import React from "react";
import PropTypes from "prop-types";
import { Link as MaterialLink } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
const Link = React.forwardRef((props, ref) => (
  <MaterialLink component={props.to ? RouterLink : undefined} {...props} />
));

Link.displayName = "Link";
Link.propTypes = {
  to: PropTypes.string,
};

export default Link;
