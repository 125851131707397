import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import BCorp from "images/cred-bcorp.png";
import Kate from "images/cred-kate.png";
import USGS from "images/cred-usgs.png";
import Blog from "images/cred-blog.png";
import Image from "material-ui-image";
import Link from "@material-ui/core/Link";
import ConfigContext from "config-context";

const CardGrid = () => {
  const config = useContext(ConfigContext);
  const classes = useStyles();

  return (
    <Box className={classes.root} pb={8}>
      <Container maxWidth="md">
        <Box py={8}>
          <Typography variant="h2" align="center">
            Built From Science, Made With{" "}
            <FavoriteIcon fontSize="inherit" color="primary" />
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <CardGridItem
            header="Powered by USGS Data"
            image={USGS}
            aspectRatio={200 / 190}
            clip={false}
          >
            <Typography>
              Jumpstart makes a &quot;parametric&quot; earthquake insurance
              available to everyone. Your eligibility to receive payment is
              determined by the quake intensity at your insured location, and a
              simple claims process.
            </Typography>
          </CardGridItem>
          <CardGridItem
            header="Created by Experts"
            image={Kate}
            aspectRatio={201 / 193}
            clip={false}
          >
            <Typography>
              Kate is the former president of Structural Engineers Association
              of Northern California and longtime fellow at the Earthquake
              Engineering Research Institute. She&apos;s devoted to making our
              communities resilient to disasters.
            </Typography>
          </CardGridItem>
          <CardGridItem
            header="Designed for Social Impact"
            image={BCorp}
            aspectRatio={200 / 188}
            clip={false}
          >
            <Typography>
              Jumpstart is a Benefit Corporation. It&apos;s our legal obligation
              to help society increase financial resilience against disasters.
              Jumpstart&apos;s innovative model gets more recovery money to
              people when you actually need it.
            </Typography>
          </CardGridItem>
          <CardGridItem
            header="Learn More"
            image={Blog}
            aspectRatio={200 / 200}
            clip={true}
          >
            <Typography>
              Read a{" "}
              <Link
                href="https://riskcenter.wharton.upenn.edu/wp-content/uploads/2020/09/Parametric-Insurance-for-Disasters_Sep-2020.pdf"
                target="_blank"
                color="primary"
              >
                white paper
              </Link>{" "}
              on parametric insurance for disasters, published by The Wharton
              Risk Center. Check out our{" "}
              <Link href={config.blogUrl} target="_blank" color="primary">
                blog
              </Link>{" "}
              for information to help you{" "}
              <Link
                href={`${config.blogUrl}/category/earthquake-preparedness/`}
                target="_blank"
                color="primary"
              >
                prepare
              </Link>{" "}
              for the next earthquake.
            </Typography>
          </CardGridItem>
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    backgroundColor: theme.palette.background.secondary,
  },
  circle: {
    clipPath: "circle(50% at 50% 50%)",
  },
}));

export default CardGrid;

const CardGridItem = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Grid item md={6} lg={6}>
      <Box display="flex" flexDirection="row">
        <Box width="30%">
          <div className={props.clip ? classes.circle : ""}>
            <Image
              src={props.image}
              aspectRatio={props.aspectRatio}
              color="rgb(255,255,255,0)"
            />
          </div>
        </Box>
        <Box width="70%" align="left" ml={3}>
          <Typography variant="h5" component="h3">
            {props.header}
          </Typography>
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

CardGridItem.propTypes = {
  children: PropTypes.node,
  clip: PropTypes.string,
  image: PropTypes.string,
  aspectRatio: PropTypes.number,
  header: PropTypes.string,
};
