import React from 'react'
import {Box, Container, Grid, Hidden, Typography} from "@material-ui/core"
import HomeGetAText from 'images/home-get-a-text.svg'
import makeStyles from "@material-ui/core/styles/makeStyles"

const GetAText = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box py={8}>
          <Typography align="center" variant="h3" component="p">
            Get a text when there&apos;s an intense earthquake in your area.
          </Typography>
          <Typography align="center" component="p" variant="h6">
            No need to jump through hoops to make a claim.
          </Typography>
        </Box>
      </Container>
      <Grid
        container
        justify="center"
      >
        <Grid item xs={12} sm={6} md={3}>
          <Box className={classes.leftContent}>
            <Box width="60%">
              <Typography variant="h5" component="p">
                Affected by an Earthquake?
              </Typography>
              <Typography>
                Jumpstart sends you a text message to confirm.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12} sm={6} md={3}>
            <Box className={classes.rightContent}>
              <Box width="60%">
                <PayoutProcess/>
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={10} sm={8} md={6}>
          <Container maxWidth="md" className={classes.middleContent}>
            <HomeGetAText/>
          </Container>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={3}>
            <Box className={classes.rightContent}>
              <Box width="60%">
                <PayoutProcess/>
              </Box>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}

const PayoutProcess = () => {
  return (
    <Typography component="p">
      Once confirmed, $10,000 is deposited into your bank account as soon as the next day.
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.primary.borderLight}`,
      borderBottom: `1px solid ${theme.palette.primary.borderLight}`
    },
    leftContent: {
      display: "flex",
      flexDirection: "row-reverse",
      [theme.breakpoints.down('sm')]: {
        justifyContent: "center"
      }
    },
    middleContent: {
      marginBottom: -5,
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(5),
      }
    },
    rightContent: {
      display: "flex",
      flexDirection: "row-start",
      [theme.breakpoints.down('sm')]: {
        justifyContent: "center"
      }
    },
    bottomAlignedContent: {
      paddingBottom: 0,
    }
  })
)

export default GetAText
