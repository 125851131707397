import React from 'react'
import {Box, Button, Container, Typography} from '@material-ui/core'
import {PlayCircleOutline} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import {PATHS} from 'onboarding'
import VideoModal from "components/video-modal"
import parameterize from "parameterize"

const Hero = () => {
  const classes = useStyles()
  const hash = window.location.hash.replace("#", "")
  const hashParam = parameterize(hash)
  const videoDeepLinkHash = parameterize("hero-video")
  const [open, setOpen] = React.useState(hashParam === videoDeepLinkHash)
  const playVideo = () => {
    setOpen(true)
  }
  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box py={5}>
          <Typography
            component="h1"
            variant="h2"
          >
            Earthquake Insurance. Simplified.
          </Typography>
          <Typography
            className='mvm'
            component="h2"
            variant="h6"
          >
            $10,000 right away after a big earthquake with no deductible.
          </Typography>

          <Box mt={5} mb={2}>
            <Button
              className={classes.cta}
              color="primary"
              component={RouterLink}
              to={ { pathname: `/${PATHS.BASE}`, state: { source: "Main Landing - Top of Page" } } }
              size="large"
              variant="contained"
            >
              Get Instant Quote
            </Button>
          </Box>
          <Button
            onClick={playVideo}
            startIcon={<PlayCircleOutline/>}
          >
            Watch the video
          </Button>
        </Box>
      </Container>
      <VideoModal
        open={open}
        closeHandler={closeHandler}
        videoDeepLinkHash={videoDeepLinkHash}
        videoSrc="https://www.youtube.com/embed/QXuL5cGz2lU?autoplay=1&playlist=QXuL5cGz2lU&loop=1"
      />
    </Box>
  )
}
// could create a ref in the header and dynamically get the height. Actual header height is ~95px.
const headerHeight = 100
const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(135deg, rgb(255, 222, 187) 30%, rgb(255, 168, 225) 100%)',
    textAlign: 'center',
    marginTop: -headerHeight,
    paddingTop: theme.spacing(3) + headerHeight,
    paddingBottom: theme.spacing(5),
  },
  cta: {
    fontSize: '1.1rem',
    padding: '18px 44px'
  },
}))

export default Hero
