import React, { useContext } from "react";
import {
  Box,
  Typography,
  Divider,
  Link,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ConfigContext from "config-context";
import HomeLink from "components/home-link";

// const BouncePrivacyPolicy = () => {
//   const config = useContext(ConfigContext)

//   return (
//     <div>
//       <Typography variant="h5" component="p">
//         (Last updated 19 February 2021)
//       </Typography>
//       <Box my={5}>
//         <Divider/>
//       </Box>
//       <Box align="left">
//         <Typography paragraph>
//           <HomeLink>{config.marketingHost}</HomeLink> is owned by Bounce Insurance Limited (“Bounce”) and operated by Bounce and its affiliates and contracting third parties (collectively, “Bounce”, “we”, “us” or “our”).
//         </Typography>
//         <Typography paragraph>
//           At Bounce, we take our privacy obligations very seriously. Our customers entrust us with their information, and we protect this information as if it were our own.
//         </Typography>
//         <Typography paragraph>
//           This Privacy Statement sets out how we deal with your personal information. It describes what information we collect, what we do with the information we collect, and under what circumstances we would disclose this information and for what purposes.
//         </Typography>
//         <Typography paragraph>
//           This document should be read alongside our <Link component={RouterLink} to="/terms-of-use" target="_blank">Terms of Service</Link>. By using our website and/or Services, you are declaring that you understand and agree to the terms of this policy.
//         </Typography>
//         <Typography variant="h4" component="h2" paragraph>
//           What information do we collect?
//         </Typography>
//         <Typography paragraph>
//           We may collect personally identifiable information when you specifically and knowingly provide it to us, for example when you register an account or submit an application and provide us your name, address, email address and similar personal information; when you provide your credit card or other financial information; or when you contact us with your name, address, or email address.
//         </Typography>
//         <Typography paragraph>
//           You may always choose not to provide personal information, but if you so choose, some or all of the Services may not be available to you including the ability to submit an application.
//         </Typography>
//         <Typography paragraph>
//           Note that personally identifiable information we collect and your User content may be stored and processed in New Zealand or any other country in which we or our affiliates, subsidiaries, contractors or agents maintain facilities; by using the Services, you consent to any such transfer of information outside of your country and acknowledge that some of these countries may not have an equivalent level of data protection as New Zealand.
//         </Typography>
//         <Typography paragraph>
//           When you use the Services, Bounce, or third parties on our behalf, may collect information via the use of add-ons, extensions, pixels, programs, cookies and other similar technologies.
//         </Typography>
//         <Typography paragraph>
//           That information can include a website that referred you to us, your IP address, browser type and language, hardware types, geographic location, and access times and durations. We also may collect navigational information, including information about the Service content or pages you view, the links you click, and other actions taken in connection with the Service. We use this information to improve some of the online services available through our website.
//         </Typography>
//         <Typography variant="h6" component="p" paragraph>
//           Cookies
//         </Typography>
//         <Typography paragraph>
//           Cookies, for example, are pieces of information that are transferred to an individual&apos;s hard drive for record-keeping purposes. They are used to assist you in using site information and content by saving your user preferences which allows us to identify registered users and save you time by eliminating the need to repeatedly enter the same information. They are also used to collect aggregate information about users on an anonymous basis. In addition to cookies, we may use other technologies, including single-pixel gifs (also known as web beacons) on our websites and in promotional e-mail messages or newsletters. These electronic images assist us in determining how many users have visited certain pages or opened messages or newsletters. We do not use these single-pixel gifs to collect personal information.
//         </Typography>
//         <Typography paragraph>
//           You have the ability to accept or decline cookies. Most browsers automatically accept cookies, but you can modify your browser setting (or in the case of Flash Cookies, you Flash Player settings) to decline cookies or to request your permission each time a service attempts to set a cookie. Please be aware that the site is optimally designed to operate with cookies enabled in your internet browser. If you choose to decline cookies, you may not be able to sign in or use other interactive features of the Services that depend on cookies.
//         </Typography>
//         <Typography variant="h6" component="p" paragraph>
//           Do Not Track Signals
//         </Typography>
//         <Typography paragraph>
//           Some Internet browsers may be configured to send &quot;Do Not Track&quot; signals to the online services that you visit. We currently do not respond to do not track signals. To find out more about &quot;Do Not Track,&quot; please visit <Link href="http://www.allaboutdnt.com" target="_blank">http://www.allaboutdnt.com</Link>.
//         </Typography>
//         <Typography variant="h4" component="h2" paragraph>
//           What do we do with the information that we collect?
//         </Typography>
//         <Typography paragraph>
//           Except as disclosed in this privacy policy, Bounce does not share your personal information with any outside parties.
//         </Typography>
//         <Typography component="ul" paragraph>
//           <li>to provide the Services to you</li>
//           <li>to operate, maintain, and improve the Services</li>
//           <li>to manage your account, including to communicate with you regarding your account</li>
//           <li>to respond to your comments and questions and to provide customer service</li>
//           <li>to send information including technical notices, updates, security alerts, and support and administrative messages</li>
//           <li>to send you marketing e-mails about upcoming promotions, and other news, including information about products and services offered by us and our affiliates or other third parties. You may opt-out of receiving such information at any time: such marketing emails tell you how to “opt-out.” You may also opt-out by contacting <Link href={`mailto:${config.supportEmail}`} target="_blank">{config.supportEmail}</Link>. Please note, even if you opt out of receiving marketing emails, we may still send you non-marketing emails. Non-marketing emails include emails about your account with us (if you have one) and our business dealings with you</li>
//           <li>to improve customer experience and train our staff</li>
//           <li>to process payments you make via the Services, and</li>
//           <li>as we believe necessary or appropriate (a) to comply with applicable laws; (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities; (c) to enforce our Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or others.</li>
//         </Typography>
//         <Typography paragraph>
//           We may share this information with service providers who perform services on our behalf, such as processing information requests, displaying stored data you access, to assist us in marketing, to conduct audits, etc.
//         </Typography>
//         <Typography variant="h4" component="h2" paragraph>
//           Disclosure
//         </Typography>
//         <Typography paragraph>
//           As a general rule, Bounce will not disclose any of your personally identifiable information or your User content except under one of the following circumstances: we have your permission; we determine in good faith that it is legally required to be revealed by any relevant legislation, regulation, ordinance, rule, administrative or court order, information that we determine must be disclosed to correct what we believe to be false or misleading information or to address activities that we believe to be manipulative, deceptive or otherwise a violation of law; where you are otherwise notified at the time we collect the data; in order to provide the product or service you have requested; when such disclosure is made subject to confidentiality restrictions in connection with a sale, merger, transfer, exchange, or other disposition (whether of assets, stock, or otherwise) of all or a portion of the business conducted by Bounce. Bounce may share non-personally identifiable information that Bounce gathers or creates, in aggregate form only, with advertisers and other partners.
//         </Typography>
//         <Typography variant="h4" component="h2" paragraph>
//           Social network applications
//         </Typography>
//         <Typography paragraph>
//           The Services may allow you to elect to access the data on your profile in social networks, and to interact through features on the social networks. If so, this access is not activated automatically, but require express authorisation by you to allow us to obtain information about you that is available on the social network, as well as to interact with our account. For further information on the use of Personal Information by social networks, you should review their respective privacy policies.
//         </Typography>
//         <Typography variant="h4" component="h2" paragraph>
//           Security and encryption
//         </Typography>
//         <Typography paragraph>
//           We follow generally accepted industry standards to help protect your personal information. No method of transmission over the internet, mobile technology, or method of electronic storage, is completely secure. Therefore, while we endeavour to maintain physical, electronic, and procedural safeguards to protect the confidentiality of the information that we collect online, we cannot guarantee its absolute security.
//         </Typography>
//         <Typography paragraph>
//           If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us at <Link href={`mailto:${config.supportEmail}`} target="_blank">{config.supportEmail}</Link>.
//         </Typography>
//         <Typography paragraph>
//           All credit cards, debit cards or bank account information submitted by you is securely processed directly with <Link href="https://stripe.com/docs/security/stripe" target="_blank">Stripe.com</Link> and is stored using application level Advanced Encryption Standard (AES) 128-bit encryption over secure HTTPS connections. None of our company’s internal servers are able to view or obtain plaintext card numbers.
//         </Typography>
//         <Typography variant="h4" component="h2" paragraph>
//           Changes to this privacy statement
//         </Typography>
//         <Typography paragraph>
//           We reserve the right to change the terms of this privacy policy at any time. When we make changes, we will revise the “last updated” date at the top of the policy. If there are material changes to this statement or in how we will use your personal information, we will notify you by prominently posting a notice of such changes here or on our home page, or by sending you an email. We encourage you to review this policy whenever you visit one of our sites.
//         </Typography>
//         <Typography variant="h4" component="h2" paragraph>
//           Choices about data
//         </Typography>
//         <Typography paragraph>
//           To request access to, or to make changes to, data hold about you, email us at <Link href={`mailto:${config.supportEmail}`} target="_blank">{config.supportEmail}</Link>. You also have the ability to access and update some information directly in our system by logging into our customer portal.
//         </Typography>
//       </Box>
//     </div>
//   )
// }

const JumpstartPrivacyPolicy = () => {
  const config = useContext(ConfigContext);

  return (
    <div>
      <Typography variant="h5" component="p">
        (Last Updated March 21, 2022)
      </Typography>
      <Box my={5}>
        <Divider />
      </Box>
      <Box align="left">
        <Typography paragraph>
          To learn more about how Neptune Flood Incorporated d/b/a Jumpstart
          Insurance Solutions collects and uses your personal information,
          please read this notice.
        </Typography>
        <Typography paragraph>
          Jumpstart and its affiliated partners appreciate the trust you place
          in us when you ask us to help protect you and your assets. You trust
          us with your private, personal information when you purchase insurance
          from us, and we are committed to protecting this information.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          Jumpstart’s Use of Your Personal Information
        </Typography>
        <Typography paragraph>
          We treat your information with respect and concern for your privacy.
          We do not disclose any nonpublic personal information about our
          customers or former customers to anyone, except as permitted or
          required by law. For example, we may share any or all of your
          information with our partners. We may also disclose any or all
          information we have collected about you to companies that perform
          services on our behalf.
        </Typography>
        <Typography paragraph>
          When we make this type of disclosure it is done to service your
          account or policy, or to inform you about Jumpstart products and
          services. Before disclosing your information, we require these
          companies to agree to keep it confidential and use it only for the
          transaction we request.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Independent Agents
        </Typography>
        <Typography paragraph>
          The independent insurance agents authorized to sell Jumpstart products
          and services are not Jumpstart employees, but they are subject to
          Jumpstart’s Privacy Policy, to the extent that they sell or service
          our products unless they have provided you with a copy of their own
          privacy policy. Because they have a unique business relationship with
          you, they may have additional personal information about you that
          Jumpstart does not. They may use this information differently than
          Jumpstart. Contact your Independent Agent to learn more about their
          privacy practices.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Information About Jumpstart’s Websites Information collected
        </Typography>
        <Typography paragraph>
          Jumpstart collects personal information (for example, name, address,
          E-mail address, telephone number) on our web site only when you or
          your agent voluntarily provide it to us. Only the Internet domain is
          recognized when you visit our website; individual E-mail addresses are
          not.
        </Typography>
        <Typography paragraph>
          If you provide us with your E-mail address, Jumpstart may use it to
          occasionally notify you of new products or services, special offers,
          severe weather alerts, mitigation techniques or to confirm
          transactions. If you do not wish to be contacted by E-mail you may
          click on the “unsubscribe” link at the bottom of any Jumpstart E-mail
          you receive and you will no longer receive non-policy related
          communications from us via E-mail. Jumpstart may work with several
          third-party vendors, including, but not necessarily limited to Yahoo,
          Google, and others, to research usage and activities on our website.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Jumpstart’s Sources of Information About You
        </Typography>
        <Typography paragraph>
          We may collect personal information about you from:
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            The information you provide on quotes, applications or other forms
            (such as your name, address and email)
          </li>
          <li>
            Your transactions with us (such as payment history and claims
            information)
          </li>
          <li>
            The information we receive from a consumer reporting agency (such as
            claims history with other insurance carriers) or industry data
            providers
          </li>
          <li>
            Your insurance agent/broker (such as updated information pertaining
            to your account)
          </li>
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Cookies
        </Typography>
        <Typography paragraph>
          Jumpstart uses cookies to maintain the continuity of browser sessions
          and to track repeat visitors.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Browsers & Security
        </Typography>
        <Typography paragraph>
          Jumpstart uses secure technology, which encrypts information as it
          crosses the Internet. You can tell if you are visiting a secure area
          by looking at the padlock symbol on your browser screen. If it is
          “locked,” you have a secure session.
        </Typography>
        <Typography paragraph>
          Jumpstart has security measures in place to protect the loss, misuse,
          and alteration of your personal information. Our servers are located
          in the United States of America and are designed to prevent
          unauthorized access.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Links to other sites
        </Typography>
        <Typography paragraph>
          <HomeLink>{config.marketingHost}</HomeLink> contains links to other
          websites. These other websites are not under Jumpstart’s direct
          control, and they may collect information about you that Jumpstart
          does not. We are not responsible for the privacy practices or the
          content of linked websites.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Protecting Your Personal Information from Unauthorized Access
        </Typography>
        <Typography paragraph>
          We limit employee access to customer information to those employees
          with a legitimate business reason for such access. We maintain
          physical, electronic and procedural safeguards to protect your
          personal information from being accessed by unauthorized persons. We
          also conduct a regular risk assessment, risk management and control,
          and oversight of our service providers to assure the effectiveness of
          these safeguards.
        </Typography>
        <Typography variant="h5" component="h3" paragraph>
          Changes to this Statement
        </Typography>
        <Typography paragraph>
          Please be aware that we may periodically update or revise this
          Statement. Please check our Privacy Statement frequently for changes.
        </Typography>
        <Typography variant="h4" component="h2" paragraph align="center">
          PRIVACY NOTICE FOR CALIFORNIA RESIDENTS
        </Typography>
        <Typography paragraph>
          This <strong>Privacy Notice for California Residents</strong>{" "}
          supplements the information contained in the Company’s general Privacy
          Notice and applies solely to all visitors, users, and others who
          reside in the State of California (“consumers” or “you”). We adopt
          this notice to comply with the California Consumer Privacy Act of 2018
          (CCPA) and any terms defined in the CCPA have the same meaning when
          used in this Notice.
        </Typography>
        <Typography component="ul" paragraph>
          <li>Publicly available information from government records.</li>
          <li>Deidentified or aggregated consumer information.</li>
        </Typography>
        <Typography paragraph>
          In particular, we have collected the following categories of personal
          information from its consumers within the last twelve (12) months:
        </Typography>
        <Table className="mbl">
          <TableBody>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Examples</TableCell>
              <TableCell>Collected</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A. Identifiers.</TableCell>
              <TableCell>
                {" "}
                A real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address,
                account name, Social Security number, driver’s license number,
                passport number, or other similar identifiers.
              </TableCell>
              <TableCell>YES</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)).
              </TableCell>
              <TableCell>
                A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                passport number, driver’s license or state identification card
                number, insurance policy number, education, employment,
                employment history, bank account number, credit card number,
                debit card number, or any other financial information, medical
                information, or health insurance information.
                <br />
                <br />
                Some personal information included in this category may overlap
                with other categories.
              </TableCell>
              <TableCell>YES</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                C. Protected classification characteristics under California or
                federal law.
              </TableCell>
              <TableCell>
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex (including gender,
                gender identity, gender expression, pregnancy or childbirth and
                related medical conditions), sexual orientation, veteran or
                military status, genetic information (including familial genetic
                information).
              </TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>D. Commercial information.</TableCell>
              <TableCell>
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>E. Biometric information.</TableCell>
              <TableCell>
                Genetic, physiological, behavioral, and biological
                characteristics, or activity patterns used to extract a template
                or other identifier or identifying information, such as,
                fingerprints, faceprints, and voiceprints, iris or retina scans,
                keystroke, gait, or other physical patterns, and sleep, health,
                or exercise data.
              </TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                F. Internet or other similar network activity.
              </TableCell>
              <TableCell>
                Browsing history, search history, information on a consumer’s
                interaction with a website, application, or advertisement.
              </TableCell>
              <TableCell>YES</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>G. Geolocation data.</TableCell>
              <TableCell>Physical location or movements.</TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>H. Sensory data.</TableCell>
              <TableCell>
                Audio, electronic, visual, thermal, olfactory, or similar
                information.
              </TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                I. Professional or employment-related information.
              </TableCell>
              <TableCell>
                Current or past job history or performance evaluations.
              </TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                J. Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
                99)).
              </TableCell>
              <TableCell>
                Education records directly related to a student maintained by an
                educational institution or party acting on its behalf, such as
                grades, transcripts, class lists, student schedules, student
                identification codes, student financial information, or student
                disciplinary records.
              </TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                K. Inferences drawn from other personal information.
              </TableCell>
              <TableCell>
                Profile reflecting a person’s preferences, characteristics,
                psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes.
              </TableCell>
              <TableCell>NO</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography paragraph>
          We obtain the categories of personal information listed above from the
          following categories of sources:
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            Directly from you. For example, from forms you complete or products
            and services you purchase.
          </li>
          <li>
            Indirectly from you. For example, from observing your actions on our
            Website.
          </li>
        </Typography>
        <Typography paragraph>
          <u>Use of Personal Information</u>
        </Typography>
        <Typography paragraph>
          We may use or disclose the personal information we collect for one or
          more of the following purposes:
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            To fulfill or meet the reason you provided the information. For
            example, if you share your name and contact information to request a
            price quote or ask a question about our products or services, we
            will use that personal information to respond to your inquiry. If
            you provide your personal information to purchase a product or
            service, we will use that information to process your payment and
            facilitate delivery. We may also save your information to facilitate
            new product orders or process returns.
          </li>
          <li>
            To provide, support, personalize, and develop our Website, products,
            and services.
          </li>
          <li>
            To create, maintain, customize, and secure your account with us.
          </li>
          <li>
            To process your requests, purchases, transactions, and payments and
            prevent transactional fraud.
          </li>
          <li>
            To provide you with support and to respond to your inquiries,
            including to investigate and address your concerns and monitor and
            improve our responses.
          </li>
          <li>
            To personalize your Website experience and to deliver content and
            product and service offerings relevant to your interests, including
            targeted offers and ads through our Website, third-party sites, and
            via email or text message (with your consent, where required by
            law).
          </li>
          <li>
            To help maintain the safety, security, and integrity of our Website,
            products and services, databases and other technology assets, and
            business.
          </li>
          <li>
            For testing, research, analysis, and product development, including
            to develop and improve our Website, products, and services.
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </li>
          <li>
            As described to you when collecting your personal information or as
            otherwise set forth in the CCPA.
          </li>
          <li>
            To evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which personal
            information held by us about our consumers is among the assets
            transferred.
          </li>
        </Typography>
        <Typography paragraph>
          We will not collect additional categories of personal information or
          use the personal information we collected for materially different,
          unrelated, or incompatible purposes without providing you notice.
        </Typography>
        <Typography paragraph>
          <u>Sharing Personal Information</u>
        </Typography>
        <Typography paragraph>
          We may disclose your personal information to a third party for a
          business purpose. When we disclose personal information for a business
          purpose, we enter a contract that describes the purpose and requires
          the recipient to both keep that personal information confidential and
          not use it for any purpose except performing the contract.
        </Typography>
        <Typography paragraph>
          We share your personal information with the following categories of
          third parties:
        </Typography>
        <Typography component="ul" paragraph>
          <li>Service providers</li>
        </Typography>
        <Typography paragraph>
          <em>Disclosures of Personal Information for a Business Purpose</em>
        </Typography>
        <Typography paragraph>
          In the preceding twelve (12) months, Company has disclosed the
          following categories of personal information for a business purpose:
        </Typography>
        <Typography paragraph>Category A: Identifiers.</Typography>
        <Typography paragraph>
          Category B: California Customer Records personal information
          categories.
        </Typography>
        <Typography paragraph>
          Category F: Internet or other similar network activity.
        </Typography>
        <Typography paragraph>
          We disclose your personal information for a business purpose to the
          following categories of third parties:
        </Typography>
        <Typography component="ul" paragraph>
          <li>Service providers</li>
        </Typography>
        <Typography paragraph>
          <em>Sales of Personal Information</em>
        </Typography>
        <Typography paragraph>
          In the preceding twelve (12) months, Company had not sold personal
          information.
        </Typography>
        <Typography paragraph>
          <u>Your Rights and Choices</u>
        </Typography>
        <Typography paragraph>
          The CCPA provides consumers (California residents) with specific
          rights regarding their personal information. This section describes
          your CCPA rights and explains how to exercise those rights.
        </Typography>
        <Typography paragraph>
          <em>Access to Specific Information and Data Portability Rights</em>
        </Typography>
        <Typography paragraph>
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information over the
          past 12 months. Once we receive and confirm your verifiable consumer
          request (see Exercising Access, Data Portability, and Deletion
          Rights), we will disclose to you:
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            The categories of personal information we collected about you.
          </li>
          <li>
            The categories of sources for the personal information we collected
            about you.
          </li>
          <li>
            Our business or commercial purpose for collecting or selling that
            personal information.
          </li>
          <li>
            The categories of third parties with whom we share that personal
            information.
          </li>
          <li>
            The specific pieces of personal information we collected about you
            (also called a data portability request).
          </li>
          <li>
            If we sold or disclosed your personal information for a business
            purpose, two separate lists disclosing:
            <Typography component="ul" paragraph>
              <li>
                sales, identifying the personal information categories that each
                category of recipient purchased; and
              </li>
              <li>
                disclosures for a business purpose, identifying the personal
                information categories that each category of recipient obtained.
              </li>
            </Typography>
          </li>
        </Typography>
        <Typography paragraph>
          <em>Deletion Request Rights</em>
        </Typography>
        <Typography paragraph>
          You have the right to request that we delete any of your personal
          information that we collected from you and retained, subject to
          certain exceptions. Once we receive and confirm your verifiable
          consumer request (see Exercising Access, Data Portability, and
          Deletion Rights), we will delete (and direct our service providers to
          delete) your personal information from our records, unless an
          exception applies.
        </Typography>
        <Typography paragraph>
          We may deny your deletion request if retaining the information is
          necessary for us or our service provider(s) to:
        </Typography>
        <Typography component="ol" paragraph>
          <li>
            Complete the transaction for which we collected the personal
            information, provide a good or service that you requested, take
            actions reasonably anticipated within the context of our ongoing
            business relationship with you, fulfill the terms of a written
            warranty or product recall conducted in accordance with federal law,
            or otherwise perform our contract with you.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity, or prosecute those responsible for
            such activities.
          </li>
          <li>
            Debug products to identify and repair errors that impair existing
            intended functionality.
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise their free speech rights, or exercise another right
            provided for by law.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act
            (Cal. Penal Code § 1546 seq.).
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when the information’s
            deletion may likely render impossible or seriously impair the
            research’s achievement, if you previously provided informed consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with
            consumer expectations based on your relationship with us.
          </li>
          <li>Comply with a legal obligation.</li>
          <li>
            Make other internal and lawful uses of that information that are
            compatible with the context in which you provided it.
          </li>
        </Typography>
        <Typography paragraph>
          <em>Exercising Access, Data Portability, and Deletion Rights</em>
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            Emailing us at{" "}
            <Link href="mailto:privacy@jumpstartinsurance.com" target="_blank">
              privacy@jumpstartinsurance.com
            </Link>
            .
          </li>
        </Typography>
        <Typography paragraph>
          Only you, or someone legally authorized to act on your behalf, may
          make a verifiable consumer request related to your personal
          information. You may also make a verifiable consumer request on behalf
          of your minor child.
        </Typography>
        <Typography paragraph>
          You may only make a verifiable consumer request for access or data
          portability twice within a 12-month period. The verifiable consumer
          request must:
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            Provide sufficient information that allows us to reasonably verify
            you are the person about whom we collected personal information or
            an authorized representative, which may include:
            <Typography component="ul" paragraph>
              <li>Proof of identity.</li>
            </Typography>
          </li>
          <li>
            Describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </li>
        </Typography>
        <Typography paragraph>
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you.
        </Typography>
        <Typography paragraph>
          Making a verifiable consumer request does not require you to create an
          account with us.
        </Typography>
        <Typography paragraph>
          We will only use personal information provided in a verifiable
          consumer request to verify the requestor’s identity or authority to
          make the request.
        </Typography>
        <Typography paragraph>
          For instructions on exercising sale opt-out rights, see Personal
          Information Sales Opt-Out and Opt-In Rights.
        </Typography>
        <Typography paragraph>
          <em>Response Timing and Format</em>
        </Typography>
        <Typography paragraph>
          We endeavor to respond to a verifiable consumer request within
          forty-five (45) days of its receipt. If we require more time (up to 90
          days), we will inform you of the reason and extension period in
          writing.
        </Typography>
        <Typography paragraph>
          If you have an account with us, we will deliver our written response
          to that account. If you do not have an account with us, we will
          deliver our written response by mail or electronically, at your
          option.
        </Typography>
        <Typography paragraph>
          Any disclosures we provide will only cover the 12-month period
          preceding the verifiable consumer request’s receipt. The response we
          provide will also explain the reasons we cannot comply with a request,
          if applicable. For data portability requests, we will select a format
          to provide your personal information that is readily useable and
          should allow you to transmit the information from one entity to
          another entity without hindrance.
        </Typography>
        <Typography paragraph>
          We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.
        </Typography>
        <Typography paragraph>
          <em>Personal Information Sales Opt-Out and Opt-In Rights</em>
        </Typography>
        <Typography paragraph>We do not sell personal information.</Typography>
        <Typography paragraph>
          <u>Non-Discrimination</u>
        </Typography>
        <Typography paragraph>
          We will not discriminate against you for exercising any of your CCPA
          rights. Unless permitted by the CCPA, we will not:
        </Typography>
        <Typography component="ul" paragraph>
          <li>Deny you goods or services.</li>
          <li>
            Charge you different prices or rates for goods or services,
            including through granting discounts or other benefits, or imposing
            penalties.
          </li>
          <li>
            Provide you a different level or quality of goods or services.
          </li>
          <li>
            Suggest that you may receive a different price or rate for goods or
            services or a different level or quality of goods or services.
          </li>
        </Typography>
        <Typography paragraph>
          However, we may offer you certain financial incentives permitted by
          the CCPA that can result in different prices, rates, or quality
          levels. Any CCPA-permitted financial incentive we offer will
          reasonably relate to your personal information’s value and contain
          written terms that describe the program’s material aspects.
          Participation in a financial incentive program requires your prior opt
          in consent, which you may revoke at any time.
        </Typography>
        <Typography paragraph>
          <u>Changes to Our Privacy Notice</u>
        </Typography>
        <Typography paragraph>
          We reserve the right to amend this privacy notice at our discretion
          and at any time. When we make changes to this privacy notice, we will
          post the updated notice on the Website and update the notice’s
          effective date.{" "}
          <em>
            Your continued use of our Website following the posting of changes
            constitutes your acceptance of such changes.
          </em>
        </Typography>
        <Typography paragraph>
          <u>Contact Information</u>
        </Typography>
        <Typography paragraph>
          If you have any questions or comments about this notice, the ways in
          which Jumpstart collects and uses your information described here,
          your choices and rights regarding such use, or wish to exercise your
          rights under California law, please do not hesitate to contact us at:
        </Typography>
        <Typography paragraph>
          <strong>Email:</strong> privacy@jumpstartinsurance.com
          <br />
          <strong>Postal Address:</strong>
          <br />
          Neptune Flood Incorporated d/b/a Jumpstart Insurance Solutions
          <br />
          400 6th Street S.
          <br />
          St. Petersburg, FL 33701
          <br />
          Attention: Compliance
        </Typography>
      </Box>
    </div>
  );
};

const policies = {
  // bounce   : <BouncePrivacyPolicy/>,
  jumpstart: <JumpstartPrivacyPolicy />,
};

const PrivacyPolicy = () => {
  const config = useContext(ConfigContext);
  const classes = useStyles();

  return (
    <Box align="center" className={classes.root} py={10}>
      <Typography variant="h3">Privacy Policy</Typography>
      {policies[config.tenantSlug]}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    textAlign: "center",
  },
});

export default PrivacyPolicy;
