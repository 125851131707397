import React from 'react'
import {Box, Container, Grid, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import HomeNoPaperworkSVG from 'images/home-no-paperwork.svg'

const NoPaperwork = () => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
    >
      <Grid item xs={12}>
        <Container maxWidth="md">
          <Box py={8}>
            <Typography variant="h3" component="h2">
              No paperwork. No adjusters. No delays.
            </Typography>
            <Typography variant="h6" component="p">
              Use the money for anything you need.
            </Typography>
          </Box>
        </Container>

        <Container maxWidth="md">
          <Box pb={8} px={4}>
            <HomeNoPaperworkSVG/>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.primary.borderLight}`
    },
  })
)

export default NoPaperwork
