// Based on: https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import NoMatch from "./pages/no-match";
import DefaultLayout from "./layouts";
import InternalServerError from "./pages/internal-server-error";

const ErrorHandler = ({ children }) => {
  const location = useLocation();
  const errorStatusCode = get(location.state, "errorStatusCode");

  if (errorStatusCode > 400) {
    return (
      <DefaultLayout location={location}>
        <ErrorComp errorStatusCode={errorStatusCode} />
      </DefaultLayout>
    );
  }

  return children;
};

ErrorHandler.propTypes = {
  children: PropTypes.node,
};

const ErrorComp = ({ errorStatusCode }) => {
  switch (errorStatusCode) {
    case 404:
      return <NoMatch />;

    // ... cases for other types of errors

    default:
      return <InternalServerError />;
  }
};

ErrorComp.propTypes = {
  errorStatusCode: PropTypes.oneOf[(PropTypes.string, PropTypes.number)],
};

export default ErrorHandler;
