import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import PhoneForm from "./phone-form";
import VerificationCodeForm from "./verification-code-form";
import VerifiedPhone from "./verified-phone";

const PhoneVerificationWizard = ({
  onCancelSuccess,
  onSubmitSuccess,
  onVerifySuccess,
  phone,
  phoneVerified,
  saveWithoutChangesComp,
  showUpdatePhone,
  userId,
}) => {
  const [updatePhone, setUpdatePhone] = useState(showUpdatePhone);
  const [confirmPhone, setConfirmPhone] = useState(false);

  const handleCancel = () => {
    setUpdatePhone(false);
    onCancelSuccess && onCancelSuccess();
  };

  if (!phone || updatePhone || (phone && !phoneVerified && !confirmPhone)) {
    return (
      <PhoneForm
        onCancel={updatePhone && (() => handleCancel())}
        onSubmitSuccess={(phoneVerification) => {
          setUpdatePhone(false);
          setConfirmPhone(true);
          onSubmitSuccess && onSubmitSuccess(phoneVerification);
        }}
        phoneNumber={phone}
        userId={userId}
      />
    );
  }

  if (confirmPhone) {
    return (
      <VerificationCodeForm
        onTryAgain={() => {
          setUpdatePhone(true);
          setConfirmPhone(false);
        }}
        userId={userId}
        phoneNumber={phone}
        onSubmitSuccess={async (user) => {
          await onVerifySuccess(user);
        }}
      />
    );
  }

  return (
    <Box textAlign="center">
      <VerifiedPhone onUpdate={() => setUpdatePhone(true)} phone={phone} />
      {saveWithoutChangesComp}
    </Box>
  );
};

PhoneVerificationWizard.propTypes = {
  onCancelSuccess: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onVerifySuccess: PropTypes.func,
  phone: PropTypes.string,
  phoneVerified: PropTypes.bool,
  saveWithoutChangesComp: PropTypes.object,
  showUpdatePhone: PropTypes.bool,
  userId: PropTypes.string,
};

export default PhoneVerificationWizard;
