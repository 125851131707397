import React from "react";
import PropTypes from "prop-types";
import { Box, Container } from "@material-ui/core";
import Navigation from "./components/navigation";
import Footer from "./components/footer";
import SeoMetadata from "./components/seo-metadata";

const DefaultLayout = ({ children, location }) => {
  return (
    <Box>
      <SeoMetadata location={location} />
      <Box style={{ minHeight: "100vh" }}>
        <Navigation />
        <Box py={5}>
          <Container maxWidth="md">
            <Box>{children}</Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

const NullLayout = ({ children, location }) => {
  return (
    <>
      <SeoMetadata location={location} />
      {children}
    </>
  );
};

NullLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export { DefaultLayout as default, NullLayout };
