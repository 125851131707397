import React, { useContext } from "react";
import PropTypes from "prop-types";
import Slide from "onboarding/components/slide";
import { Box, Button, Typography } from "@material-ui/core";
import { PATHS } from "onboarding/onboarding-app";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "actions/onboarding";
import ConfigContext from "config-context";

const InquiryComplete = ({ history }) => {
  const config = useContext(ConfigContext);
  const { action, state } = useStateMachine(setOnboarding);

  return (
    <Slide title={`Thanks for your interest in ${config.productName}!`}>
      <Box mb={3}>
        <Typography variant="h6" align="center">
          We&apos;ll email you when {config.productName} is available in your
          area.
        </Typography>
      </Box>
      {state.systemConfiguration.policySignUpEnabled && (
        <Box mt={3} textAlign="center">
          <Button
            data-test="inquiry__try-different"
            color="primary"
            onClick={() => {
              action({ quoteZip: null });
              history.push(`/${PATHS.BASE}/${PATHS.PRICE_QUOTE}`);
            }}
          >
            Try a different {config.postalCodeName}
          </Button>
        </Box>
      )}
    </Slide>
  );
};

InquiryComplete.propTypes = {
  history: PropTypes.object,
};

export default InquiryComplete;
