import React, { useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Box } from '@material-ui/core'
import { useClientMutation } from 'api'
import Spinner from 'components/spinner'

export const PaymentFormContainer = ({ children, ...props }) => {
  const [clientSecret, setClientSecret] = useState(null)
  const [stripeKey, setStripeKey] = useState(null)
  const [error, setError] = useState(null)

  const elementOptions = useMemo(() => {
    if (!clientSecret) {
      return undefined
    }

    return {
      clientSecret: clientSecret,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#FF614C',
        },
      },
    }
  }, [clientSecret])

  const stripePromise = useMemo(() => {
    if (!stripeKey) {
      return undefined
    }

    return loadStripe(stripeKey)
  }, [stripeKey])

  const {
    request: getClientSecret,
    loading: loadingSecret,
  } = useClientMutation('/api/v2/jumpstart/getClientSecret', {
    name: 'get_client_secret',
    method: 'POST',
    onCompleted: (data) => {
      if (!data.stripeKey || !data.client_secret) {
        setError('Unable to update payment method at this time.')
        return
      }

      if (stripeKey !== data.stripeKey) {
        setStripeKey(data.stripeKey)
      }

      setClientSecret(data.client_secret)
    },
    onError: (e) => {
      setError(e)
    },
  })

  useEffect(() => {
    ;(async () => {
      await getClientSecret({})
    })()
  }, [])

  return (
    <Box {...props}>
      {!stripePromise || !elementOptions ? (
        <Spinner />
      ) : (
        <Elements stripe={stripePromise} options={elementOptions}>
          {children}
        </Elements>
      )}
    </Box>
  )
}

PaymentFormContainer.propTypes = {
  children: PropTypes.node,
}
