import React from 'react'
import {Box, Container} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import VideoPlaceholderImage from 'images/video-placeholder.jpg'
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import IconButton from "@material-ui/core/IconButton"
import {PlayCircleFilled} from "@material-ui/icons"
import VideoModal from "components/video-modal"
import parameterize from "parameterize"


const Video = () => {
  const classes = useStyles()
  const hash = window.location.hash.replace("#", "")
  const hashParam = parameterize(hash)
  const videoDeepLinkHash = parameterize("video")
  const [open, setOpen] = React.useState(hashParam === videoDeepLinkHash)

  const playVideo = () => {
    setOpen(true)
  }

  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <>
      <Box className={classes.root} pb={4}>
        <Container maxWidth="lg">
          <Card className={classes.card}>
            <CardMedia image={VideoPlaceholderImage} className={classes.media}/>
            <Box className={classes.overlay}>
              <Box className={classes.dynamicSize}>
                <IconButton aria-label="play video" size="medium" onClick={playVideo}>
                  <PlayCircleFilled className={classes.full}/>
                </IconButton>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
      <VideoModal
        open={open}
        closeHandler={closeHandler}
        videoDeepLinkHash={videoDeepLinkHash}
        videoSrc="https://www.youtube.com/embed/QXuL5cGz2lU?autoplay=1&playlist=QXuL5cGz2lU&loop=1"
      />
    </>
  )
}


const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.secondary,
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    card: {
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: {
        translateX: "-50%",
        translateY: "-50%",
      }
    },
    dynamicSize: {
      width: 140,
      height: 140,
      marginTop: -70,
      marginLeft: -70,
      [theme.breakpoints.down('sm')]: {
        width: 80,
        height: 80,
        marginTop: -40,
        marginLeft: -40,
      },
    },
    full: {
      width: '100%',
      height: '100%',
    },
  })
)

export default Video
