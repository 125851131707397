export function cleanSession(sessionData) {
  let clean = {
    ...sessionData,
  };

  delete clean.id;
  return clean;
}

export function flattenSession(sessionData) {
  const meta = JSON.parse(sessionData.Meta);
  return {
    id: sessionData.SessionId ?? sessionData.Id,
    ...meta,
  };
}

// setSession takes a full session object and
// flattens it before storing onto state
export const setSession = (state, payload) => ({
  ...state,
  session: {
    ...state.session,
    ...flattenSession(payload),
  },
});

// setJumpstartSession takes a session.Meta object and updates the session state
export const setJumpstartSession = (state, payload) => ({
  ...state,
  session: {
    ...state.session,
    ...payload,
  },
});

export const getSession = (state) => {
  return cleanSession(state.session);
};
