/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { setOnboarding, setTransactionFees } from "actions/onboarding";
import { getSession, setSession } from "actions/session";
import { setQuote } from "actions/quote";
import { setUser } from "actions/user";
import CouponForm from "components/coupon-form";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { numberToCurrency } from "lib/money";
import { capitalize, camelCase } from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useForm } from "react-hook-form";
import { NextButton } from "components/buttons";
import PriceLabel from "components/price-label";
import ScrollableCard from "components/scrollable-card";
import { PATHS } from "../../onboarding-app";
import CheckboxFormControlLabel from "components/checkbox-form-control-label";
import Checkbox from "@material-ui/core/Checkbox";
import { defaultOnboardingState } from "../../../app";
import ConfigContext from "../../../config-context";
import { format as formatDate, parse, parseISO, add } from "date-fns";
import { useClientMutation, useClientRequest, apiClient } from "api";
import { Help } from "@material-ui/icons";

// Checkout components
import { Summary } from "./summary";
import { JumpstartDisclosures as Disclosures } from "./disclosures";
import { HelpAlert } from "components/help-alert";

const useStyles = makeStyles((theme) => ({
  dropdownOpen: {
    transform: "rotate(-180deg)",
  },
  dropdownClosed: {
    transform: "rotate(0)",
  },
  disclaimerText: {
    // based on the width of the Next button less 5px
    width: 195,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  gridMainContainer: {
    backgroundColor: "#FFFFFF",
    borderRight: `1px solid ${theme.palette.primary.borderLight}`,
  },
  outermostGridContainer: {
    borderTop: `1px solid ${theme.palette.primary.borderLight}`,
  },
  fullWidthFormItem: {
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  label: {
    fontWeight: 400,
    color: theme.palette.primary.textLight,
    display: "inline",
    width: theme.spacing(12),
  },
  tighterMobileMargin: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  orderSummaryDropdown: {
    textDecoration: "none !important",
    width: "100%",
  },
  orderSummaryWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  wordBreak: {
    wordWrap: "break-word",
  },
  stickySum: {
    position: "sticky",
    top: "32px",
  },
}));

const DEFAULT_COVERAGES = {
  RESIDENTIAL: 10000,
  BUSINESS: 20000,
};

export const Checkout = ({ history }) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const { actions, state } = useStateMachine({
    setOnboarding,
    setSession,
    setQuote,
    setUser,
    setTransactionFees,
  });
  const { register, handleSubmit, errors } = useForm();

  // hasUpdated is a helper boolean to determin whether
  // we've checked that the quote has been updated as this is required
  // for bind. We must create => update => bind
  const [hasUpdated, setHasUpdated] = useState(false);
  const [screenError, setScreenError] = useState(null);

  const {
    loading: transactionFeeLoading,
    request: getTransactionFee,
  } = useClientRequest("/api/v2/jumpstart/getTransactionFee", {
    lazy: true,
    onCompleted: (data) => {
      if (!data || (!data.FeeMonthly && !data.FeeYear)) {
        return;
      }
      
      actions.setTransactionFees(data);
    },
    onError: (err) => {
      setScreenError("unable to update transaction fee");
    },
  });

  // GET session
  const {
    data: sessionData,
    loading: loadingSession,
    errors: getSessionErrors,
  } = useClientRequest("/api/v2/auth/getSession", {
    name: "get_session",
    onCompleted: (data) => {
      actions.setSession(data);
    },
    onError: (err) => {
      // TODO: redirect back to /start/1
    },
  });

  // UPDATE session
  const { request: updateSession } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      name: "update_session",
      method: "POST",
      onCompleted: async (data) => {
        actions.setSession(data);
      },
    }
  );

  // GET quote
  const { request: getQuote, errors: getErrors } = useClientRequest(
    "/api/v4/direct/quotes",
    {
      lazy: true,
      onCompleted: (quoteData) => {
        if (Object.prototype.hasOwnProperty.call(quoteData, "quote_number")) {
          actions.setQuote(quoteData);
        }
      },
    }
  );

  useEffect(() => {
    (async () => {
      const {
        quote: { quote_number },
      } = state;
      await getQuote({
        args: {
          quote_number,
        },
      });
    })();
  }, []);

  // UPDATE quote
  const { request: updateQuote, errors: updateErrors } = useClientMutation(
    "/api/v4/direct/quotes",
    {
      method: "PUT",
      name: "update_quote",
      onCompleted: (data) => {
        if (!data.quote_number) {
          return;
        }

        actions.setQuote(data);
        setHasUpdated(true);
      },
    }
  );

  useEffect(() => {
    const quote = state.quote;
    const { checkoutPrice, session } = state.onboarding;
    if (!quote || !session || !checkoutPrice) {
      return;
    }

    const { firstName, lastName, email, phone, addressType } = session;
    const isResidential = addressType === "RESIDENTIAL";
    const product_no = isResidential ? 4 : 5;
    const earthquakeCoverage =
      checkoutPrice?.coverageLimit ?? DEFAULT_COVERAGES[addressType];

    // check if the data between localQuote (session storage) and
    // the data in sessionData (fetched api session) matches. If so,
    // we do not need to update the quote.
    if (
      quote.first_name?.includes(firstName) &&
      quote.last_name?.includes(lastName) &&
      email === quote.email &&
      phone === quote.phone &&
      product_no === quote.product_no &&
      checkoutPrice.coverageLimit === quote.earthquakeCoverage
    ) {
      setHasUpdated(true);
      return;
    }

    const updatedQuote = {
      ...quote,
      email,
      phone,
      first_name: firstName,
      last_name: lastName,
      product_no,
      earthquakeCoverage,
      payment_method: "Credit Card",
      delivery_method: "Electronically",
    };

    (async () => {
      await updateQuote({
        body: updatedQuote,
      });
    })();
  }, [state.onboarding.checkoutPrice, state.quote, state.session]);

  const handleIntervalChange = async (paymentInterval) => {
    if (!paymentInterval) {
      return;
    }

    await updateQuote({
      body: {
        ...state.quote,
        paymentInterval,
      },
    });
  };

  // BIND quote
  const {
    request: bindQuote,
    data: bindData,
    errors: bindErrors,
    loading: loadingBind,
  } = useClientMutation("/api/v4/direct/quotes/bind", {
    method: "PUT",
    name: "bind_quote",
    onCompleted: async (data) => {
      if (!data.PolicyId && !data.policyNo) {
        if (
          Object.prototype.hasOwnProperty.call(data, "ErrorMessage") &&
          typeof data.ErrorMessage === "string" &&
          data.ErrorMessage.length
        ) {
          setScreenError(data.ErrorMessage);
        } else {
          setScreenError(
            "Encountered an unexpected error while trying to bind your policy."
          );
        }
        return;
      }

      const policyId = data.PolicyId || data.policyNo;
      try {
        const response = await apiClient.get(
          `/api/v2/jumpstart/getPolicyConfirmation/${policyId}`
        );

        if (response.status !== 200) {
          throw `Unexpected status code ${response.status}`;
        }

        if (!Object.prototype.hasOwnProperty.call(response.data, "Payload")) {
          throw "No payload found";
        }

        const policies = state.onboarding.multiPolicy
          ? [...state.user.policies]
          : [];
        actions.setUser({
          id: state.session.id,
          policyNo: response.data?.Payload?.PolicyNo,
          transactionFees: state.onboarding.transactionFees,
          policies: [
            {
              ...response.data.Payload,
              isResidential,
            },
            ...policies,
          ],
        });
        actions.setOnboarding({ ...defaultOnboardingState, userId: null });
        history.push("/account");
        return;
      } catch (e) {
        console.log("pc e", e);
      }
    },
  });

  // Alert error
  const displayError = useMemo(() => {
    if (getSessionErrors) {
      console.log("getSessionError", getSessionErrors);
      return getSessionErrors;
    }

    if (getErrors) {
      console.log("getErrors", getSessionErrors);
      return getErrors;
    }

    if (updateErrors) {
      console.log("updateErrors", updateErrors);
      return updateErrors;
    }

    if (bindErrors) {
      console.log("bindErrors", bindErrors);
      return bindErrors;
    }

    if (screenError) {
      return screenError;
    }

    return null;
  }, [getSessionErrors, getErrors, updateErrors, bindErrors, screenError]);

  const onSubmit = async (form) => {
    setScreenError(null);
    const { signature, name } = form;
    actions.setOnboarding({ ...form });

    if (isBusiness) {
      await updateSession({
        body: {
          ...getSession(state),
          companyName: name,
        },
      });

      await updateQuote({
        body: {
          ...state.quote,
          company_name: name,
        },
      });
    }

    const quote_number = state.quote?.quote_number;
    if (!quote_number) {
      return;
    }

    await bindQuote({
      body: {
        quote_number,
      },
    });
  };

  const productPrices = state.onboarding.productPrices.filter(
    (p) => p.productType === state.onboarding.address.addressType
  );
  const [summaryVisible, setSummaryVisible] = React.useState(false);
  const toggleSummary = () => {
    setSummaryVisible(!summaryVisible);
  };

  const isBusiness = state.quote.product_no === 5;
  const isResidential = !isBusiness;

  const defaultName = isResidential
    ? state.onboarding.name || `${state.user.firstName} ${state.user.lastName}`
    : state.onboarding.name;

  const checkoutPrice = state.onboarding.checkoutPrice;

  const handleCoverageChange = async (coverage) => {
    if (!coverage || !coverage.coverageLimit) {
      return;
    }

    actions.setOnboarding({ checkoutPrice: coverage });

    await getTransactionFee({
      args: {
        country: state.onboarding.card.country,
        card: state.onboarding.card.brand.toLowerCase(),
        amount: coverage.yearlyTotal,
      },
    });

    await updateSession({
      body: {
        ...getSession(state),
        coverageAmount: coverage.coverageLimit,
      },
    });

    await updateQuote({
      body: {
        ...state.quote,
        earthquakeCoverage: coverage.coverageLimit,
      },
    });
  };

  const transactionFee = useMemo(() => {
    const { transactionFees, subscriptionInterval } = state.onboarding;
    return subscriptionInterval === "MONTH"
      ? transactionFees.monthly
      : transactionFees.yearly;
  }, [state.onboarding.subscriptionInterval, state.onboarding.transactionFees]);

  return (
    <Box>
      <Grid container className={classes.outermostGridContainer}>
        <Hidden mdUp>
          <Box mx={4} my={2} width={1} className={classes.tighterMobileMargin}>
            <Link
              onClick={toggleSummary}
              component="button"
              variant="body2"
              className={classes.orderSummaryDropdown}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <ExpandMoreIcon
                    className={
                      summaryVisible
                        ? classes.dropdownOpen
                        : classes.dropdownClosed
                    }
                  />
                  <span>
                    {summaryVisible
                      ? "Hide order summary"
                      : "Show order summary"}
                  </span>
                </Box>
                <Box>
                  <PriceLabel
                    checkoutPrice={state.onboarding.checkoutPrice}
                    coupon={state.onboarding.couponCode}
                    interval={state.onboarding.subscriptionInterval.toLowerCase()}
                  />
                </Box>
              </Box>
            </Link>
          </Box>
          <Collapse in={summaryVisible} style={{ width: "100%" }}>
            <Summary
              xs={12}
              sm={12}
              productPrices={productPrices}
              checkoutPrice={checkoutPrice}
              onCoverageChange={handleCoverageChange}
              onIntervalChange={handleIntervalChange}
            />
          </Collapse>
        </Hidden>

        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={8}
          className={classes.gridMainContainer}
        >
          <Box>
            <Box m={4} className={classes.tighterMobileMargin}>
              <Typography data-test="checkout__title" component="h1" variant="h4">
                Last step! Review the summary, disclosures and sign your policy.
              </Typography>
              <Hidden xsDown>
                <Box my={4} />
              </Hidden>
              <Box mt={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Disclosures form={{ register, errors }} />

                  <Box mt={3}>
                    <Box mb={2}>
                      <TextField
                        className={classes.fullWidthFormItem}
                        data-test="checkout__policy-name"
                        error={!!errors.name}
                        defaultValue={defaultName}
                        label={
                          isBusiness ? "Name of business" : "Name on policy"
                        }
                        name="name"
                        helperText={
                          isBusiness
                            ? "Please enter the name of the business at this address."
                            : "Please enter the name of the policy holder."
                        }
                        inputRef={register({ required: true, minLength: 1 })}
                        type="text"
                        variant="outlined"
                      />
                    </Box>
                    <Box width="100%">
                      <FormControl
                        required
                        error={!!errors.ackLosses}
                        component="fieldset"
                      >
                        <CheckboxFormControlLabel
                          control={
                            <Checkbox
                              data-test="checkout__ack-losses"
                              inputRef={register({ required: true })}
                              name="ackLosses"
                              color="primary"
                            />
                          }
                          label={`I understand my losses might exceed the ${numberToCurrency(
                            checkoutPrice.coverageLimit,
                            { truncateZeroCents: true }
                          )} provided by this coverage.`}
                        />
                        {!!errors.ackLosses && (
                          <FormHelperText data-test="checkout__ack-losses-error">You must acknowledge!</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                    <Box width="100%">
                      <FormControl
                        required
                        error={!!errors.ackCoverage}
                        component="fieldset"
                      >
                        <CheckboxFormControlLabel
                          control={
                            <Checkbox
                              data-test="checkout__ack-coverage"
                              inputRef={register({ required: true })}
                              name="ackCoverage"
                              color="primary"
                            />
                          }
                          label="I understand the policy coverage."
                        />
                        {!!errors.ackCoverage && (
                          <FormHelperText data-test="checkout__ack-coverage-error">You must acknowledge!</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Box>

                  {state.onboarding.address.provinceCode === "CA" && (
                    <Box mt={3}>
                      <Typography data-test="checkout__notice" component="h2" variant="h5" paragraph>
                        Nonadmitted Insurer Notice
                      </Typography>
                      <ScrollableCard>
                        <ul>
                          <li data-test="checkout__notice-text">
                            THE INSURANCE POLICY THAT YOU HAVE PURCHASED IS
                            BEING ISSUED BY AN INSURER THAT IS NOT LICENSED BY
                            THE STATE OF CALIFORNIA. THESE COMPANIES ARE CALLED
                            &quot;NONADMITTED&quot; OR &quot;SURPLUS LINE&quot;
                            INSURERS.
                          </li>
                          <li>
                            THE INSURER IS NOT SUBJECT TO THE FINANCIAL SOLVENCY
                            REGULATION AND ENFORCEMENT THAT APPLY TO CALIFORNIA
                            LICENSED INSURERS.
                          </li>
                          <li>
                            THE INSURER DOES NOT PARTICIPATE IN ANY OF THE
                            INSURANCE GUARANTEE FUNDS CREATED BY CALIFORNIA LAW.
                            THEREFORE, THESE FUNDS WILL NOT PAY YOUR CLAIMS OR
                            PROTECT YOUR ASSETS IF THE INSURER BECOMES INSOLVENT
                            AND IS UNABLE TO MAKE PAYMENTS AS PROMISED.
                          </li>
                          <li>
                            CALIFORNIA MAINTAINS A LIST OF ELIGIBLE SURPLUS LINE
                            INSURERS APPROVED BY THE INSURANCE COMMISSIONER. ASK
                            YOUR AGENT OR BROKER IF THE INSURER IS ON THAT LIST,
                            OR VIEW THAT LIST AT THE INTERNET WEB SITE OF THE
                            CALIFORNIA DEPARTMENT OF INSURANCE:
                            www.insurance.ca.gov.
                          </li>
                          <li>
                            FOR ADDITIONAL INFORMATION ABOUT THE INSURER YOU
                            SHOULD ASK QUESTIONS OF YOUR INSURANCE AGENT,
                            BROKER, OR &quot;SURPLUS LINE&quot; BROKER OR
                            CONTACT THE CALIFORNIA DEPARTMENT OF INSURANCE, AT
                            THE FOLLOWING TOLL-FREE TELEPHONE NUMBER:
                            1-800-927-4357.
                          </li>
                          <li>
                            IF YOU, AS THE APPLICANT, REQUIRED THAT THE
                            INSURANCE POLICY YOU HAVE PURCHASED BE BOUND
                            IMMEDIATELY, EITHER BECAUSE EXISTING COVERAGE WAS
                            GOING TO LAPSE WITHIN TWO BUSINESS DAYS OR BECAUSE
                            YOU WERE REQUIRED TO HAVE COVERAGE WITHIN TWO
                            BUSINESS DAYS, AND YOU DID NOT RECEIVE THIS
                            DISCLOSURE FORM AND A REQUEST FOR YOUR SIGNATURE
                            UNTIL AFTER COVERAGE BECAME EFFECTIVE, YOU HAVE THE
                            RIGHT TO CANCEL THIS POLICY WITHIN FIVE DAYS OF
                            RECEIVING THIS DISCLOSURE. IF YOU CANCEL COVERAGE,
                            THE PREMIUM WILL BE PRORATED AND ANY BROKER’S FEE
                            CHARGED FOR THIS INSURANCE WILL BE RETURNED TO YOU.
                          </li>
                        </ul>
                      </ScrollableCard>
                      <FormControl
                        required
                        error={!!errors.ackInsurerNotice}
                        component="fieldset"
                      >
                        <CheckboxFormControlLabel
                          control={
                            <Checkbox
                              data-test="checkout__ack-insurer-confirm"
                              inputRef={register({ required: true })}
                              name="ackInsurerNotice"
                              color="primary"
                            />
                          }
                          label="OK, got it."
                        />
                        {!!errors.ackInsurerNotice && (
                          <FormHelperText data-test="checkout__ack-insurer-error">You must acknowledge!</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  )}

                  <Box mt={3}>
                    <Typography data-test="sign-submit__title" component="h2" variant="h5" paragraph>
                      Sign and Submit
                    </Typography>
                    <Box mb={2}>
                      <TextField
                        error={!!errors.signature}
                        defaultValue={state.onboarding.signature}
                        label="Signature"
                        name="signature"
                        data-test="checkout__signature"
                        helperText="Please enter your legal name (e.g. the name on your bank account)."
                        inputRef={register({ required: true, minLength: 1 })}
                        type="text"
                        variant="outlined"
                        className={classes.fullWidthFormItem}
                      />
                    </Box>
                    <Box>
                      <FormControl
                        required
                        error={!!errors.ackSignature}
                        component="fieldset"
                      >
                        <CheckboxFormControlLabel
                          control={
                            <Checkbox
                              data-test="checkout__ack-sig"
                              inputRef={register({ required: true })}
                              name="ackSignature"
                              color="primary"
                            />
                          }
                          label={`Signed today, ${formatDate(
                            new Date(),
                            "MMM do, yyyy"
                          )}.`}
                        />
                        {!!errors.ackSignature && (
                          <FormHelperText data-test="checkout__sig-error">You must sign!</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                    <FormControl
                      required
                      error={!!errors.ackElectronicSignature}
                      component="fieldset"
                    >
                      <CheckboxFormControlLabel
                        control={
                          <Checkbox
                            data-test="checkout__elect-sig"
                            inputRef={register({ required: true })}
                            name="ackElectronicSignature"
                            color="primary"
                          />
                        }
                        label="I agree to provide my signature electronically, and that my electronic signature is the same as my handwritten signature for the purposes of validity, enforceability, and admissibility."
                      />
                      {!!errors.ackElectronicSignature && (
                        <FormHelperText data-test="checkout__elect-sig-error">You must acknowledge!</FormHelperText>
                      )}
                    </FormControl>
                    <Box my={4}>
                      <Divider />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      {displayError && (
                        <>
                          <Box>
                            <Alert severity="error">
                              <>
                                <AlertTitle>Error</AlertTitle>
                                <Typography>{displayError}</Typography>
                              </>
                            </Alert>
                          </Box>
                          <Box my={2}>
                            <HelpAlert />
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="row-reverse">
                      <Box>
                        <NextButton data-test="submit__button" disabled={loadingBind} type="submit">
                          Submit
                        </NextButton>
                        <Box mt={2} className={classes.disclaimerText}>
                          <Typography data-test="submit__caption" variant="caption">
                            By clicking submit, your card will be charged{" "}
                            <PriceLabel
                              checkoutPrice={checkoutPrice}
                              coupon={state.onboarding.couponCode}
                              interval={state.onboarding.subscriptionInterval.toLowerCase()}
                              transactionFee={transactionFee}
                              showDiscountTerms
                            />{" "}
                            and your policy will be processed.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Hidden smDown>
          <Summary
            md={5}
            lg={4}
            productPrices={productPrices}
            checkoutPrice={checkoutPrice}
            onCoverageChange={handleCoverageChange}
            onIntervalChange={handleIntervalChange}
          />
        </Hidden>
      </Grid>
    </Box>
  );
};

Checkout.propTypes = {
  history: PropTypes.object,
};
