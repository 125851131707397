import { find } from "lodash";
import { parseISO, add } from "date-fns";

export const getAddress = (state, policy) => {
  // const { addr1, addr2, city, state: addressState, zip } = policy;
  const { Address1, Address2, City, State: addressState, ZipCode } = policy;
  return {
    address1: Address1,
    address2: Address2,
    localities: `${City}, ${addressState}`,
    city: City,
    state: addressState,
    zip: ZipCode,
  };
  // return find(state.user?.addresses, { 'id': policy.address.id })
};

export const getPolicy = (state, number) =>
  find(state.user?.policies, { number: number });

export const isActiveUser = (state) =>
  !!state.user?.id && !!state.user?.policies?.length;

export const isLead = (state) =>
  state.user?.id && !state.user?.policies?.length;

export const isLoggedIn = (state) => {
  return !!state.user?.id;
};

export const resetUser = (state) => ({
  ...state,
  user: {},
});

export const setUser = (state, payload) => ({
  ...state,
  user: {
    ...state.user,
    ...payload,
  },
});

export const getJumpstartPolicy = (state, number) => {
  if (!state.user || !Array.isArray(state.user.policies)) {
    return undefined;
  }

  const policy = state.user.policies.find((x) => x.PolicyNo === number);
  const {
    CompanyName,
    EarthquakeCoverage,
    FirstName,
    InceptionDate,
    LastName,
    PaymentInterval,
    PolicyNo,
    TotalPremium,
    MonthlyCost,
    ProductId,
    State,
  } = policy;

  const isMonthly = PaymentInterval === "Monthly";
  const effDate = parseISO(InceptionDate);
  const expiresDate = add(effDate, { years: 1 });
  const name = `${FirstName} ${LastName}`;

  const priceKey = isMonthly ? "monthlyTotal" : "yearlyTotal";
  const checkoutPrice = {
    [priceKey]: isMonthly
      ? getMonthlyPrice(MonthlyCost, State)
      : getAnnualPrice(TotalPremium),
  };

  return {
    ...policy,
    // Jumpstart UI data
    number: PolicyNo,
    subscriptions: [
      {
        couponCode: null,
        interval: isMonthly ? "month" : "year",
        checkoutPrice,
      },
    ],
    effectiveAt: effDate.toISOString(),
    expiresAt: expiresDate.toISOString(),
    coverageLimit: EarthquakeCoverage,
    name: ProductId === 5 ? CompanyName : name,
    status: "PENDING_ACTIVATION",
  };
};

// getMonthlyPrice takes a base price and
// applies rounding logic. Specific logic is applied for 
// OR policies. See PD-5597 for more information
function getMonthlyPrice(base, state) {
  // if state is OR, apply specific logic
  if (state === "OR") {
    const remainder = base % 1;
    let fractional;
    if (remainder >= 0.83) {
      fractional = 0.83;
    } else if (remainder >= 0.66) {
      fractional = 0.66;
    } else if (remainder >= 0.50) {
      fractional = 0.50;
    } else if (remainder >= 0.33) {
      fractional = 0.33;
    } else if (remainder >= 0.16) {
      fractional = 0.16;
    }

    return fractional ? (base - remainder ) + fractional : base - remainder;
  }

  // remove fractional value and return a whole number
  return base - (base % 1);
}

// getAnnualPrice gets the base price and removes any fractional value for display
function getAnnualPrice(base) {
  return base - (base % 1);
}
