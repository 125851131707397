import React from 'react'
import {Box, Container, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'


const Quote = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box display="flex" flexDirection="row" pt={6}>
          <Box>
            <Typography component="blockquote" className={classes.largeLeftQuote}/>
          </Box>
          <Box pt={5} pb={10}>
            <Typography component="blockquote" className={classes.quote}>
              Signing up is nearly as easy as hailing a Lyft. If you are a renter, I can&apos;t think of a better way to give
              yourself peace of mind once the &apos;Big One&apos; finally hits.
            </Typography>
          </Box>
          <Box display="flex"
               flexWrap="wrap"
               alignContent="flex-end"
          >
            <Typography component="blockquote" className={classes.largeRightQuote}/>
          </Box>
        </Box>
        <Box mt={-6} mb={6}>
          <Typography component="p" className={classes.quoted}>
            - Joe S, customer since 2018
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.secondary,
      borderBottom: `1px solid ${theme.palette.primary.borderLight}`
    },
    quote: {
      fontFamily: ['Merriweather', 'serif'],
      fontSize: '1.2rem',
      '@media (min-width:600px)': {
        fontSize: '1.8rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.3rem',
      }
    },
    quoted: {
      fontWeight: 500,
      fontSize: '1.2rem',
      fontStyle: "italic",
      '@media (min-width:600px)': {
        fontSize: '1.2rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      }
    },
    largeLeftQuote: {
      fontFamily: ['Merriweather', 'serif'],
      '&:before': {
        content: '"\\201C"',
        fontSize: '5rem',
        lineHeight: 'inherit',
      },
    },
    largeRightQuote: {
      fontFamily: ['Merriweather', 'serif'],
      '&:after': {
        content: '"\\201D"',
        fontSize: '5rem',
        lineHeight: 'inherit',
      }
    },


  })
)

export default Quote
