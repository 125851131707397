import { buildHeaders } from "./utils";

const defaultOptions = {
  baseUrl: process.env.REACT_APP_DTC_API_URL,
};

export class APIClient {
  constructor(clientOptions) {
    this.token = null;
    this.sessionId = null;
    this.baseUrl = clientOptions.baseUrl;
    this.env = clientOptions.env ?? "dev";

    this.authenticate();
  }

  async authenticate() {
    const basicAuth = window.btoa(`${process.env.REACT_APP_DTC_SOURCE}:${process.env.REACT_APP_DTC_KEY}`)
    const response = await fetch(`${this.baseUrl}/api/v5/auth/getToken`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Basic ${basicAuth}`,
      }),
    });

    if (response.status !== 200) {
      throw `Unexpected status. expected 200, got ${response.status}`;
    }

    let token = null;
    const contentType = response.headers.get("Content-Type");
    if (contentType.includes("text/plain")) {
      token = await response.text();
    } else {
      this.token = null;
      throw `Unexpected content-type. expected text/plain, got ${contentType}`;
    }

    if (!token) {
      return;
    }

    this.token = token;
  }

  setSessionId(id) {
    if (!id || typeof id !== "string") {
      return;
    }

    this.sessionId = id;
  }

  clearSessionId() {
    this.sessionId = null;
  }

  async request(url, options = {}) {
    if (!this.token) {
      await this.authenticate();
    }

    const { headers, ...rest } = options;
    const response = await fetch(`${this.baseUrl}${url}`, {
      ...rest,
      // include cookies in requests
      credentials: "include",
      headers: new Headers({
        ...headers,
        Authorization: this.token,
      }),
    });

    let data;
    const contentLength = response.headers.get("Content-Length");
    const contentType = response.headers.get("Content-Type");
    if (contentType?.includes("application/json")) {
      data = await response.json();
    } else if (contentType?.includes("text/plain")) {
      data = await response.text();
    } else if (contentLength <= 0) {
      data = "No Data";
    } else {
      data = "Unexpected Content-Type";
    }

    const requestResponse = {
      status: response.status,
      statusText: response.statusText,
      body: response.body,
      headers: response.headers,
      data,
    };

    return requestResponse;
  }

  async get(url, options) {
    return await this.request(url, {
      method: "GET",
      ...options,
    });
  }

  async post(url, data, options = {}) {
    const { headers: inputHeaders, ...fetchOptions } = options;
    const skipDefault = !data;
    const defaultHeaders = {
      "Content-Type": "application/json",
    };

    const headers = buildHeaders(defaultHeaders, inputHeaders, skipDefault);
    return await this.request(url, {
      ...fetchOptions,
      method: "POST",
      headers,
      body: data ? JSON.stringify(data) : undefined,
    });
  }

  async put(url, data, options = {}) {
    const { headers: inputHeaders, ...fetchOptions } = options;
    const skipDefault = !data;
    const defaultHeaders = {
      "Content-Type": "application/json",
    };

    const headers = buildHeaders(defaultHeaders, inputHeaders, skipDefault);
    return await this.request(url, {
      ...fetchOptions,
      method: "PUT",
      headers,
      body: data ? JSON.stringify(data) : undefined,
    });
  }
}

// default api client;
export const apiClient = new APIClient(defaultOptions);
