import React, { useContext } from "react";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { show as showIntercom } from "lib/intercom";
import ConfigContext from "../config-context";

const NewCoverage = () => {
  const config = useContext(ConfigContext);
  const history = useHistory();

  return (
    <Paper elevation={0} variant="outlined">
      <Box p={3}>
        <Grid container>
          <Grid item xs={2}>
            <Box>
              <IconButton
                aria-label="Go Back"
                color="inherit"
                onClick={() => history.goBack()}
              >
                <ArrowBack />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box pt={1}>
              <Typography variant="h6" component="h2" align="center">
                Add a New Policy
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Box py={2}>
          <Alert severity="info">
            <Box mb={2}>
              <Typography component="p" variant="body2">
                To add a policy for another adult at your address, log out, and
                proceed through the sign-up again using that person&apos;s name and
                email address.
              </Typography>
            </Box>
            <Box>
              <Typography component="p" variant="body2">
                To add a policy for an additional residence, or to add a
                business policy, please contact us via phone at{" "}
                <Link href={`tel:${config.supportPhone}`}>
                  {config.supportPhone}
                </Link>
                , via{" "}
                <Link
                  onClick={showIntercom}
                  component="button"
                  variant="body2"
                  underline="always"
                >
                  chat
                </Link>
                , or{" "}
                <Link
                  href={`mailto:${config.supportEmail}`}
                  target="_blank"
                  underline="always"
                >
                  email support
                </Link>
                .
              </Typography>
            </Box>
          </Alert>
        </Box>
      </Box>
    </Paper>
  );
};

export default NewCoverage;
