import React from 'react'
import UserForm from '../components/user-form'
import {useStateMachine} from 'little-state-machine'
import {useSnackbar} from 'notistack'

const User = () => {
  const {state} = useStateMachine()
  const {enqueueSnackbar} = useSnackbar()

  const onSubmitSuccess = (user) => {
    enqueueSnackbar('Contact info successfully updated!', {variant: 'success'})
  }

  return (
    <UserForm
      user={state.user}
      onSubmitSuccess={onSubmitSuccess}
    />
  )
}

export default User