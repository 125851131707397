import React, {useContext} from 'react'
import {makeStyles} from "@material-ui/core/styles"
import {Avatar, Box, Button, Grid, Hidden, Link, Typography} from "@material-ui/core"
import Image from 'material-ui-image'
import BounceLogo from "images/bounce-logo-primary.png"
import JumpstartLogo from "images/jumpstart-logo-secondary.png"
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import {Link as RouterLink} from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import ConfigContext from '../config-context'
import HomeLink from './home-link'
import FaqLink from './faq-link'

const logos = {
  bounce   : BounceLogo,
  jumpstart: JumpstartLogo
}

const logoAspectRatios = {
  bounce   : 743 / 200,
  jumpstart: 501 / 100,
}

const backgrounds = {
  bounce: {
    backgroundColor: '#F2F2F2',
  },
  jumpstart: {
    backgroundImage: 'linear-gradient(to right, #f3d9cc, #f6dece, #f9e4d1, #fae9d4, #fcefd7, #fcefd7, #fcefd7, #fcefd7, #fae9d4, #f9e4d1, #f6dece, #f3d9cc)',
  },
}

const useAvatarStyles = makeStyles((theme) => ({
  colorDefault: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main
  }
}))

const useStyles = makeStyles((theme) => ({
  footerIcons: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  footerLogoContainer: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '33%',
    },
    [theme.breakpoints.down(450)]: {
      width: '50%',
    },
    [theme.breakpoints.down(350)]: {
      width: '75%',
    },
  },
  footerMainContainer: {
    borderTop: `1px solid ${theme.palette.primary.borderLight}`,
    backgroundColor: theme.palette.background.default,
  },
  footerMainContainerGradient: props => ({
    borderTop: `1px solid ${theme.palette.primary.borderDark}`,
    ...backgrounds[props.tenantSlug],
  }),
  copyright: {
    borderTop: `1px solid ${theme.palette.primary.borderLight}`,
    [theme.breakpoints.down('xl')]: {
      width: "75%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  copyrightGradient: {
    borderTop: `1px solid ${theme.palette.primary.borderDark}`,
    [theme.breakpoints.down('xl')]: {
      width: "75%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
}))

const Footer = () => {
  const config = useContext(ConfigContext)
  const classes = useStyles({ tenantSlug: config.tenantSlug })
  const avatarClasses = useAvatarStyles({ tenantSlug: config.tenantSlug })
  const src = logos[config.tenantSlug];
  const { company = {}, social = {} } = config;
  return (
    <Box align="center" className={classes.footerMainContainerGradient}>
      <Box m={8} mb={4}>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <Box className={classes.footerLogoContainer} mb={4}>
              <HomeLink>
                <span></span>
                {src && (
                  <Image src={src} aspectRatio={logoAspectRatios[config.tenantSlug]} color="rgb(255,255,255,0)" alt="company logo"/>
                )}
              </HomeLink>
            </Box>
          </Grid>
          <Grid item md={2} sm={4} xs={12}>
            <Box align="left">
              <Typography variant="overline">Company</Typography>
              <Typography variant="body2">
                <Link component={RouterLink} to="/terms-of-use">
                  Terms of Service
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link component={RouterLink} to="/privacy-policy">
                  Privacy
                </Link>
              </Typography>
              {
                config.fairInsuranceCode &&
                <Typography variant="body2">
                  <FaqLink component={HashLink} to="/faq#what-is-the-fair-insurance-code">
                    Fair Insurance Code
                  </FaqLink>
                </Typography>
              }
            </Box>
          </Grid>
          <Grid item md={2} sm={4} xs={12}>
            <Box align="left">
              <Typography variant="overline">Resources</Typography>
              <Typography variant="body2">
                <FaqLink to="/faq">
                  FAQ / About
                </FaqLink>
              </Typography>
              {
                config.complaints &&
                <Typography variant="body2">
                  <Link component={RouterLink} to="/complaints">
                    Complaints
                  </Link>
                </Typography>
              }
              {
                config.blogUrl &&
                <Typography variant="body2">
                  <Link href={config.blogUrl} color="primary">
                    Blog
                  </Link>
                </Typography>
              }
            </Box>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Box align="left">
              <Typography variant="overline">Contact</Typography>
              <Typography variant="body2">{company.name}</Typography>
              <Typography variant="body2">{company.address1}</Typography>
              <Typography variant="body2">{company.localities} {company.zip}</Typography>
              <Typography variant="body2">
                <Link href={`tel:${config.supportPhone}`}>
                  <span>{config.supportPhone}</span>
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Box align="left">
              <Hidden smDown>
                <Typography variant="overline">Follow Us</Typography>
              </Hidden>
              <Box className={classes.footerIcons}>
                <Avatar classes={{colorDefault: avatarClasses.colorDefault}}>
                  <Button href={`https://www.facebook.com/${social.facebook}`} target="_blank" color="inherit" aria-label="Facebook">
                    <FacebookIcon/>
                  </Button>
                </Avatar>
                <Avatar classes={{colorDefault: avatarClasses.colorDefault}}>
                  <Button href={`https://www.linkedin.com/company/${social.linkedIn}`} target="_blank" color="inherit" aria-label="LinkedIn">
                    <LinkedInIcon/>
                  </Button>
                </Avatar>
                {
                  social.twitter &&
                  <Avatar classes={{colorDefault: avatarClasses.colorDefault}}>
                    <Button href={`https://twitter.com/${social.twitter}`} target="_blank" color="inherit" aria-label="Twitter">
                      <TwitterIcon/>
                    </Button>
                  </Avatar>
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mx={6} display="flex" justifyContent="flex-end">
        <Box py={6} className={classes.copyrightGradient}>
          {
            config.disclaimerText &&
            <Typography variant="body2" align="left" paragraph>
              {config.disclaimerText}
            </Typography>
          }
          <Typography variant="body2" align="left" paragraph>
            © {new Date().getFullYear()} {company.name}{ config.licenseTextInline ? `, ${config.licenseText}` : null}
          </Typography>
          {
            !config.licenseTextInline &&
            <Typography variant="body2" align="left">
              {config.licenseText}
            </Typography>
          }
        </Box>
      </Box>

    </Box>
  )
}

export default Footer