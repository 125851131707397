export const boot = () => {
  window.Intercom && window.Intercom('boot', {
    app_id: window.INTERCOM_APP_ID,
  })
}

export const show = () => {
  window.Intercom && window.Intercom('show')
}

export const update = user => {
  window.Intercom && window.Intercom('update', {
    email     : user.email,
    created_at: new Date(user.createdAt).getTime() / 1000,
    name      : `${user.firstName} ${user.lastName}`,
    user_id   : user.id
  })
}