import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@material-ui/core";

const Spinner = ({ color, ...props }) => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress color={color} {...props} />
    </Box>
  );
};

Spinner.propTypes = {
  color: PropTypes.oneOf(["inherit", "primary", "secondary"]),
};

export default Spinner;
