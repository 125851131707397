import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Link,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { NextButton } from "components/buttons";
import { isEmail } from "lib/form-validation";
import { update as updateIntercom } from "../lib/intercom";
import { useSnackbar } from "notistack";
import CheckboxFormControlLabel from "./checkbox-form-control-label";

const useStyles = makeStyles((theme) => ({
  ackTermsWrapper: {
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  email: {
    marginTop: 10,
    width: "100%",
  },
  emailWrapper: {
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  firstName: {
    flexGrow: 1,
  },
  lastName: {
    flexGrow: 1,
    marginTop: 10,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
      marginTop: 0,
    },
  },
  nameWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      width: "50%",
    },
  },
}));

const UserForm = ({
  showAckTerms,
  onSubmitSuccess,
  onUserAlreadyExists,
  user,
  ackTerms,
  submitText,
  loading,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
    watch,
  } = useForm();
  const watchEmail = watch("email");

  const { isSubmitting } = formState;

  const onCompleted = ({ user }) => {
    window.heap && window.heap.identify(user.email);
    updateIntercom(user);
    onSubmitSuccess(user);
  };

  const onSubmit = async (form) => {
    try {
      onCompleted({
        user: {
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
        },
      });
    } catch (e) {
      if (e instanceof SyntaxError) {
        console.log("onSubmit SyntaxError", e);
      } else {
        console.log("onSubmitError", e);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.nameWrapper}>
        <TextField
          autoFocus
          className={classes.firstName}
          error={!!errors.firstName}
          defaultValue={user?.firstName}
          helperText={errors.firstName ? 'Must be 1-50 characters.' : ''}
          label="First Name"
          name="firstName"
          data-test="user__first-name"
          inputRef={register({ required: true, minLength: 1, maxLength: 50 })}
          type="text"
          variant="outlined"
        />
        <TextField
          className={classes.lastName}
          error={!!errors.lastName}
          defaultValue={user?.lastName}
          helperText={errors.lastName ? 'Must be 1-50 characters.' : ''}
          label="Last Name"
          name="lastName"
          data-test="user__last-name"
          inputRef={register({ required: true, minLength: 1, maxLength: 50 })}
          type="text"
          variant="outlined"
        />
      </Box>
      <Box className={classes.emailWrapper}>
        <TextField
          className={classes.email}
          error={!!errors.email}
          defaultValue={user?.email}
          helperText={!!errors.email && errors.email.message}
          label="Email"
          name="email"
          data-test="user__email"
          inputRef={register({
            validate: (value) => isEmail(value) || "Please enter a valid email",
          })}
          type="text"
          variant="outlined"
        />
      </Box>
      {showAckTerms && (
        <Box className={classes.ackTermsWrapper}>
          <FormControl required error={!!errors.ackTerms} component="fieldset">
            <CheckboxFormControlLabel
              control={
                <Checkbox
                  inputRef={register({ required: true })}
                  name="ackTerms"
                  color="primary"
                  data-test="user__ack-check"
                  defaultChecked={ackTerms}
                />
              }
              label={
                <span data-test="user__ack-terms">
                  By starting an application, I agree to the{" "}
                  <Link
                    data-test="user__pp-link"
                    component={RouterLink}
                    to="/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    data-test="user__tou-link"
                    component={RouterLink}
                    to="/terms-of-use"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>{" "}
                  for use of the website.
                </span>
              }
            />
            {!!errors.ackTerms && (
              <FormHelperText>You must acknowledge to continue</FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
      <Box mt={5} textAlign="center">
        <NextButton
          data-test="user__next"
          disabled={isSubmitting || loading}
          type="submit"
        >
          {submitText}
        </NextButton>
      </Box>
    </form>
  );
};

UserForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  onUserAlreadyExists: PropTypes.func,
  showAckTerms: PropTypes.bool,
  submitText: PropTypes.string,
  loading: PropTypes.bool,
  ackTerms: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

UserForm.defaultProps = {
  showAckTerms: false,
  submitText: "Save",
};

export default UserForm;
