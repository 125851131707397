import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link, Typography } from "@material-ui/core";
import ConfigContext from "../../../config-context";

const ChangeAlert = ({ endorsement, severity }) => {
  const config = useContext(ConfigContext);

  return (
    <Alert severity={severity}>
      <AlertTitle>
        Thanks for updating the {endorsement.changeDescription} for your{" "}
        {config.productName} policy.
      </AlertTitle>
      <Typography component="p" variant="body2" paragraph>
        When you update your {endorsement.changeDescription}, we first notify
        the underwriters of the update. Then, within 48 hours, we email you to
        confirm that your updated policy has become effective. In that email, we
        will also send the updated policy document.
      </Typography>
      <Typography component="p" variant="body2">
        We are only able to process one policy change at a time. If you need to
        cancel this change or make another please{" "}
        <Link
          href={`mailto:${config.supportEmail}`}
          target="_blank"
          underline="always"
        >
          email support
        </Link>
        .
      </Typography>
    </Alert>
  );
};

ChangeAlert.propTypes = {
  endorsement: PropTypes.object,
  severity: PropTypes.string,
};

export default ChangeAlert;
