import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Box, Container, Fade, Typography } from "@material-ui/core";

const Slide = ({ children, title, iconComponent }) => {
  return (
    <SlideTransition>
      <Box py={6}>
        <Container maxWidth="sm">
          {iconComponent && <Box>{iconComponent}</Box>}
          <Box mb={5} mt={iconComponent ? 2 : 0}>
            <Typography align="center" variant="h4" component="h1" data-test="slide__title">
              {title}
            </Typography>
          </Box>
        </Container>
        <Container maxWidth="md">
          <Box data-test="slide__box">{children}</Box>
        </Container>
      </Box>
    </SlideTransition>
  );
};

Slide.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconComponent: PropTypes.node,
};

const SlideTransition = ({ children }) => {
  const history = useHistory();
  return (
    <Fade in timeout={400}>
      {children}
    </Fade>
  );
};

SlideTransition.propTypes = {
  children: PropTypes.node,
};

export default Slide;
