import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { ArrowForward, Menu } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import BounceLogoPrimary from "../images/bounce-logo-primary.png";
import BounceLogoSecondary from "../images/bounce-logo-secondary.png";
import JumpstartLogoSecondary from "../images/jumpstart-logo-secondary.png";
import JumpstartLogoPrimary from "../images/jumpstart-logo-primary.png";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { PATHS } from "../onboarding";
import { isOnboarding, setOnboarding } from "../actions/onboarding";
import { clearQuote } from "../actions/quote";
import { isActiveUser, setUser } from "../actions/user";
import { useStateMachine } from "little-state-machine";
import matchPath from "react-router/modules/matchPath";
import ConfigContext from "../config-context";
import HomeLink from "./home-link";
import FaqLink from "./faq-link";

const logos = {
  bounce: {
    primary: BounceLogoPrimary,
    secondary: BounceLogoSecondary,
  },
  jumpstart: {
    primary: JumpstartLogoPrimary,
    secondary: JumpstartLogoSecondary,
  },
};

const navButtonColors = (theme, isHome) => ({
  bounce: isHome
    ? theme.palette.primary.contrastText
    : theme.palette.primary.main,
  jumpstart: "inherit",
});

const useButtonStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    color: (props) => navButtonColors(theme, props.isHome)[props.tenantSlug],
  },
  label: {
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: 400,
  },
}));

const NavItemButton = (props) => {
  const config = useContext(ConfigContext);
  const { children, isHome, ...rest } = props;

  const classes = useButtonStyles({ tenantSlug: config.tenantSlug, isHome });

  return (
    <Button classes={classes} {...rest}>
      {children}
    </Button>
  );
};

NavItemButton.propTypes = {
  children: PropTypes.node,
  isHome: PropTypes.bool,
};

const buttonColors = {
  bounce: "secondary",
  jumpstart: "primary",
};

const hamburgerButtonColors = (isHome) => ({
  bounce: isHome ? "secondary" : "primary",
  jumpstart: "inherit",
});

const Navigation = ({ mt }) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const { actions, state } = useStateMachine({ setOnboarding, setUser, clearQuote });
  const history = useHistory();
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const isHome = matchPath(location.pathname, {
    path: "/",
    exact: true,
    strict: true,
  });

  const isAccount = Boolean(
    matchPath(location.pathname, {
      path: "/account/coverage",
    })
  );

  const isLogin = Boolean(
    matchPath(location.pathname, {
      path: "/login",
    })
  );

  const appBarClass = isHome ? classes.appBarTransparent : classes.appBar;
  const activeUser = isActiveUser(state);
  const onboarding = isOnboarding(state);

  const logo = logos[config.tenantSlug]?.[isHome ? "secondary" : "primary"];

  const anotherOne = () => {
    actions.clearQuote();
    actions.setOnboarding({ multiPolicy: true });
    history.push("/start/1");
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {onboarding && !activeUser && (
          <ListItem
            button
            key="finish-application"
            className={classes.finishApplication}
            component={RouterLink}
            to={PATHS.BASE}
          >
            <ListItemText primary="Finish Application" />
          </ListItem>
        )}
        <ListItem
          button
          color="inherit"
          key="faq"
          component={FaqLink}
          to="/faq"
          underline="none"
        >
          <ListItemText primary="FAQ" />
        </ListItem>
        {config.blogUrl && (
          <ListItem
            button
            key="blog"
            component="a"
            href={config.blogUrl}
            target="_blank"
          >
            <ListItemText primary="Blog" />
          </ListItem>
        )}
        {config.brokersNav && (
          <ListItem button key="brokers" component="a" href="/admin">
            <ListItemText primary="Brokers" />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        {activeUser && !isAccount && (
          <ListItem
            button
            key="my-account"
            component={RouterLink}
            to="/account"
          >
            <ListItemText primary="My Account" />
          </ListItem>
        )}
        {activeUser ? (
          // <ListItem button key="logout" component="a" href="/users/sign_out">
          //   <ListItemText primary="Log Out" />
          // </ListItem>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowForward />}
            onClick={anotherOne}
          >
            Buy another policy
          </Button>
        ) : (
          <ListItem button key="login" component={RouterLink} to="/login">
            <ListItemText primary="Log In" />
          </ListItem>
        )}
        {!activeUser && !onboarding && (
          <ListItem button key="sign-up" component={RouterLink} to={PATHS.BASE}>
            <ListItemText primary="Get Instant Quote" />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <Box className={classes.root} mt={mt}>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        className={appBarClass}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {/* Offset on left for overflow menu on right - Hidden for mdUp & 1/12 for smDown  */}
            <Hidden mdUp>
              <Grid item xs={1}></Grid>
            </Hidden>

            {/* Left Nav Links - Hidden for smDown & 4/12 for mdUp */}
            <Hidden smDown>
              <Grid item md={4}>
                <NavItemButton
                  color="inherit"
                  component={HomeLink}
                  isHome={isHome}
                  underline="none"
                >
                  Home
                </NavItemButton>
                <NavItemButton
                  color="inherit"
                  component={FaqLink}
                  to="/faq"
                  isHome={isHome}
                  underline="none"
                >
                  FAQ
                </NavItemButton>
                {config.blogUrl && (
                  <NavItemButton
                    color="inherit"
                    href={config.blogUrl}
                    target="_blank"
                    isHome={isHome}
                  >
                    Blog
                  </NavItemButton>
                )}
                {config.brokersNav && (
                  <NavItemButton color="inherit" href="/admin" isHome={isHome}>
                    Brokers
                  </NavItemButton>
                )}
              </Grid>
            </Hidden>

            {/* Logo - centered in all cases  */}
            <Grid item md={4} xs={10}>
              <Box display="flex" justifyContent="center">
                <HomeLink underline="none">
                  <img
                    className={clsx(classes.logo, {
                      [classes.homeLogo]: isHome,
                    })}
                    src={logo}
                    alt={`${config.productName} logo`}
                  />
                </HomeLink>
              </Box>
            </Grid>

            {/* Right Nav links - Hidden for smDown & 4/12 for mdUp */}
            <Hidden smDown>
              <Grid item md={4}>
                <Box display="flex" justifyContent="flex-end">
                  {activeUser && !isAccount && (
                    <NavItemButton
                      color="inherit"
                      component={RouterLink}
                      to="/account"
                      isHome={isHome}
                    >
                      My Account
                    </NavItemButton>
                  )}
                  {activeUser && (
                    // <NavItemButton
                    //   color="inherit"
                    //   component="a"
                    //   href="/users/sign_out"
                    //   isHome={isHome}
                    // >
                    //   Log Out
                    // </NavItemButton>
                    <Button
                      data-test="buy-another__button"
                      variant="contained"
                      color="primary"
                      startIcon={<ArrowForward />}
                      onClick={anotherOne}
                    >
                      Buy another policy
                    </Button>
                  )} 
                  {!activeUser && !isLogin && (
                    <NavItemButton
                      color="inherit"
                      component={RouterLink}
                      to="/login"
                      isHome={isHome}
                    >
                      Log In
                    </NavItemButton>
                  )}
                  {onboarding && !activeUser && (
                    <Button
                      variant="contained"
                      color={buttonColors[config.tenantSlug]}
                      component={RouterLink}
                      to={`/${PATHS.BASE}`}
                    >
                      Continue
                    </Button>
                  )}
                  {!onboarding && !activeUser && (
                    <Button
                      variant="contained"
                      color={buttonColors[config.tenantSlug]}
                      component={RouterLink}
                      to={`/${PATHS.BASE}`}
                    >
                      Get Instant Quote
                    </Button>
                  )}
                </Box>
              </Grid>
            </Hidden>

            {/* Overflow Menu for Mobile - Hidden for mdUp & 1/12 for smDown */}
            <Hidden mdUp>
              <Grid item md={1} xs={1}>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    aria-label="menu"
                    edge="end"
                    color={hamburgerButtonColors(isHome)[config.tenantSlug]}
                    onClick={toggleDrawer(true)}
                  >
                    <Badge
                      color="secondary"
                      variant="dot"
                      invisible={!onboarding || activeUser}
                    >
                      <Menu />
                    </Badge>
                  </IconButton>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </Box>
  );
};

Navigation.propTypes = {
  mt: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.background.default,
  },
  appBarTransparent: {
    // transparency is controlled with the 4th arg. Full transparency now.
    background: "rgb(255,255,255,0)",
  },
  logo: {
    display: "block",
    textAlign: "center",
    margin: "0 auto",
    flexGrow: 1,
    width: theme.spacing(22),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(17),
    },
  },
  homeLogo: {
    width: theme.spacing(34),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(20),
    },
  },
  list: {
    width: 250,
  },
  finishApplication: {
    color: theme.palette.primary.main,
  },
}));

export default Navigation;
