import React, { useContext } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import GetAText from './components/get-a-text'
import Hero from './components/hero'
import NoPaperwork from './components/no-paperwork'
import Quote from './components/quote'
import Plans from './components/plans'
import CardGrid from './components/card-grid'
import Video from './components/video'
import Navigation from 'components/navigation'
import Footer from 'components/footer'
import { useTheme } from '@material-ui/core/styles'
import ConfigContext from 'config-context'
import BounceHero from './components/bounce-hero'
import BounceAllOnline from './components/bounce-all-online'
import BounceNoPaperwork from './components/bounce-no-paperwork'
import BounceQuote from './components/bounce-quote'
import BouncePlans from './components/bounce-plans'
import BounceCardGrid from './components/bounce-card-grid'
import { Redirect } from 'react-router-dom'

const BounceHome = () => {
  return (
    <>
      <BounceHero />
      <BounceAllOnline />
      <BounceNoPaperwork />
      <BounceQuote />
      <BouncePlans />
      <BounceCardGrid />
    </>
  )
}

const JumpstartHome = () => {
  return (
    <>
      <Hero />
      <NoPaperwork />
      <GetAText />
      <Quote />
      <Plans />
      <CardGrid />
      <Video />
    </>
  )
}

const homes = {
  bounce: <BounceHome />,
  jumpstart: <JumpstartHome />,
}

const Home = () => {
  const config = useContext(ConfigContext)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    // <Box>
    //   <Navigation mt={matches ? 3 : 0} />
    //   { homes[config.tenantSlug] }
    //   <Footer/>
    // </Box>
    <Redirect to="/start/1" />
  )
}

export default Home
