import React from "react";
import PropTypes from "prop-types";
import { Box, Container, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LloydsCoverHolderLogo from "images/lloyds-cover-holder-logo.jpg";
import Lightbulb from "images/bounce-lightbulb.png";
import FairInsuranceCodeLogo from "images/fair-insurance-code-logo.png";
import BounceMap from "images/bounce-map.png";
import Image from "material-ui-image";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const BounceCardGrid = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} pb={8}>
      <Container maxWidth="md">
        <Box py={8}>
          <Typography component="h2" variant="h3" align="center">
            Built with science, made for Kiwis
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <CardGridItem
            header="Powered by GeoNet data"
            image={
              <Image
                src={BounceMap}
                aspectRatio={531 / 731}
                color="rgb(255,255,255,0)"
              />
            }
          >
            <Typography>
              To ensure fast and fair claim payments, we use GeoNet - New
              Zealand&apos;s earthquake data authority. This allows us to
              objectively identify areas where Kiwis have experienced a strong
              earthquake.
            </Typography>
          </CardGridItem>
          <CardGridItem
            header="Committed to our customers"
            image={
              <Image
                src={FairInsuranceCodeLogo}
                aspectRatio={294 / 300}
                color="rgb(255,255,255,0)"
              />
            }
          >
            <Typography>
              Bounce is committed to complying with the Fair Insurance Code to
              provide our customers with assurance that we will always strive to
              meet their expectations of high standards of service.{" "}
              <Link
                component={HashLink}
                to="/faq#what-is-the-fair-insurance-code"
              >
                Read more
              </Link>
            </Typography>
          </CardGridItem>
          <CardGridItem
            header="Created by experts"
            image={
              <Image
                src={Lightbulb}
                aspectRatio={200 / 194}
                color="rgb(255,255,255,0)"
              />
            }
          >
            <Typography>
              Led by a{" "}
              <Link
                component={RouterLink}
                to={{ pathname: "/faq", hash: "#what-is-the-bounce-story" }}
                target="_blank"
              >
                Kiwi team
              </Link>
              , with a deep understanding of New Zealand’s insurance industry,
              and its approach to earthquakes.
            </Typography>
          </CardGridItem>
          <Grid item xs={12} md={6}>
            <Box p={3}>
              <Image
                src={LloydsCoverHolderLogo}
                aspectRatio={1774 / 316}
                color="rgb(255,255,255,0)"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    backgroundColor: theme.palette.background.secondary,
  },
}));

export default BounceCardGrid;

const CardGridItem = ({ children, ...props }) => {
  return (
    <Grid item xs={12} md={6}>
      <Box display="flex">
        <Box width="30%">
          <Box p={3}>{props.image}</Box>
        </Box>
        <Box width="70%" align="left" ml={3}>
          <Typography variant="h5">{props.header}</Typography>
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

CardGridItem.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  image: PropTypes.oneOf([PropTypes.string, PropTypes.node, PropTypes.element]),
};
