import React from 'react'
import {Box, Container, Grid, Link, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {NextButton} from "components/buttons"
import {Link as RouterLink} from "react-router-dom"
import {PATHS} from 'onboarding'
import FaqLink from 'components/faq-link'


const Plans = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box py={8}>
          <Typography variant="h3" component="h2">
            Affordable Plans to Suit Your Needs
          </Typography>

          <Typography variant="h6" component="p">
            Learn more about what Jumpstart can do for you.
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Grid
          container
          spacing={2}
          justify="center"
        >
          <Grid item xs={12} md={6}>
            <Box className={classes.plan} p={6}>
              <Typography variant="h6" component="h3">Renters / Homeowners</Typography>
              <Typography variant="h4" component="p">$10,000</Typography>
              <Typography variant="overline" component="p" paragraph>Earthquake Coverage</Typography>
              <NextButton
                color="primary"
                component={RouterLink}
                to={ { pathname: `/${PATHS.BASE}/${PATHS.RESIDENTIAL}`, state: { source: "Main Landing - Residential Plan" } } }
              >
                Get A Quote
              </NextButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.plan} p={6}>
              <Typography variant="h6" component="h3">Small Business / Non-Profit</Typography>
              <Typography variant="h4" component="p">$20,000</Typography>
              <Typography variant="overline" component="p" paragraph>Earthquake Coverage</Typography>
              <NextButton
                color="primary"
                component={RouterLink}
                to={ { pathname: `/${PATHS.BASE}/${PATHS.BUSINESS}`, state: { source: "Main Landing - Business Plan" } } }
              >
                Get A Quote
              </NextButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Box pt={3} pb={8}>
          <Typography display="inline" variant="subtitle2" component="p">
            Need more coverage?&nbsp;
            <FaqLink to={{pathname: "/faq", hash: "#can-i-buy-more-than-10k-of-coverage"}}>Check out our FAQ</FaqLink>.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.primary.borderLight}`
    },
    plan: {
      backgroundColor: theme.palette.background.secondary,
      border: `1px solid ${theme.palette.primary.borderLight}`,
      borderRadius: 4,
      flex: 1,
    },
  })
)

export default Plans
