import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { makeStyles } from '@material-ui/core/styles'

const CardSummary = ({ justifyContent, card, mb, variant }) => {
  const classes = useStyles()

  const cardBrand = useMemo(() => {
    switch (card.brand) {
      case 'visa':
        return card.brand.toUpperCase()
      default:
        return card.brand
          .split(' ')
          .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
          .join(' ')
    }
  }, [card])

  return (
    <Typography variant={variant} component="span">
      <Box
        display="flex"
        alignItems="center"
        justifyContent={justifyContent}
        component="span"
        mb={mb}
      >
        <CreditCardIcon className={classes.icon} />
        <Box ml={1} component="span">
          {cardBrand} ending in {card.last4} ({card.expMonth}/{card.expYear})
        </Box>
      </Box>
    </Typography>
  )
}

CardSummary.propTypes = {
  justifyContent: PropTypes.string,
  card: PropTypes.object,
  mb: PropTypes.number,
  variant: PropTypes.string,
}

CardSummary.defaulProps = {
  justifyContent: 'left',
  mb: 0,
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.textLight,
  },
}))

export default CardSummary
