import React from "react";
import PropTypes from "prop-types";
import Step from "../components/step";
import AddressForm from "components/address-form";

const Address = ({ policy, address, onSubmitSuccess, user }) => {
  return (
    <Step title="Tell us about your new address!">
      <AddressForm
        address={address}
        addressType={address?.addressType || policy.address.addressType}
        onSubmitSuccess={onSubmitSuccess}
        submitText="Next"
        userId={user.id}
      />
    </Step>
  );
};

Address.propTypes = {
  policy: PropTypes.object,
  address: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  user: PropTypes.object,
};

export default Address;
