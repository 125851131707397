import React from 'react'
import {Box, Container, Grid, Link, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'
import {NextButton} from "components/buttons"
import {Link as RouterLink} from "react-router-dom"
import {PATHS} from 'onboarding'


const BouncePlans = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box py={8}>
          <Typography variant="h3" component="h2">
            Affordable cover to suit your needs
          </Typography>

          <Typography variant="h5" component="p" className="mtxl">
            Learn more about what Bounce can do for you.
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Grid
          container
          spacing={2}
          justify="center"
        >
          <Grid item xs={12} md={4}>
            <Box className={classes.plan} p={4}>
              <Typography variant="h6" component="h3">Renters, Homeowners, Business Owners</Typography>
              <Typography variant="h3" component="p" className={classes.price}>$10,000</Typography>
              <Typography variant="overline" component="p" paragraph>Earthquake Coverage</Typography>
              <NextButton
                className={classes.quoteBtn}
                component={RouterLink}
                to={ { pathname: `/${PATHS.BASE}/${PATHS.TEN_K}`, state: { source: "Main Landing - 10K" } } }
              >
                Get A Quote
              </NextButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.plan} p={4}>
              <Typography variant="h6" component="h3">Renters, Homeowners, Business Owners</Typography>
              <Typography variant="h3" component="p" className={classes.price}>$20,000</Typography>
              <Typography variant="overline" component="p" paragraph>Earthquake Coverage</Typography>
              <NextButton
                className={classes.quoteBtn}
                component={RouterLink}
                to={ { pathname: `/${PATHS.BASE}/${PATHS.TWENTY_K}`, state: { source: "Main Landing - 20K" } } }
              >
                Get A Quote
              </NextButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.plan} p={4}>
              <Typography variant="h6" component="h3">Business Owners</Typography>
              <Typography variant="h3" component="p" className={classes.price}>$50,000</Typography>
              <Typography variant="overline" component="p" paragraph>Earthquake Coverage</Typography>
              <NextButton
                className={classes.quoteBtn}
                component={RouterLink}
                to={ { pathname: `/${PATHS.BASE}/${PATHS.BUSINESS}`, state: { source: "Main Landing - Business Plan" } } }
              >
                Get A Quote
              </NextButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Box pt={3} pb={8}>
          <Typography display="inline" variant="subtitle2" component="p">
            Need more coverage?&nbsp;
            <Link component={RouterLink} to={{pathname: "/faq", hash: "#can-i-buy-more-than-10k-of-coverage"}}>Check out our FAQ</Link>.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.primary.borderLight}`
    },
    plan: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 4,
      flex: 1,
      '& h3': {
        minHeight: 65,
      },
    },
    price: {
      fontFamily: theme.typography.secondaryFontFamily,
    },
    quoteBtn: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.borderLight,
      }
    }
  })
)

export default BouncePlans
