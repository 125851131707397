export const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/i
export const isEmail = value => {
  return EMAIL_REGEX.test(value)
}

export const isPhone = (value, pattern) => {
  const regex = new RegExp(pattern)
  const phone = value.match(regex)
  return !!phone
}

export const isZip = (value, pattern) => {
  const regex = new RegExp(pattern)
  const zip = value.match(regex)
  return !!zip
}
