import React, { useState, useMemo, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { Box, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import { setUser, isLead, isLoggedIn } from "actions/user";
import Spinner from "components/spinner";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { find } from "lodash";
import Coverage from "./coverage";
import Phone from "./phone";
import Payment from "./payment";
import User from "./user";
import { PATHS } from "onboarding";
import ChangeAddressApp from "./change-address/change-address-app";
import NewCoverage from "./new-coverage";
import { apiClient, useClientRequest } from "api";

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  tabPanel: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      marginTop: 0,
      marginLeft: theme.spacing(3),
    },
  },
  tabs: {
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: theme.spacing(21),
    },
  },
}));

const AccountApp = () => {
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();
  const route = useLocation();
  const { actions, state } = useStateMachine({ setUser, isLoggedIn });
  const match = useRouteMatch("/account/:tab");
  const tab =
    find(
      ["coverage", "payment", "phone", "contact"],
      (t) => t === match?.params?.tab
    ) || "coverage";
  const [value, setValue] = useState(tab);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (
      !state.user?.policies ||
      !Array.isArray(state.user?.policies) ||
      !state.user?.policies.length
    ) {
      actions.setUser(null);
      history.push(`/start/1`);
      return;
    }

    (async () => {
      try {
        const [{ PolicyNo }, ...rest] = state.user.policies;
        const response = await apiClient.get(
          `/api/v2/jumpstart/getPolicyConfirmation/${PolicyNo}`
        );

        if (response.status !== 200) {
          throw `Unexpected error encountered`;
        }

        actions.setUser({ policies: [response.data?.Payload, ...rest] });
        setIsLoading(false);
      } catch (e) {
        console.log("e", e);
        setErrors(e);
        setIsLoading(false);
      }
    })();
  }, []);

  const handleChange = (event, newValue) => {
    history.push(`/account/${newValue}`);
    setValue(newValue);
  };

  const { data: sessionData } = useClientRequest(
    "/api/v2/jumpstart/getSessionData",
    {
      name: "get_account_session",
    }
  );

  const card = useMemo(() => {
    if (
      !sessionData ||
      !Object.prototype.hasOwnProperty.call(sessionData, "cardBrand") ||
      !Object.prototype.hasOwnProperty.call(
        sessionData,
        "cardExpirationMonth"
      ) ||
      !Object.prototype.hasOwnProperty.call(
        sessionData,
        "cardExpirationYear"
      ) ||
      !Object.prototype.hasOwnProperty.call(sessionData, "cardLast4")
    ) {
      return {};
    }
    const {
      cardBrand,
      cardExpirationMonth,
      cardExpirationYear,
      cardLast4,
    } = sessionData;

    return {
      brand: cardBrand,
      expMonth: cardExpirationMonth,
      expYear: cardExpirationYear,
      lastFour: cardLast4,
    };
  }, [sessionData]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isLead(state)) {
    return <Redirect to={`/${PATHS.BASE}`} />;
  }

  if (!isLoggedIn(state)) {
    localStorage.setItem("prevLocation", location.pathname);
    return <Redirect to="/login" />;
  }

  const prevLocation = localStorage.getItem("prevLocation");

  if (prevLocation && prevLocation != location.pathname) {
    localStorage.removeItem("prevLocation");
    return <Redirect to={prevLocation} />;
  }

  if (location.pathname == path) {
    return <Redirect to={`${path}/coverage`} />;
  }

  return (
    <Switch>
      <Route path="/account">
        {route.pathname === "/account/coverage" && (
          <div className="max-w-[960px] mx-auto">
            <div className=" sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
              <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-200 py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4">
                <p className="text-sm leading-6 text-gray-700">
                  Your policy documents will be delivered to the email address
                  you provided.
                </p>
              </div>
            </div>
          </div>
        )}

        <Box className={classes.tabsWrapper} mt={3}>
          <Tabs
            orientation={matches ? "vertical" : "horizontal"}
            variant="scrollable"
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="Account nav"
            className={classes.tabs}
          >
            <Tab label="Coverage" value="coverage" />
            {/* <Tab label="Mobile Phone" value="phone" />
            <Tab label="Payment Info" value="payment" />
            <Tab label="Contact Info" value="contact" /> */}
          </Tabs>
          <Box className={classes.tabPanel} mt={3}>
            <Switch>
              <Route path={`${path}/coverage`}>
                <Switch>
                  <Route path={`${path}/coverage`} exact>
                    <Coverage />
                  </Route>
                  <Route path={`${path}/coverage/new`} exact>
                    <NewCoverage />
                  </Route>
                  <Route path={`${path}/coverage/:policyNumber/change-address`}>
                    <ChangeAddressApp />
                  </Route>
                </Switch>
              </Route>
              <Route path={`${path}/phone`} exact>
                <Phone />
              </Route>
              <Route path={`${path}/payment`} exact>
                <Payment card={card} />
              </Route>
              <Route path={`${path}/contact`} exact>
                <User />
              </Route>
              <Route path="*">
                <Redirect to={`${path}/coverage`} />
              </Route>
            </Switch>
          </Box>
        </Box>
      </Route>
    </Switch>
  );
};

export default AccountApp;
