import React from "react";
import PropTypes from "prop-types";
import Step from "../components/step";
import PriceResult from "components/price-result";
import { numberToCurrency } from "lib/money";

const Price = ({ policy, productPrice }) => {
  return (
    <Step
      title={`Your new monthly cost is ${numberToCurrency(
        productPrice.monthlyTotal,
        { truncateZeroCents: true }
      )}*`}
    >
      <PriceResult
        nextPath={`/account/coverage/${policy.number}/change-address/4`}
        productPrice={productPrice}
      />
    </Step>
  );
};

Price.propTypes = {
  policy: PropTypes.object,
  productPrice: PropTypes.object,
};

export default Price;
