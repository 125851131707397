import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

const bounceMainFontFamily = ['Space Mono', 'sans-serif']
const bounceSecondaryFontFamily = ['Poppins', 'sans-serif']

const jumpstartMainFontFamily = ['Graphik', 'sans-serif']

const themes = {
  bounce: {
    typography: {
      allVariants: {
        color: '#00557a'
      },
      fontFamily: bounceMainFontFamily,
      secondaryFontFamily: bounceSecondaryFontFamily,
      h1: {
        fontFamily: bounceSecondaryFontFamily,
        fontWeight: 100
      },
      h2: {
        fontFamily: bounceSecondaryFontFamily,
      },
      h3: {
        fontFamily: bounceSecondaryFontFamily,
      },
      h4: {
        fontFamily: bounceSecondaryFontFamily,
      },
      h5: {
        fontFamily: bounceSecondaryFontFamily,
      },
      h6: {
        fontFamily: bounceSecondaryFontFamily,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 33,
        },
      },
      MuiFormHelperText: {
        root: {
          fontWeight: 400 // not sure how to restrict this to just the error state
        }
      },
      MuiInputBase:{
        formControl:{
          fontFamily: bounceSecondaryFontFamily,
          fontWeight: 400,
        }
      },
      MuiInputLabel:{
        root:{
          fontFamily: bounceSecondaryFontFamily,
        }
      },
      MuiLink: {
        underlineHover: {
          textDecoration: 'underline',
        },
      },
      MuiTableCell: {
        body: {
          color: '#00557a',
        },
      },
    },
    palette: {
      primary: {
        main: '#00557a',
        contrastText: '#ffffff',
        borderLight: grey[300],
        borderDark: '#337794',
        textLight: grey[600],
      },
      secondary: {
        main: '#EBEB17',
        contrastText: '#00557a',
      },
      background: {
        default: '#F2F2F2',
        light: '#FFFFFF',
        secondary: '#FBFBFB',
        secondaryLight: '#FFFFFF'
      }
    },
  },
  jumpstart: {
    typography: {
      fontFamily: jumpstartMainFontFamily,
      body1: {
        fontWeight: 400
      },
      body2: {
        fontWeight: 300
      },
      h1: {
        fontWeight: 600
      },
      h2: {
        fontWeight: 500
      },
      h3: {
        fontWeight: 500
      },
      h4: {
        fontWeight: 500
      },
      h5: {
        fontWeight: 500
      },
      h6: {
        fontWeight: 400
      },
      caption: {
        fontWeight: 200
      },
    },
    overrides: {
      MuiFormHelperText: {
        root: {
          fontWeight: 400 // not sure how to restrict this to just the error state
        }
      },
      MuiInputBase:{
        formControl:{
          fontWeight: 400,
        }
      }
    },
    palette: {
      primary: {
        main: '#FF614C',
        contrastText: '#ffffff',
        borderLight: grey[300],
        borderDark: '#f0aa9c',
        textLight: grey[600],
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#FF614C',
      },
      background: {
        default: '#FFFFFF',
        light: '#fdf5ec',
        secondary: '#fbfbfb',
        secondaryLight: '#e9f2fc'
      }
    }
  }
}

const createTheme = (tenant) => {
  let theme = createMuiTheme(themes[tenant])
  theme = responsiveFontSizes(theme)
  return theme
}

export default createTheme
