import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  capitalize,
  compact,
  find,
  join,
  last,
  replace,
  throttle,
  trim,
} from "lodash";
import { useForm } from "react-hook-form";

import { NextButton } from "./buttons";
import { makeStyles } from "@material-ui/core/styles";
import PoweredByGoogle from "../images/powered-by-google.png";
import ConfigContext from "../config-context";
import { apiClient } from "api";
import Spinner from "./spinner";

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  address: {
    width: 380,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  address2: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 10,
      width: 110,
    },
  },
}));

const AddressForm = ({
  address: currentAddress,
  addressType,
  onSubmitSuccess,
  submitText,
  error,
  loading,
}) => {
  const config = useContext(ConfigContext);
  const {
    register,
    handleSubmit,
    errors,
    setError,
    isSubmitting,
  } = useForm();
  const [address, setAddress] = useState(currentAddress);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!window.google) {
      return;
    }

    if (!autocompleteService.current) {
      autocompleteService.current =
        window.google && new window.google.maps.places.AutocompleteService();
    }

    if (!placesService.current) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }

    if ((!options || !options.length) && currentAddress) {
      let address1 = currentAddress?.address1;
      address1 = replace(address1, address2, "");
      address1 = trim(address1);
      let addressStr = [
        address1,
        currentAddress?.city,
        currentAddress?.provinceCode,
        currentAddress?.countryCode,
      ];
      addressStr = join(compact(addressStr), ", ");

      const request = {
        bounds: config.latLngBounds,
        input: addressStr,
      };

      autocompleteService.current.getPlacePredictions(request, (results) => {
        setOptions(results);
        const found = results.find((x) => {
          // eslint-disable-next-line eqeqeq
          return x.structured_formatting?.main_text.toLowerCase() == currentAddress.address1.toLowerCase();
        });

        if (found) {
          setValue(found);
          const addressParts = found.description.split(", ");
          placesService.current.getDetails(
            {
              fields: ["address_components", "formatted_address"],
              placeId: found.place_id,
            },
            (result) => {
              const {
                streetNumber,
                route,
                provinceCode,
                zip,
                countryCode,
                city,
                dependentLocality,
              } = getAddressDetails(result, addressParts);

              setAddress({
                address1: [streetNumber, route].join(" "),
                city,
                countryCode,
                provinceCode,
                dependentLocality,
                zip,
              });
            }
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (typeof error !== "string" && error instanceof Error) {
      setError("address", {
        type: "manual",
        message: error.toString(),
      });

      return;
    }

    setError("address", {
      type: "manual",
      message:
        "Encountered an unexpected error. Please try again momentarily or contact our Support Team",
    });
  }, [error]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        request.bounds = config.latLngBounds;
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChangeOption = (event, value, reason) => {
    if (reason === "select-option") {
      placesService.current.getDetails(
        {
          fields: ["address_components", "formatted_address"],
          placeId: value.place_id,
        },
        (result) => {
          const addressParts = value.description.split(", ");

          const {
            streetNumber,
            route,
            provinceCode,
            zip,
            countryCode,
            city,
            dependentLocality,
          } = getAddressDetails(result, addressParts);

          setAddress({
            address1: [streetNumber, route].join(" "),
            city,
            countryCode,
            provinceCode,
            dependentLocality,
            zip,
          });
        }
      );
    }
  };

  const onCompleted = (resp) => {
    if (Array.isArray(resp.userErrors) && resp.userErrors.length > 0) {
      const errors = resp.userErrors.map((error) => {
        const fieldName =
          last(error.path) === "address2" ? "address2" : "address";
        let message = error.message;
        let notAvailable = false;

        if (fieldName === "address" && message.indexOf("not eligible") > -1) {
          message = `${config.productName} is not available in your area at the moment.`;
          notAvailable = true;
        }

        if (fieldName === "address2") {
          if (message.toLowerCase().indexOf("dropping secondary") >= 0) {
            message = "invalid";
          } else {
            message = "missing";
          }
        }

        return { fieldName, message, notAvailable };
      });

      // Prefer the not available error message
      const error = find(errors, ["notAvailable", true]) || errors[0];
      setError(error.fieldName, {
        type: "manual",
        message: capitalize(error.message),
      });
    } else if (!resp.address) {
      setError("address", { type: "manual", message: "Invalid address" });
    } else {
      onSubmitSuccess(resp.address);
    }
  };

  const onSubmit = async (form) => {
    if (
      !address ||
      !address.address1 ||
      !address.city ||
      !address.provinceCode ||
      !address.countryCode
    ) {
      setError("address", {
        type: "manual",
        message: "Invalid address",
      });
      return false;
    }

    if (!address.address1.trim()) {
      setError("address", {
        type: "manual",
        message: "address is invalid. (city/state/zip + street don't match.)",
      });

      return false;
    }

    // check availability
    try {
      const params = new URLSearchParams({
        zip: address.zip,
        product: 1,
      }).toString();
      const response = await apiClient.get(
        `/api/v2/jumpstart/price/check?${params}`,
        {}
      );

      if (response.status !== 200) {
        throw `Unexpected status code ${response.status}`;
      }

      if (
        response.data &&
        Object.prototype.hasOwnProperty.call(response.data, "productPricing") &&
        Array.isArray(response.data.productPricing) &&
        !response.data.productPricing.length
      ) {
        setError("address", {
          type: "manual",
          message: "Jumpstart is not available in your area at the moment.",
        });
        return;
      }
    } catch (e) {
      setError("address", {
        type: "manual",
        message: "Unable to check availability. Please try again momentarily",
      });
      return;
    }

    const addressData = {
      address: {
        id: null,
        address1: address.address1,
        address2: form.address2,
        addressType: addressType,
        city: address.city,
        countryCode: address.countryCode,
        provinceCode: address.provinceCode,
        zip: address.zip,
      },
      userErrors: null,
    };

    onCompleted(addressData);
  };

  let address2 =
    trim(
      [
        currentAddress?.secondaryDesignator,
        currentAddress?.secondaryNumber,
      ].join(" ")
    ) || null;

  const showLoading = useMemo(() => {
    return isSubmitting || loading;
  }, [isSubmitting, loading])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" justifyContent="center" className={classes.root}>
        <Box>
          <Autocomplete
            disabled={showLoading}
            className={classes.address}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            noOptionsText={`No address found`}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue, reason) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              handleChangeOption(event, newValue, reason);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="address"
                data-test="address__input"
                error={!!errors.address}
                helperText={!!errors.address && errors.address.message}
                label="Insured Address"
                variant="outlined"
                fullWidth
                inputRef={register({ required: true })}
              />
            )}
            renderOption={(option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings;
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );

              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}

                    <Typography data-test="address__option" variant="body2" color="textSecondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
          />
          <Box display="flex" justifyContent="flex-end">
            <Box mt={1}>
              <img src={PoweredByGoogle} alt="Powered by Google" width="85" />
            </Box>
          </Box>
        </Box>
        <TextField
          className={classes.address2}
          error={!!errors.address2}
          defaultValue={address2 || address?.address2}
          helperText={errors.address2?.message}
          label="Apt/Unit #"
          name="address2"
          data-test="address__unit"
          inputRef={register({ required: false })}
          inputProps={{
            maxLength: 10,
          }}
          type="text"
          variant="outlined"
        />
      </Box>

      <Box mt={5} textAlign="center">
        <NextButton data-test="address__next" disabled={showLoading} type="submit">
          {showLoading ? (
            <Spinner />
          ): (
            submitText
          )}
        </NextButton>
      </Box>
    </form>
  );
};

function getAddressDetails(result, addressParts) {
  const streetNumber = find(result.address_components, (c) =>
    find(c.types, (t) => t === "street_number")
  )?.short_name;

  const route = find(result.address_components, (c) =>
    find(c.types, (t) => t === "route")
  )?.short_name;

  const provinceCode = find(result.address_components, (c) =>
    find(c.types, (t) => t === "administrative_area_level_1")
  )?.short_name;

  const zip = find(result.address_components, (c) =>
    find(c.types, (t) => t === "postal_code")
  )?.short_name;

  const countryCode = find(result.address_components, (c) =>
    find(c.types, (t) => t === "country")
  )?.short_name;

  let city = find(result.address_components, (c) =>
    find(c.types, (t) => t === "locality")
  )?.short_name;
  if (!city && countryCode !== "US") {
    city = addressParts[addressParts.length - 2];
  }

  let dependentLocality = find(result.address_components, (c) =>
    find(c.types, (t) => t === "sublocality_level_1")
  )?.short_name;
  if (!dependentLocality && countryCode !== "US") {
    dependentLocality = addressParts[addressParts.length - 3];
  }

  return {
    streetNumber,
    route,
    city,
    provinceCode,
    countryCode,
    zip,
    dependentLocality,
  };
}

AddressForm.propTypes = {
  address: PropTypes.object,
  addressType: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  submitText: PropTypes.string,
  userId: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

AddressForm.defaultProps = {
  addressType: "RESIDENTIAL",
  submitText: "Save",
};

export default AddressForm;
