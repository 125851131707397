import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { PrimaryButton } from "components/buttons";
// import ProductPrice from "components/product-price";
import ProductSelect from "components/product-selection";
import Spinner from "components/spinner";

const PriceResult = ({ loading, productPrice, productPrices, onCoverageChange, onTypeChange, onNext }) => {
  return (
    <>
      {/* <ProductPrice productPrice={productPrice} /> */}
      <ProductSelect
        productPrice={productPrice}
        priceList={productPrices}
        onCoverageChange={onCoverageChange}
        onTypeChange={onTypeChange}
      />
      <Box mt={2}>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <Typography data-test="price-select__subtitle" align="center" component="p" variant="subtitle1">
              Lump-sum payment after a disruptive earthquake to cover your extra
              expenses.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={5} textAlign="center">
        <PrimaryButton data-test="price-select__next" onClick={onNext} disabled={loading}>
          {loading ? (
            <Spinner />
          ) : (
          "Next"
          )}
          </PrimaryButton>
      </Box>
      <Box mt={5} textAlign="center">
        <Typography variant="caption" data-test="price-select__caption">
          <sup>*</sup>Including base monthly premium, taxes, and fees. Additional credit card convenience fee applies.
        </Typography>
      </Box>
    </>
  );
};

PriceResult.propTypes = {
  productPrice: PropTypes.object,
  productPrices: PropTypes.arrayOf(PropTypes.object),
  onCoverageChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  onNext: PropTypes.func,
  loading: PropTypes.bool,
};

export default PriceResult;
