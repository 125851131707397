import React from 'react'
import {Box, Typography} from '@material-ui/core'
import HomeLink from 'components/home-link'

const NoMatch = () => {
  return (
    <Box align="center">
      <Typography component="h1" variant="h3" paragraph>
        Not Found
      </Typography>
      <Typography component="p" variant="h6">
        Oops! There&apos;s nothing here. Go back to the <HomeLink>home page</HomeLink>.
      </Typography>
    </Box>
  )
}

export default NoMatch