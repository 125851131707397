import React from 'react'
import {Box, Container, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'


const BounceQuote = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box pt={10} pb={4}>
          <Typography variant="h2" color="secondary" component="h2" className={classes.quote}>
            “Prices double overnight after an earthquake. If we had Bounce 10 years ago, it would have alleviated stress, and been very helpful as part of our recovery.”
          </Typography>
        </Box>
        <Box pb={10}>
          <Typography component="p" variant="h6" className={classes.quoted}>
            - Erin, Customer, Christchurch
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    quote: {
      color: theme.palette.primary.contrastText,
      fontWeight: 400,
    },
    quoteHighlight: {
      color: theme.palette.secondary.main,
    },
    quoted: {
      color: theme.palette.primary.contrastText,
      fontWeight: 400,
      fontStyle: 'italic',
    },
    largeLeftQuote: {
      fontFamily: ['Merriweather', 'serif'],
      '&:before': {
        content: '"\\201C"',
        fontSize: '5rem',
        lineHeight: 'inherit',
      },
    },
    largeRightQuote: {
      fontFamily: ['Merriweather', 'serif'],
      '&:after': {
        content: '"\\201D"',
        fontSize: '5rem',
        lineHeight: 'inherit',
      }
    },


  })
)

export default BounceQuote
