import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { NextButton } from "components/buttons";
import ChangeAlert from "../components/change-alert";
import Step from "../components/step";

const Done = ({ endorsement }) => {
  return (
    <Step title="Change successfully submitted!">
      <ChangeAlert endorsement={endorsement} severity="success" />
      <Box mt={5} textAlign="center">
        <NextButton to="/account/coverage">Done</NextButton>
      </Box>
    </Step>
  );
};

Done.propTypes = {
  endorsement: PropTypes.object,
};

export default Done;
