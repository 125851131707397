import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Box, Link, Typography } from "@material-ui/core";

const AddressSummary = ({
  variant,
  address: { address1, address2, localities, zip },
  children,
}) => {
  return (
    <Box>
      <Typography variant={variant}>
        {address1}
        {address2 ? ` ${address2}` : null}
        {", "}
        {localities}
        {"  "}
        {zip} {children}
      </Typography>
    </Box>
  );
};

AddressSummary.defaultProps = {
  variant: "body2",
};

AddressSummary.propTypes = {
  variant: PropTypes.string,
  address: PropTypes.object,
  children: PropTypes.node,
};

export default AddressSummary;
