import React, { useContext, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { makeStyles } from "@material-ui/core/styles";
import { startCase } from "lodash";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

// internal
import { useClientMutation } from "api";
import { PATHS } from "../../onboarding-app";
import { capitalize } from "lodash";
import { numberToCurrency } from "lib/money";
import { formatPhone } from "lib/format";
import AddressSummary from "components/address-summary";
import ConfigContext from "../../../config-context";
import { setOnboarding } from "actions/onboarding";
import { getSession, setSession } from "actions/session";
import EditableLabel from "components/editable-label";
import PriceLabel from "components/price-label";
import CardSummary from "components/card-summary";
import { getProductKey } from "lib/hash-code";

const useStyles = makeStyles((theme) => ({
  tighterMobileMargin: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  orderSummaryWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  stickySum: {
    position: "sticky",
    top: "32px",
  },
}));

export const Summary = ({
  xs,
  sm,
  md,
  lg,
  checkoutPrice,
  onIntervalChange,
  onCoverageChange,
}) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const { actions, state } = useStateMachine({ setSession, setOnboarding });
  const { path, url } = useRouteMatch();

  const { request: setSessionData } = useClientMutation(
    "/api/v2/jumpstart/setSessionData",
    {
      name: "set_session_checkout",
      method: "POST",
      onCompleted: (data) => {
        actions.setSession(data);
      },
    }
  );

  const handelIntervalChange = async (event) => {
    const { value } = event.target;
    let paymentInterval;
    switch (value) {
      case "MONTH":
        paymentInterval = "Monthly";
        break;
      case "YEAR":
        paymentInterval = "Annual";
        break;
    }

    actions.setOnboarding({ subscriptionInterval: event.target.value });
    const session = getSession(state);
    await setSessionData({
      body: {
        ...session,
        paymentInterval,
      },
    });

    onIntervalChange(paymentInterval);
  };

  const address = useMemo(() => {
    const { addr1, addr2, city, state: quoteState, zip } = state.quote;
    return {
      address1: startCase(addr1.toLowerCase()),
      address2: addr2,
      city,
      provinceCode: quoteState,
      zip,
    }
  }, [state.quote]);

  const stickySideRef = useRef(null);
  const stickyContentRef = useRef(null);

  const formattedPhone = formatPhone(
    state.onboarding.phone,
    config.phoneFormat,
    config.countryCallingCode
  );

  const productPrices = useMemo(() => {
    if (!state.onboarding.productPrices) {
      return [];
    }

    if (!state.onboarding.address) {
      return state.onboarding.productPrices;
    }

    const {
      productPrices,
      address: { addressType },
    } = state.onboarding;

    return productPrices.filter((x) => {
      return x.productType === addressType;
    });
  }, [state.onboarding.productPrices, state.onboarding.address]);

  const value = useMemo(() => {
    if (!state.onboarding.checkoutPrice) {
      return {};
    }

    if (
      Object.prototype.hasOwnProperty.call(state.onboarding.checkoutPrice, "id")
    ) {
      return state.onboarding.checkoutPrice;
    }

    return {
      ...state.onboarding.checkoutPrice,
      id: getProductKey(state.onboarding.checkoutPrice),
    };
  }, [state.onboarding.checkoutPrice]);

  const handleCoverageChange = (ev) => {
    if (!ev.target?.value) {
      return;
    }

    const { value } = ev.target;
    const selected = state.onboarding.productPrices.find((x) => x.id === value);
    onCoverageChange(selected);
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <Box mx={4} ref={stickySideRef} />
      <Box
        className={clsx(
          classes.orderSummaryWrapper,
          classes.tighterMobileMargin,
          classes.stickySum
        )}
        m={4}
        ref={stickyContentRef}
      >
        <Box>
          <Paper elevation={0} variant="outlined">
            <Box p={2}>
              <Typography data-test="primary-contact" variant="overline" paragraph>
                Primary Contact
              </Typography>
              <EditableLabel
                changeTo={{
                  pathname: PATHS.NAME,
                  state: { prevPath: path, toPath: path },
                }}
                labelText="Name"
                showDivider
              >
                {state.quote.first_name} {state.quote.last_name}
              </EditableLabel>
              <EditableLabel
                changeTo={{
                  pathname: PATHS.NAME,
                  state: { prevPath: path, toPath: path },
                }}
                labelText="Email"
                showDivider
              >
                {state.quote.email}
              </EditableLabel>
              <EditableLabel
                changeTo={{
                  pathname: PATHS.PHONE_VERIFICATION,
                  state: { updatePhone: true, toPath: path, prevPath: path },
                }}
                labelText="Phone"
                showDivider={false}
              >
                {formattedPhone}
              </EditableLabel>
            </Box>
          </Paper>

          <Box mt={2} />

          <Paper elevation={0} variant="outlined">
            <Box p={2}>
              <Typography variant="overline" paragraph>
                Insured Address
              </Typography>
              <EditableLabel
                changeTo={{
                  pathname: PATHS.ADDRESS,
                  state: { toPath: path, prevPath: path },
                }}
                labelText="Address"
                showDivider={false}
              >
                <AddressSummary
                  address={address}
                  variant="subtitle2"
                />
              </EditableLabel>
            </Box>
          </Paper>

          <Box mt={2} />

          <Paper elevation={0} variant="outlined">
            <Box p={2}>
              <Typography variant="overline" paragraph>
                Coverage
              </Typography>
              <EditableLabel
                changeTo={{
                  pathname: PATHS.BUILDING_TYPE,
                  state: { toPath: path, prevPath: path },
                }}
                labelText="Type"
                showDivider
              >
                {capitalize(state.onboarding.address.addressType)} coverage
              </EditableLabel>
              <Box mt={1.5}>
                <EditableLabel labelText="Amount" showDivider={false}>
                  {productPrices.length > 1 && (
                    <FormControl variant="outlined">
                      <InputLabel>Coverage</InputLabel>
                      <Select
                        data-test="coverage__dropdown"
                        value={value.id}
                        onChange={handleCoverageChange}
                        label="Coverage"
                      >
                        {productPrices.map((p) => (
                          <MenuItem data-test={"coverage__" + p.coverageLimit} key={p.id} value={p.id}>
                            {numberToCurrency(p.coverageLimit)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {productPrices.length === 1 && (
                    <>
                      {numberToCurrency(checkoutPrice.coverageLimit, {
                        truncateZeroCents: true,
                      })}{" "}
                      of coverage
                    </>
                  )}
                </EditableLabel>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box mt={2} />

        <Paper elevation={0} variant="outlined">
          <Box p={2}>
            <Typography data-test="payment-info__title" variant="overline" paragraph>
              Payment Information
            </Typography>
            <Box mt={2}>
              <FormControl component="fieldset" fullWidth={true}>
                <RadioGroup
                  aria-label="interval"
                  name="interval"
                  value={state.onboarding.subscriptionInterval}
                  onChange={handelIntervalChange}
                >
                  <FormControlLabel
                    value="MONTH"
                    control={<Radio data-test="payment__monthly" color="primary" />}
                    label={
                      <PriceLabel
                        checkoutPrice={checkoutPrice}
                        coupon={state.onboarding.couponCode}
                        transactionFee={state.onboarding.transactionFees.monthly}
                        interval="month"
                        verbose={true}
                        showDiscountTerms
                      />
                    }
                  />

                  <FormControlLabel
                    value="YEAR"
                    control={<Radio data-test="payment__yearly" color="primary" />}
                    label={
                      <PriceLabel
                        checkoutPrice={checkoutPrice}
                        coupon={state.onboarding.couponCode}
                        transactionFee={state.onboarding.transactionFees.yearly}
                        interval="year"
                        verbose={true}
                        showDiscountTerms
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box py={1}>
              <Divider />
            </Box>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <CardSummary card={state.onboarding.card} />
              </Box>
              <Typography align="right">
                <Link
                  data-test="edit-payment"
                  variant="body2"
                  color="primary"
                  component={RouterLink}
                  to={{
                    pathname: PATHS.PAYMENT_METHOD,
                    state: { updateCard: true, toPath: path, prevPath: path },
                  }}
                >
                  Change
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
};

Summary.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  xl: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  checkoutPrice: PropTypes.object,
  onIntervalChange: PropTypes.func,
  onCoverageChange: PropTypes.func,
};
