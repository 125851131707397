import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { AppLayout } from "./layout";
import Spinner from "../components/spinner";
import Slide from "../onboarding/components/slide";
import { useStateMachine } from "little-state-machine";
import { useRouteQuery } from "../hooks";
import { setOnboarding, setPriceList, setRenewalOnboarding } from "actions/onboarding";
import { setJumpstartSession } from "actions/session";
import { setQuote } from "actions/quote";
import { useClientRequest } from "api";
import { PATHS } from "../onboarding/onboarding-app";
import ConfigContext from '../config-context'

export const RenewalApp = () => {
  const config = useContext(ConfigContext);
  const history = useHistory();
  // query search params
  const query = useRouteQuery();
  const sessionID = query.get("session");
  // screen error
  const [error, setError] = useState();
  const { actions, state } = useStateMachine({
    setJumpstartSession,
    setOnboarding,
    setQuote,
    setPriceList,
    setRenewalOnboarding,
  });


  // session
  const { request: getSession } = useClientRequest(
    "/api/v2/jumpstart/getSessionData",
    {
      name: "get_account_session",
      lazy: true,
      onCompleted: (data) => {
        actions.setJumpstartSession(data);
      },
      onError: (err) => {
        setError(err);
      },
    }
  );

  // quote
  const { request: getQuote, errors: getErrors } = useClientRequest(
    "/api/v4/direct/quotes",
    {
      lazy: true,
      onCompleted: (quoteData) => {
        if (Object.prototype.hasOwnProperty.call(quoteData, "quote_number")) {
          actions.setQuote(quoteData);
        }
      },
    }
  );

  // price list
  const { loading: pricesLoading, request: getPrices } = useClientRequest(
    "/api/v2/jumpstart/price/list",
    {
      lazy: true,
      onCompleted: async (data) => {
        if (
          !data ||
          !data.pricing ||
          (Array.isArray(data.pricing) && !data.pricing.length)
        ) {
          throw new Error("No pricing data available");
        }

        const addressType = state.quote.product_no === 4 ? "RESIDENTIAL" : "BUSINESS";
        const coverageLimit = state.quote.earthquakeCoverage;
        actions.setPriceList({ data: data.pricing, config, addressType, coverageLimit });
        actions.setRenewalOnboarding();
        history.push(`/${PATHS.BASE}/${PATHS.PAYMENT_METHOD}`);
      },
      // eslint-disable-next-line no-unused-vars
      onError: async (e) => {
        console.log("e", e);
        actions.setOnboarding({ monthlyTotal: "" });
        history.push(PATHS.UNAVAILABLE);
      },
    }
  );
  
  // Fetch session dependent on sessionID
  useEffect(() => {
    if (!sessionID) {
      history.push(`/${PATHS.BASE}`);
    }

    (async () => {
      await getSession({
        args: {
          sessionId: sessionID,
        }
      });
    })();
  }, [sessionID]);

  // fetch quote dependent on state session data
  useEffect(() => {
    if (!state.session || !state.session.quoteNumber) {
      return;
    }

    (async () => {
      const quote_number = state.session.quoteNumber;
      await getQuote({
        args: {
          quote_number,
        },
      });
    })();
  }, [state.session]);

  // fetch price list dependent on state quote data
  useEffect(() => {
    if (!state.quote) {
      return;
    }

    (async () => {
      const zip = state.quote.zip;
      await getPrices({
        args: {
          zip,
        },
      });
    })();
  }, [state.quote]);

  return (
    <AppLayout>
      <Slide title="One sec. We're loading your application details.">
        <Box>
          <Spinner />
        </Box>
      </Slide>
    </AppLayout>
  );
};
