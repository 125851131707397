import React from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { numberToCurrency } from "../lib/money";
import { makeStyles } from "@material-ui/core/styles";

const ProductPrice = ({ productPrice }) => {
  const classes = useStyles();

  const coverageLimit = productPrice?.coverageLimit ?? 0;

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography className={classes.desc} data-test="product__coverage-amount" variant="body1">
                Coverage Amount
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className={classes.value} data-test="product__coverage-dropdown" variant="body1">
                {numberToCurrency(coverageLimit, { truncateZeroCents: true })}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography className={classes.desc} data-test="product__deductible" variant="body1">
                Deductible
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography className={classes.value} data-test="product__deductible-0" variant="body1">
                $0
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProductPrice.propTypes = {
  productPrice: PropTypes.object,
};

const useStyles = makeStyles(() => ({
  cityZip: {
    position: "relative",
  },
  zip: {
    position: "absolute",
    bottom: "-6px",
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    width: "100%",
  },
  city: {
    display: "block",
    margin: "0 auto",
    width: "140px",
  },
  banner: {
    display: "block",
    margin: "-10px auto 0",
  },
  table: {
    maxWidth: "360px",
    margin: "0 auto",
  },
  desc: {
    fontWeight: "bold",
  },
}));

export default ProductPrice;
