import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'
import { useStateMachine } from 'little-state-machine'
import Slide from 'onboarding/components/slide'
import { setOnboarding } from 'actions/onboarding'
import { setUser } from 'actions/user'
import PriceLabel from 'components/price-label'
import PaymentWizard from 'components/payment-wizard'
import { numberToCurrency } from 'lib/money'
import SamplePolicyLink from 'components/sample-policy-link'
import ConfigContext from 'config-context'

const PaymentMethod = ({ history, location, nextPath }) => {
  const { actions, state } = useStateMachine({ setOnboarding, setUser })
  const config = useContext(ConfigContext)

  const card = state.onboarding.card
  const userId = state.onboarding.userId
  const updateCard = location?.state?.updateCard
  const toPath = location?.state?.toPath ?? nextPath

  const checkoutPrice = state.onboarding.checkoutPrice

  const onUpdateCard = (card) => {
    actions.setOnboarding({
      card,
      subscriptionInterval: state.onboarding.subscriptionInterval ?? 'MONTH',
    })
  }

  const onSubmitSuccess = () => {
    history.push(toPath)
  }

  const interval = useMemo(() => {
    if (!state.onboarding || !state.onboarding.subscriptionInterval) {
      return 'MONTH'
    }

    return state.onboarding.subscriptionInterval
  }, [state.onboarding.subscriptionInterval])

  return (
    <Slide data-test="payment__title" title="Enter your payment info">
      <Box mb={5}>
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Typography
              data-test="payment__subtitle"
              align="center"
              component="h2"
              variant="h6"
            >
              Your premium is{' '}
              <PriceLabel
                data-test="payment__premium"
                coupon={state.onboarding.couponCode}
                checkoutPrice={checkoutPrice}
                interval={interval}
                showDiscountTerms
              />{' '}
              for{' '}
              {numberToCurrency(checkoutPrice.coverageLimit, {
                truncateZeroCents: true,
              })}{' '}
              of coverage, including all applicable taxes and fees.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box data-test="payment__sample-policy" textAlign="center" mb={5}>
        {config.enableSamplePolicy && (
          <SamplePolicyLink
            params={{
              coverage_limit: state.onboarding.checkoutPrice.coverageLimit,
              province_code: state.onboarding.address.provinceCode,
            }}
          />
        )}
      </Box>
      <PaymentWizard
        card={card}
        onUpdateCard={onUpdateCard}
        onSubmitSuccess={onSubmitSuccess}
        showAckCoverageFootnote
        showCouponHint={!state.onboarding.couponCode}
        showUpdateCard={updateCard}
        submitText="Save"
        userId={userId}
      />
    </Slide>
  )
}

PaymentMethod.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  nextPath: PropTypes.string,
}

export default PaymentMethod
