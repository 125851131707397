import React from "react";
import PropTypes from "prop-types";
import Slide from "onboarding/components/slide";
import MailingListForm from "onboarding/components/mailing-list-form";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "../../actions/onboarding";

const Disabled = ({ history, nextPath }) => {
  const { action, state } = useStateMachine(setOnboarding);

  return (
    <Slide title="Sorry, we are currently not accepting requests for new policies at this time.">
      <MailingListForm
        onSubmitSuccess={() => history.push(nextPath)}
        zip={state.onboarding.quoteZip}
      />
    </Slide>
  );
};

Disabled.propTypes = {
  history: PropTypes.object,
  nextPath: PropTypes.string,
};

export default Disabled;
