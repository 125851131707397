import React from 'react'
import Slide from 'onboarding/components/slide'
import QuoteForm from 'onboarding/components/quote-form'

const PriceQuote = props => {
  return (
    <Slide title="All we need is your ZIP code to find your price. Ready?">
      <QuoteForm {...props} />
    </Slide>
  )
}

export default PriceQuote
