export function hashCode(s) {
  let h;

  for(let i = 0; i < s.length; i++) {
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;
  }

  return h;
}

export function getProductKey(x) {
  const stringKey = [x.productType, x.productId, x.coverageLimit].join("-");
  return hashCode(stringKey);
}
