import React from 'react'
import {Box, Container, Typography} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'

const BounceAllOnline = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth="md" classes={{ maxWidthMd: classes.maxWidthMd }}>
        <Box py={8}>
          <Typography align="center" variant="h3" component="h2">
            All online, signup in minutes,
            claims paid in days.
          </Typography>
          <Typography align="center" component="p" variant="h5" className="mtxl">
            Use the money for anything you need to bounce-back.
          </Typography>
        </Box>
        <Box pb={8}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/PN-hAhYKSDo?playlist=PN-hAhYKSDo&loop=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Container>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.primary.borderLight}`
    },
    maxWidthMd: {
      maxWidth: 725,
    },
    beckWrapper: {
      marginBottom: -5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
  })
)

export default BounceAllOnline
