import { useState, useEffect } from "react";
import { apiClient } from "./client";
import { buildURL } from "./utils";

export function useClientRequest(url, options) {
  // state
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // input
  const { name, onCompleted, onError, lazy = false, ...fetchOptions } = options;
  // immediate hook
  useEffect(() => {
    // if request is LAZY do not immediately request
    if (lazy || loading) {
      return;
    }

    // iife
    (async () => {
      try {
        setLoading(true);
        const { data: fetchedData, status } = await apiClient.get(url, options);

        if (status === 200) {
          setData(fetchedData);
        } else {
          throw new Error(
            `Unexpected status in ${name}. Expected 200, got ${status}`
          );
        }

        setLoading(false);
        onCompleted && onCompleted(fetchedData);
      } catch (e) {
        console.log("Unexpected error in useClientRequest");
        console.log(e);
        setError(e.toString() || `Error encountered: ${e}`);
        setLoading(false);
        onError && onError(e);
      }
    })();
  }, [url, lazy]);

  // lazy request
  const request = async (requestOptions) => {
    try {
      setLoading(true);
      const { args, ...requestOverrideOptions } = requestOptions;
      const params = new URLSearchParams(args).toString();
      const newURL = params ? `${url}?${params}` : url;
      const { data: fetchedData, status } = await apiClient.get(newURL, {
        ...fetchOptions,
        ...requestOverrideOptions,
      });

      if (status !== 200) {
        throw new Error(
          `Unexpected status in ${name}. Expected 200, got ${status}`
        );
      }

      setData(fetchedData);
      setLoading(false);
      onCompleted && (await onCompleted(fetchedData));
      return {
        data: fetchedData,
        error: null,
      };
    } catch (e) {
      setError(e.toString() || `Error encountered: ${e}`);
      setLoading(false);
      onError && (await onError(e));
      return {
        data: null,
        error: e,
      };
    }
  };

  return { data, error, request };
}

export function useClientMutation(
  url,
  options = { method: "POST", name: "mutation" }
) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    name,
    onCompleted,
    onError,
    method: defaultMethod,
    ...defaultFetchOptions
  } = options;

  const request = async ({ body, args, method, ...inputRequestOptions } = {}) => {
    try {
      setLoading(true);
      let response;
      const selectedMethod = method ?? defaultMethod;
      const requestOptions = {
        ...defaultFetchOptions,
        ...inputRequestOptions,
      };

      const builtURL = buildURL(url, args);
      if (selectedMethod === "PUT") {
        response = await apiClient.put(builtURL, body, requestOptions);
      } else {
        response = await apiClient.post(builtURL, body, requestOptions);
      }

      const { status, data: responseData } = response;

      if (status !== 200 && status !== 204) {
        throw new Error(
          `Unexpected status in ${name}. Expected 200, got ${status}`
        );
      }

      // set hook state
      setData(responseData);
      // set loading
      setLoading(false);
      // invoke callback
      onCompleted && (await onCompleted(responseData, body));
      return {
        data: responseData,
        error: null,
      };
    } catch (e) {
      // set hook state
      setError(e);
      setData(null);
      // set loading
      setLoading(false);
      // invoke error callback
      onError && (await onError(e, body));
      return {
        data: null,
        error: e,
      };
    }
  };

  return { data, loading, error, request };
}
