import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { ThemeProvider } from "@material-ui/core/styles";
import qs from "query-string";

import CssBaseline from "@material-ui/core/CssBaseline";
import { setSystemConfiguration } from "actions/system-configuration";
import { setOnboarding } from "actions/onboarding";
import { resetUser, setUser } from "actions/user";
import theme from "themes/default";
import { useSnackbar } from "notistack";

const AppConfigProvider = ({ children, flash }) => {
  const { actions } = useStateMachine({
    resetUser,
    setOnboarding,
    setSystemConfiguration,
    setUser,
  });
  const { enqueueSnackbar } = useSnackbar();

  let couponCode = qs.parse(window.location.search)?.cid;
  if (Array.isArray(couponCode)) {
    couponCode = couponCode[0];
  }

  useEffect(() => {
    flash.forEach((f) => {
      enqueueSnackbar(f.message, { variant: f.type });
    });
  }, [flash]);

  useEffect(() => {
    if (couponCode) {
      actions.setOnboarding({ couponCode: couponCode });
    }
  }, [couponCode]);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme("jumpstart")}>{children}</ThemeProvider>
    </>
  );
};

AppConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flash: PropTypes.array,
};

export default AppConfigProvider;
