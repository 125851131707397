import React, {useContext} from 'react'
import {Box, Typography, Link} from '@material-ui/core'
import ConfigContext from '../config-context'

const InternalServerError = () => {
  const config = useContext(ConfigContext)

  return (
    <Box align="center">
      <Typography component="h1" variant="h3" paragraph>
        System Error
      </Typography>
      <Typography component="p" variant="h6">
        These errors are tracked automatically and we are looking into the problem now. If you need support contact us at <Link href={`mailto:${config.supportEmail}`} target="_blank" underline="always">{config.supportEmail}</Link>
      </Typography>
    </Box>
  )
}

export default InternalServerError