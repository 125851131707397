import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  Icon,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, Route } from "react-router-dom";
import { NextButton } from "./buttons";
import FacebookIcon from "@material-ui/icons/Facebook";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { makeStyles } from "@material-ui/core/styles";
import GoogleSVG from "images/google-icon.svg";

const LoginChooser = ({ email }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box mb={3}>
        <Button
          variant="contained"
          className={classes.facebook}
          color="primary"
          href="/auth/facebook"
          startIcon={<FacebookIcon />}
        >
          Log in with Facebook
        </Button>
      </Box>
      <Box mb={3}>
        <Button
          variant="outlined"
          className={classes.google}
          color="primary"
          href="/auth/google_oauth2"
          startIcon={<GoogleIcon />}
        >
          Log in with Google
        </Button>
      </Box>
      <Box mb={3}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={{ pathname: "/login/email", state: { email: email } }}
          startIcon={<MailOutlineIcon />}
        >
          Log in with Email
        </Button>
      </Box>
    </Box>
  );
};

LoginChooser.propTypes = {
  email: PropTypes.string,
};

const GoogleIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512" height="512" width="512">
      <g fill="none" fillRule="evenodd">
        <path
          d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z"
          fill="#4285f4"
        />
        <path
          d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z"
          fill="#34a853"
        />
        <path
          d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z"
          fill="#fbbc05"
        />
        <path
          d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z"
          fill="#ea4335"
        />
        <path d="M20 20h472v472H20V20z" />
      </g>
    </SvgIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    "& a": {
      padding: "11px 30px",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: { width: "60%", maxWidth: 300 },
  },
  facebook: {
    backgroundColor: "#3b5998",
    "&:hover": {
      backgroundColor: "#2d4373",
    },
  },
  google: {
    backgroundColor: "#ffffff",
    border: "1px solid #DDDDDD",
    color: "#4A4A4A",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      borderColor: "#BEBEBE",
    },
  },
}));

export default LoginChooser;
