import React, { useContext } from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Help } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import ConfigContext from "../config-context";
import { makeStyles } from "@material-ui/core/styles";

export const HelpAlert = (props) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  return (
    <Alert severity="info" icon={<Help />} {...props}>
      <AlertTitle>Need assistance?</AlertTitle>
      <Typography>
        Contact our support team at{" "}
        <a
          href={`mailto:${config.supportEmail}`}
          className={classes.supportLink}
        >
          {config.supportEmail}
        </a>{" "}
        or{" "}
        <a href={`tel:${config.supportPhone}`} className={classes.supportLink}>
          {config.supportPhone}
        </a>
      </Typography>
    </Alert>
  );
};

const useStyles = makeStyles((theme) => ({
  supportLink: {
    color: "rgb(13, 60, 97)",
    textDecoration: "underline",
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
}));
