import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { TextMaskCustom } from "./text-mask-custom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, TextField } from "@material-ui/core";
import { NextButton } from "./buttons";
import { isPhone } from "../lib/form-validation";
import ConfigContext from "../config-context";
import { useClientMutation } from "api";

const useStyles = makeStyles((theme) => ({
  phone: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const PhoneForm = ({ userId, onCancel, onSubmitSuccess, phoneNumber }) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const { register, handleSubmit, errors, isSubmitting, setError } = useForm();

  const onSubmit = async (data) => {
    const phone = data.phone.replace(/[^0-9]/g, "").trim();
    const body = {
      userId: userId,
      // without country code
      phone,
      // with country code
      // phone: `${config.countryCallingCode}${data.phone.replace(/[^0-9]/g, '').trim()}`
    };

    // start phone verification
    await verifyPhoneStart({
      body,
    });
  };

  const { request: verifyPhoneStart } = useClientMutation(
    "/api/v2/jumpstart/phone/verify",
    {
      name: "verify_phone_start",
      method: "POST",
      onCompleted: async (resData) => {
        const errors = (resData.userErrors || []).filter(Boolean);
        if (errors.length > 0) {
          setError("phone", {
            type: "manual",
            message: "Invalid mobile number",
          });
        } else {
          onSubmitSuccess(resData.phone);
        }
      },
    }
  );

  const { company } = config;
  const validatePhone = (value) => {
    return isPhone(value.trim(), config.phonePattern);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box textAlign="center">
        <TextField
          autoFocus
          className={classes.phone}
          data-test="phone__number"
          error={!!errors.phone}
          defaultValue={phoneNumber?.replace(
            new RegExp(`^\\+${config.countryCallingCode}`),
            ""
          )}
          helperText={
            errors.phone &&
            `Must be a valid ${company.countryCode} mobile number`
          }
          label="Phone Number"
          name="phone"
          inputRef={register({ required: true, validate: validatePhone })}
          variant="outlined"
          InputProps={{
            inputComponent: TextMaskCustom,
          }}
        />
        <Box mt={5}>
          <NextButton data-test="phone__send-text" disabled={isSubmitting} type="submit">
            Send Text
          </NextButton>
        </Box>
        {onCancel && (
          <Box mt={3}>
            <Button data-test="phone__cancel" color="primary" onClick={onCancel}>
              Cancel Update
            </Button>
          </Box>
        )}
      </Box>
    </form>
  );
};

PhoneForm.propTypes = {
  userId: PropTypes.string,
  onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onSubmitSuccess: PropTypes.func,
  phoneNumber: PropTypes.string,
};

export default PhoneForm;
