import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";
import PaymentWizard from "../components/payment-wizard";
import { useStateMachine } from "little-state-machine";
import { setUser } from "../actions/user";
import { useSnackbar } from "notistack";

const Payment = ({ card }) => {
  const { action, state } = useStateMachine(setUser);
  const { enqueueSnackbar } = useSnackbar();

  const user = state.user;

  const onSubmitSuccess = (card) => {
    action({ cards: [card, ...state.user.cards] });
    enqueueSnackbar("Payment info successfully updated!", {
      variant: "success",
    });
  };

  return (
    <PaymentWizard
      card={card}
      onSubmitSuccess={onSubmitSuccess}
      userId={user.id}
    />
  );
};

Payment.propTypes = {
  card: PropTypes.object,
};

export default Payment;
