import React from 'react'
import {Box, Button, Container, Typography} from '@material-ui/core'
import {PlayCircleOutline} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {Link as RouterLink} from 'react-router-dom'
import {PATHS} from 'onboarding'
import VideoModal from "components/video-modal"
import parameterize from "parameterize"

const BounceHero = () => {
  const classes = useStyles()
  const hash = window.location.hash.replace("#", "")
  const hashParam = parameterize(hash)
  const videoDeepLinkHash = parameterize("hero-video")
  const [open, setOpen] = React.useState(hashParam === videoDeepLinkHash)
  const playVideo = () => {
    setOpen(true)
  }
  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box pt={6}>
          <Typography
            className={classes.h1}
            variant="h1"
          >
            Earthquake Insurance. Simplified.
          </Typography>
          <Typography
            className={clsx('mtxl', classes.h2)}
            component="p"
            variant="h5"
          >
            Up to $50K right-away after a big earthquake with no deductible.
          </Typography>

          <Box mt={6} mb={2}>
            <Button
              className={classes.cta}
              color="secondary"
              component={RouterLink}
              to={ { pathname: `/${PATHS.BASE}`, state: { source: "Main Landing - Top of Page" } } }
              size="large"
              variant="contained"
            >
              Get Instant Quote
            </Button>
          </Box>
          <Button
            color="secondary"
            onClick={playVideo}
            startIcon={<PlayCircleOutline/>}
          >
            Watch our video
          </Button>
        </Box>
      </Container>
      <VideoModal
        open={open}
        closeHandler={closeHandler}
        videoDeepLinkHash={videoDeepLinkHash}
        videoSrc="https://www.youtube.com/embed/PN-hAhYKSDo?autoplay=1&playlist=PN-hAhYKSDo&loop=1"
      />
    </Box>
  )
}
// could create a ref in the header and dynamically get the height. Actual header height is ~95px.
const headerHeight = 100
const useStyles = makeStyles((theme) => ({
  cta: {
    fontSize: '1.1rem',
    padding: '13px 40px'
  },
  h1: {
    color: theme.palette.primary.contrastText,
    '@media (min-width: 1280px)': {
      fontSize: '5.3556rem',
    },
  },
  h2: {
    color: theme.palette.primary.contrastText,
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: -headerHeight,
    paddingTop: theme.spacing(3) + headerHeight,
    paddingBottom: theme.spacing(6),
  }
}))

export default BounceHero
