import { getProductKey } from "lib/hash-code";
import { getPreferredProductPrice } from "lib/product-prices";

export const isOnboarding = (state) => !!state.onboarding?.quoteZip;

export const setOnboarding = (state, payload) => ({
  ...state,
  onboarding: {
    ...state.onboarding,
    ...payload,
  },
});

export const setCheckoutPrice = (state, payload) => {
  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      checkoutPrice: {
        ...payload,
        id: getProductKey(payload),
      },
    },
  };
};

export const setSetupIntent = (state, intent) => {
  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      setupIntent: intent,
    },
  };
};

export const clearSetupIntent = (state) => {
  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      setupIntent: null,
    },
  };
};

export const setTransactionFees = (state, fees) => {
  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      transactionFees: {
        monthly: fees.FeeMonthly,
        yearly: fees.FeeYear,
      },
    },
  };
};

export const clearTransactionFees = (state) => {
  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      transactionFees: null,
    },
  };
};

export const setPriceList = (state, payload) => {
  const { data, config, addressType, coverageLimit } = payload;
  const productPrices = data
    .filter((x) => Boolean(x.coverageLimit))
    .map((x) => ({
      ...x,
      // id is a hash of the products type, productId and coverage limit
      id: getProductKey(x),
    }));

  const productPrice = getPreferredProductPrice(
    config.pricing,
    productPrices,
    addressType,
    coverageLimit
  );

  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      productPrices,
      monthlyTotal: productPrice.monthlyTotal,
      productId: productPrice.productId,
      checkoutPrice: {
        ...productPrice,
        id: getProductKey(productPrice),
      },
    },
  };
};

export const setRenewalOnboarding = (state) => {
  if (!state.quote || !state.session) {
    return state;
  }

  const { quote, session } = state;
  const address = {
    address1: quote.addr1,
    address2: quote.addr2,
    addressType: session.addressType,
    city: quote.city,
    id: session.id,
    provinceCode: quote.state,
    zip: quote.zip,
  };

  return {
    ...state,
    user: {
      ...state.user,
      id: session.id,
      email: quote.email,
      firstName: quote.first_name,
      lastName: quote.last_name,
    },
    onboarding: {
      ...state.onboarding,
      address,
      email: quote.email,
      firstName: quote.first_name,
      lastName: quote.last_name,
      phone: quote.phone,
      phoneVerified: session.phoneVerified,
      quoteZip: quote.zip,
      userId: session.id,
    },
  };
};
