import { hot } from "react-hot-loader/root";
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { createStore, StateMachineProvider } from "little-state-machine";
import { DevTool } from "little-state-machine-devtools";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import { SnackbarProvider } from "notistack";

import ErrorHandler from "./error-handler";
import ConfigContext from "./config-context";
import AppConfigProvider from "./app-config-provider";
import Home from "./pages/home";
import OnboardingApp, { PATHS } from "./onboarding";
import LoginApp from "./login/login-app";
import DefaultLayout, { NullLayout } from "./layouts";
import AccountApp from "./account/account-app";
import TermsOfUse from "./pages/terms-of-use";
import PrivacyPolicy from "./pages/privacy-policy";
import FAQ from "./pages/faq";
import Complaints from "./pages/complaints";
import Chat from "./pages/chat";
import ScrollToTop from "./components/scroll-to-top";
import NoMatch from "./pages/no-match";
import { show as showIntercom } from "./lib/intercom";
import { log } from "./lib/log";
import { RenewalApp } from "./renewal";

// https://daviseford.com/blog/2019/09/26/react-hooks-async-stripe-provider.html
const AsyncStripeProvider = (props) => {
  const { apiKey, children } = props;
  const [stripe, setStripe] = useState(null);
  const isMounted = useRef(false);
  const unmountFn = () => {
    isMounted.current = false;
  };

  useEffect(() => {
    isMounted.current = true;

    // If we already have stripe loaded, we don't need to do anything
    if (stripe) return unmountFn;

    // Check if Stripe already exists in the window (probably from a previous mount)
    if (window.Stripe) {
      setStripe(window.Stripe(apiKey));
      return unmountFn;
    }

    // Tell our component what to do once the script has loaded
    document.querySelector("#stripe-js")?.addEventListener("load", () => {
      // Create Stripe instance once Stripe.js loads
      if (isMounted) setStripe(window.Stripe(apiKey));
    });

    return unmountFn;
  }, [stripe, apiKey]);

  return (
    <StripeProvider stripe={stripe}>
      <>{children}</>
    </StripeProvider>
  );
};

AsyncStripeProvider.propTypes = {
  apiKey: PropTypes.string,
  children: PropTypes.node,
};

export const defaultOnboardingState = {
  address: null,
  ackCoverage: false,
  ackDisclosures: false,
  ackElectronicSignature: false,
  ackInsurerNotice: false,
  ackLosses: false,
  ackSignature: false,
  ackTerms: false,
  card: null,
  checkoutPrice: null,
  couponCode: null,
  coverageLimit: null,
  email: null,
  firstName: null,
  lastName: null,
  monthlyTotal: null,
  name: null,
  phone: null,
  phoneVerified: false,
  productId: null,
  productPrices: null,
  quoteZip: null,
  signature: null,
  subscriptionInterval: window.configContext?.defaultSubscriptionInterval,
  userId: window.user?.id,
};

createStore(
  {
    onboarding: defaultOnboardingState,
    systemConfiguration: {
      policySignUpEnabled: true,
    },
    user: window.user,
  },
  {
    middleWares: [log],
  }
);

const App = (props) => {
  const [configContext, setConfigContext] = useState(
    window.configContext ?? {
      postalCodePattern: /^(\d{5})-?(\d{4})?$/,
      enableSamplePolicy: true,
      errorStatusCode: null,
      errorWithStatusCode: null,
      complaints: false,
      supportPhone: "510-891-1753",
      supportEmail: "support@jumpstartinsurance.com",
      productName: "Jumpstart",
      latLngBounds: {
        south: 32.4934406432,
        west: -124.7508900869,
        north: 49.0182863409,
        east: -114.0664982185,
      },
      redirectFaq: true,
      marketingHost: "www.jumpstartinsurance.com/",
      tenantSlug: "jumpstart",
      blogUrl: "https://blog.jumpstartinsurance.com",
      fairInsuranceCode: null,
      disclaimerText: null,
      licenseTextInline: false,
      licenseText:
        "Licensed Surplus Line Insurance Broker CA #0K67793, OR #3000850129, WA #1053385",
      brokersNav: false,
      phoneFormat: "(NNN) NNN-NNNN",
      postalCodeName: "Zip Code",
      phoneMask: [
        "(",
        "/[0-9]/",
        "/[0-9]/",
        "/[0-9]/",
        ")",
        " ",
        "/[0-9]/",
        "/[0-9]/",
        "/[0-9]/",
        "-",
        "/[0-9]/",
        "/[0-9]/",
        "/[0-9]/",
        "/[0-9]/",
      ],
      phonePattern: /^(\([0-9]{3}\))\s[0-9]{3}-[0-9]{4}$/,
      countryCallingCode: "+1",
      company: {
        address1: "344 Thomas L Berkley Way",
        city: "Oakland",
        countryCode: "US",
        localities: "Oakland, CA",
        name: "Jumpstart Insurance Solutions, Inc.",
        state: "CA",
        zip: "94612",
      },
      social: {
        facebook: "yourjumpstart",
        linkedIn: "jumpstart-insurance/",
      },
      seo: {
        default: null,
      },
      priceSearchText: "Searching the U.S. Geological Survey Database",
      pricing: {
        RESIDENTIAL: "min",
        residential: "min",
        business: "min",
        BUSINESS: "min",
        commercial: "max",
      },
    }
  );

  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  return (
    <ConfigContext.Provider value={configContext}>
      <AsyncStripeProvider apiKey={STRIPE_PUBLIC_KEY}>
        <StateMachineProvider>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            {process.env.NODE_ENV !== "production" && <DevTool />}
            <AppConfigProvider {...props}>
              <Router basename={process.env.PUBLIC_URL}>
                <ScrollToTop />
                <AppRoutes />
              </Router>
            </AppConfigProvider>
          </SnackbarProvider>
        </StateMachineProvider>
      </AsyncStripeProvider>
    </ConfigContext.Provider>
  );
};

function usePageViewTracking() {
  const location = useLocation();
  React.useEffect(() => {
    window.fbq && window.fbq("track", "PageView");
    window.gtag &&
      window.gtag("event", "page_view", { page_path: location.pathname });
  }, [location]);
}

function AppRoutes() {
  const history = useHistory();
  const location = useLocation();
  const config = useContext(ConfigContext);

  usePageViewTracking();

  useEffect(() => {
    if (config.errorStatusCode) {
      history.replace(history.location.pathname, {
        errorStatusCode: config.errorStatusCode,
      });
    }
  }, [config.errorStatusCode]);

  useEffect(() => {
    if (location.state?.chat) {
      showIntercom();
    }
  }, [location.state?.chat]);

  return (
    <ErrorHandler>
      <Switch>
        <RouteWrapper
          path={`/renewal`}
          component={RenewalApp}
          layout={NullLayout}
        />
        <RouteWrapper
          path={`/${PATHS.BASE}`}
          component={OnboardingApp}
          layout={NullLayout}
        />
        <Route path={["/login", "/"]}>
          <Switch>
            <RouteWrapper path="/" component={Home} layout={NullLayout} exact />
            <RouteWrapper
              path="/account"
              component={AccountApp}
              layout={DefaultLayout}
            />
            <RouteWrapper
              path="/chat"
              component={Chat}
              layout={DefaultLayout}
              exact
            />
            {config.complaints && (
              <RouteWrapper
                path="/complaints"
                component={Complaints}
                layout={DefaultLayout}
                exact
              />
            )}
            <RouteWrapper
              path="/faq"
              component={FAQ}
              layout={DefaultLayout}
              exact
            />
            <RouteWrapper
              path="/login"
              component={LoginApp}
              layout={DefaultLayout}
            />
            <RouteWrapper
              path="/privacy-policy"
              component={PrivacyPolicy}
              layout={DefaultLayout}
              exact
            />
            <RouteWrapper
              path="/terms-of-use"
              component={TermsOfUse}
              layout={DefaultLayout}
              exact
            />
            <RouteWrapper path="*" component={NoMatch} layout={DefaultLayout} />
          </Switch>
        </Route>
      </Switch>
    </ErrorHandler>
  );
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.func,
  layout: PropTypes.func,
};

export default hot(App);
