import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { last } from "lodash";
import { useForm } from "react-hook-form";
import { Box, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import titleize from "titleize";

import { isEmail, isZip } from "../../lib/form-validation";
import { NextButton } from "../../components/buttons";
import ConfigContext from "../../config-context";

const MailingListForm = ({ onSubmitSuccess, zip, loading, onSubmit: _onSubmit, error }) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    isSubmitting,
  } = useForm();

  const onSubmit = (data) => {
    _onSubmit(data.email);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={3}>
        <Typography align="center" component="h2" variant="h6" data-test="mailing-list__title">
          Be the first to know when it&apos;s available in your area!
        </Typography>
      </Box>
      <Box textAlign="center">
        <TextField
          className={classes.email}
          data-test="mailing-list__email"
          error={!!errors.email}
          helperText={!!errors.email && "Please enter a valid email"}
          inputRef={register({ required: true, validate: isEmail })}
          label="Email"
          name="email"
          variant="outlined"
        />
      </Box>
      {!zip && (
        <Box textAlign="center">
          <TextField
            className={classes.zip}
            data-test="mailing-list__text"
            error={!!errors.zip}
            defaultValue={zip}
            helperText={
              errors.zip && `Must be a valid ${config.postalCodeName}`
            }
            label={titleize(config.postalCodeName)}
            name="zip"
            inputRef={register({
              required: true,
              validate: (value) => isZip(value, config.postalCodePattern),
            })}
            variant="outlined"
          />
        </Box>
      )}
      <Box mt={2} textAlign="center">
        <NextButton
          data-test="mailing-list__button"
          color="primary"
          disabled={isSubmitting || loading}
          size="large"
          type="submit"
          variant="contained"
        >
          Notify Me
        </NextButton>
      </Box>
      <Box className={classes.errorContainer}>
        {error && <Typography color="error" data-test="mailing-list__error">{error}</Typography>}
      </Box>
    </form>
  );
};

MailingListForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  zip: PropTypes.string,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  email: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: 402,
    },
  },
  zip: {
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: 402,
    },
  },
  errorContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default MailingListForm;
