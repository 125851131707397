import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@material-ui/core";

import { formatPhone } from "../lib/format";
import ConfigContext from "../config-context";

const VerifiedPhone = ({ phone, onUpdate }) => {
  const config = useContext(ConfigContext);
  const formattedPhone = formatPhone(
    phone,
    config.phoneFormat,
    config.countryCallingCode
  );

  return (
    <Box>
      <Typography component="p" variant="subtitle1" paragraph={true}>
        {formattedPhone}
      </Typography>
      <Button color="primary" onClick={onUpdate}>
        Update
      </Button>
    </Box>
  );
};

VerifiedPhone.propTypes = {
  phone: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default VerifiedPhone;
