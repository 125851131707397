import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const Step = ({ children, title }) => {
  const history = useHistory();

  return (
    <Box mb={3}>
      <Box mb={5}>
        <Grid container>
          <Grid item xs={2}>
            <Box>
              <IconButton
                aria-label="Go Back"
                color="inherit"
                onClick={() => history.goBack()}
              >
                <ArrowBack />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box pt={1}>
              <Typography align="center" variant="h4" component="h1">
                {title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Box>
      {children}
    </Box>
  );
};

Step.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Step;
