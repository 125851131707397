import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { ReactComponent as SignUpCitySVG } from "../images/signup-city.svg";
import { ReactComponent as SignUpBannerZipSVG } from "../images/signup-banner-zip.svg";

const ZipIcon = ({ zip }) => {
  const classes = useStyles();

  return (
    <div className={classes.cityZip}>
      <Box className={classes.city} maxWidth="sm">
        <SignUpCitySVG className={classes.citySvg} />
      </Box>
      <Box display="flex" justifyContent="center">
        <Box className={classes.banner}>
          <SignUpBannerZipSVG className={classes.bannerSvg} />
        </Box>
        <p className={classes.zip}>{zip}</p>
      </Box>
    </div>
  );
};

ZipIcon.propTypes = {
  zip: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  cityZip: {
    position: "relative",
  },
  zip: {
    color: theme.palette.primary.main,
    position: "absolute",
    bottom: "12px",
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    width: "100%",
  },
  city: {
    display: "block",
    margin: "0 auto",
    width: "140px",
  },
  citySvg: {
    "& path": {
      stroke: theme.palette.primary.borderDark,
    },
  },
  banner: {
    display: "block",
    margin: "-10px auto 0",
  },
  bannerSvg: {
    "& rect": {
      stroke: theme.palette.primary.borderDark,
    },
  },
}));

export default ZipIcon;
