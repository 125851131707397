import React from 'react'
import {makeStyles} from "@material-ui/core/styles"
import {FormControlLabel} from "@material-ui/core"


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "flex-start"
  },
  label: {
    marginTop: 9,
  }
}))

const CheckboxFormControlLabel = (props) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      {...props}
      className={classes.topAlignedCheckboxLabel}
      classes={{...classes}}
    />
  )
}

export default CheckboxFormControlLabel
