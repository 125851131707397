import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { Box } from "@material-ui/core";
import qs from "query-string";
import Slide from "onboarding/components/slide";
import { setOnboarding } from "actions/onboarding";
import Spinner from "components/spinner";
import { PATHS } from "../onboarding-app";
import { getPreferredProductPrice } from "lib/product-prices";
import ConfigContext from "../../config-context";
import { Redirect } from "react-router-dom";

const QuoteDeeplink = (props) => {
  const config = useContext(ConfigContext);
  const [unavailable, setUnavailable] = useState(false);
  const { action, state } = useStateMachine(setOnboarding);

  const { location } = props;
  const queryParams = qs.parse(location.search) || {};
  const { zip } = queryParams;

  useEffect(() => {
    // getConsumerPrice({ variables: { zip } });
  }, [zip]);

  let loading = false;

  if (
    !state.onboarding.quoteZip ||
    !state.onboarding.productPrices ||
    !state.onboarding.productId
  ) {
    loading = true;
  }

  if (unavailable) {
    return <Redirect to={`/${PATHS.BASE}`} />;
  }

  if (loading) {
    return (
      <Slide title={`We’re looking up your price now`}>
        <Box>
          <Spinner />
        </Box>
      </Slide>
    );
  }

  return <Redirect to={`/${PATHS.BASE}/${PATHS.PRICE_RESULT}`} />;
};

QuoteDeeplink.propTypes = {
  location: PropTypes.object,
};

export default QuoteDeeplink;
