import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import ScrollableCard from "components/scrollable-card";
import { useStateMachine } from "little-state-machine";
import { camelCase } from "lodash";
import SamplePolicyLink from "components/sample-policy-link";
import CheckboxFormControlLabel from "components/checkbox-form-control-label";
import ConfigContext from "../../../config-context";
import PriceLabel from "components/price-label";
import { numberToCurrency } from "lib/money";

const Disclosure = ({ title, children, form, samplePolicy }) => {
  const { state } = useStateMachine();
  const ackFieldName = camelCase(title);

  return (
    <Box mt={3}>
      <Typography data-test={title + "__title"} component="h2" variant="h5" paragraph>
        {title}
      </Typography>
      <ScrollableCard>{children}</ScrollableCard>
      {samplePolicy && (
        <Box mt={1}>
          <SamplePolicyLink
            params={{
              coverage_limit: state.onboarding.checkoutPrice.coverageLimit,
              province_code: state.onboarding.address.provinceCode,
            }}
          />
        </Box>
      )}
      {form && (
        <FormControl
          required
          error={!!form.errors[ackFieldName]}
          component="fieldset"
        >
          <CheckboxFormControlLabel
            control={
              <Checkbox
                inputRef={form.register({ required: true })}
                name={ackFieldName}
                data-test="disclosure__confirm"
                color="primary"
              />
            }
            label="OK, got it."
          />
          {!!form.errors[ackFieldName] && (
            <FormHelperText data-test="disclosure__ack-error">You must acknowledge!</FormHelperText>
          )}
        </FormControl>
      )}
    </Box>
  );
};

Disclosure.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  form: PropTypes.object,
  samplePolicy: PropTypes.bool,
};

// Payment disclosure
const JumpstartPaymentDisclosure = ({ form }) => {
  const config = useContext(ConfigContext);
  const { state } = useStateMachine();

  const isBusiness = state.onboarding.address.addressType === "BUSINESS";
  const checkoutPrice = state.onboarding.checkoutPrice;
  const transactionFee =
    state.onboarding.subscriptionInterval === "MONTH"
      ? state.onboarding.transactionFees.monthly
      : state.onboarding.transactionFees.yearly;

  return (
    <Disclosure title="Disclosures" form={form}>
      <ul>
        <li>
          <p data-test="disclosures__text">
            The following will be billed for the term of this policy to the
            credit or debit card account that I have provided unless the policy
            is cancelled:
          </p>
          <Typography variant="body2" paragraph>
            <PriceLabel
              checkoutPrice={checkoutPrice}
              coupon={state.onboarding.couponCode}
              interval={state.onboarding.subscriptionInterval.toLowerCase()}
              transactionFee={transactionFee}
              showDiscountTerms
            />
          </Typography>
        </li>
        <li>
          If payment is not received by the due date, {config.productName} may
          cancel my policy 10 days thereafter, in accordance with insurance
          regulations that govern cancellation.
        </li>
        <li>
          Providing false or misrepresentative information is fraud and is
          punishable by law, and I certify that the information I provided in
          the application is true and correct to the best of my knowledge and
          belief.
        </li>
        <li>The insured person is 18 years or older.</li>
        <li>
          I agree to receive notifications electronically at the email address I
          have provided with this application.
        </li>
        <li data-test="disclosures__biz-res">
          {isBusiness
            ? "The business named above owns this property or operates at this address."
            : "This is a residence that I own or rent."}
        </li>
      </ul>
    </Disclosure>
  );
};

JumpstartPaymentDisclosure.propTypes = {
  form: PropTypes.object,
};

// Summary of Coverage
const JumpstartSummaryOfCoverageDisclosure = () => {
  const { state } = useStateMachine();
  const { checkoutPrice, address } = state.onboarding;
  const config = useContext(ConfigContext);

  return (
    <Disclosure
      title="Summary of Policy Coverage"
      samplePolicy={config.enableSamplePolicy}
    >
      <ul>
        <li>
          <span data-test="summary__limit">
            Your eligibility to claim the{" "}
            {numberToCurrency(checkoutPrice.coverageLimit, {
              truncateZeroCents: true,
            })}{" "}
            limit is based on
          </span>
          <ul>
            <li>
              shaking intensity at your location, specifically, a peak ground
              velocity of {address.provinceCode === "CA" ? "30" : "20"}{" "}
              centimeters per second or more, which corresponds roughly to the
              area of &quot;severe shaking&quot; on a US Geological Survey
              Shakemap; and
            </li>
            <li>
              a response from you, confirming that you are experiencing extra
              expenses or loss due to the earthquake.
            </li>
          </ul>
        </li>
        <li>
          If you receive a payment from the main shock, you are not eligible to
          receive another payment in any aftershock even if the aftershock also
          meets the shaking intensity criteria.
        </li>
        <li>
          You can never receive more than two payments during the 12-month term
          of your policy.
        </li>
        <li>
          Earthquake data used to determine eligibility are as reported by the
          US Geological Survey 24 hours after occurrence.
        </li>
        <li>
          This insurance is effected with certain Underwriters at Lloyd&apos;s,
          London.
        </li>
      </ul>
    </Disclosure>
  );
};

// Jumpstart Disclosures
export const JumpstartDisclosures = ({ form }) => {
  return (
    <>
      <JumpstartPaymentDisclosure form={form} />
      <JumpstartSummaryOfCoverageDisclosure />
    </>
  );
};

JumpstartDisclosures.propTypes = {
  form: PropTypes.object,
};
