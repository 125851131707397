import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";

const VideoModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const videoDeepLinkHash = props.videoDeepLinkHash;

  const onRendered = () => {
    history.push(`#${videoDeepLinkHash}`);
  };

  const onClose = () => {
    history.push("");
    props.closeHandler();
  };

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      keepMounted={false}
      onRendered={onRendered}
      onClose={onClose}
      disableEscapeKeyDown={false}
      disableBackdropClick={false}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <iframe
        width="560"
        height="315"
        src={props.videoSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  );
};

VideoModal.propTypes = {
  closeHandler: PropTypes.func,
  videoSrc: PropTypes.string,
  open: PropTypes.bool,
  videoDeepLinkHash: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default VideoModal;
