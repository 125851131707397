import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ConfigContext from "../config-context";
import { PATHS } from "../onboarding";

const SeoMetadata = ({ location }) => {
  const config = useContext(ConfigContext);

  let pathname = location.pathname;
  if (pathname.match(new RegExp(`^/${PATHS.BASE}`))) {
    pathname = `/${PATHS.BASE}`;
  }

  const meta = { ...config?.seo?.default, ...config?.seo?.[pathname] };
  const { description, keywords = [], title } = meta;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(",")} />
      {meta.relCanonicalPathname && (
        <link
          rel="canonical"
          href={`${window.location.protocol}//${window.location.host}${meta.relCanonicalPathname}`}
        />
      )}
    </Helmet>
  );
};

SeoMetadata.propTypes = {
  location: PropTypes.object,
};

export default SeoMetadata;
