import { maxBy, minBy } from "lodash";

export const getPreferredProductPrice = (
  pricingConfig,
  productPrices,
  addressType = "RESIDENTIAL",
  coverageLimit
) => {
  const aggregators = {
    max: maxBy,
    min: minBy,
  };

  const aggregateType = pricingConfig[addressType.toLowerCase()];
  const aggregator = aggregators[aggregateType];

  const matchingProductPrices = productPrices.filter(
    (p) =>
      p.productType === addressType &&
      ((coverageLimit && p.coverageLimit === coverageLimit) || !coverageLimit)
  );

  return aggregator(matchingProductPrices, "monthlyTotal");
};
