import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { ArrowBack, Menu } from "@material-ui/icons";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import BounceLogo from "../../images/bounce-logo-primary.png";
import JumpstartLogo from "../../images/jumpstart-logo-primary.png";
import ConfigContext from "../../config-context";
import HomeLink from "../../components/home-link";
import FaqLink from "../../components/faq-link";

const logos = {
  bounce: BounceLogo,
  jumpstart: JumpstartLogo,
};

const colors = {
  bounce: "primary",
  jumpstart: "inherit",
};

const OnboardingNav = ({ prevPath }) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const history = useHistory();
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button key="faq" component={FaqLink} to="/faq">
          <ListItemText primary="FAQ" />
        </ListItem>
        {config.blogUrl && (
          <ListItem
            button
            key="blog"
            component="a"
            href={config.blogUrl}
            target="_blank"
          >
            <ListItemText primary="Blog" />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button key="login" component={RouterLink} to="/login">
          <ListItemText primary="Log In" />
        </ListItem>
      </List>
    </div>
  );

  const logo = logos["jumpstart"];

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" elevation={0}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              {prevPath && prevPath !== "/" && (
                <IconButton
                  aria-label="start over"
                  className={classes.menuButton}
                  color={colors[config.tenantSlug]}
                  edge="start"
                  onClick={() => history.push(prevPath)}
                >
                  <ArrowBack />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                className={classes.logoContainer}
                justifyContent="center"
              >
                <HomeLink underline="none">
                  <img
                    className={classes.title}
                    src={logo}
                    alt={config.productName}
                  />
                </HomeLink>
              </Box>
            </Grid>
            <Grid container justify="flex-end" item xs={4}>
              <IconButton
                aria-label="menu"
                edge="end"
                className={classes.menuButton}
                color={colors[config.tenantSlug]}
                onClick={toggleDrawer(true)}
              >
                <Menu />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

OnboardingNav.propTypes = {
  prevPath: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ffffff",
  },
  title: {
    color: "#333333",
    display: "block",
    textAlign: "center",
    margin: "0 auto",
    width: theme.spacing(22),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(17),
    },
  },
  list: {
    width: 250,
  },
}));

export default OnboardingNav;
