import React from "react";
import PropTypes from "prop-types";
import EndorsementForm from "components/endorsement-form";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import EditableLabel from "components/editable-label";
import AddressSummary from "components/address-summary";
import { capitalize } from "lodash";
import { numberToCurrency } from "lib/money";
import PriceLabel from "components/price-label";
import { makeStyles } from "@material-ui/core/styles";
import Step from "../components/step";

const useStyles = makeStyles((theme) => ({
  editLabel: {
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
}));

const Checkout = ({
  address,
  handleCoverageChange,
  onSubmitSuccess,
  policy,
  productPrice,
  productPrices,
  user,
  checkoutPrice,
}) => {
  const classes = useStyles();

  const subscription = policy.subscriptions[0];

  return (
    <Step title="Review and sign your policy changes">
      <Box mb={2}>
        <EditableLabel
          className={classes.editLabel}
          labelText="Price"
          showDivider={false}
        >
          <PriceLabel
            checkoutPrice={checkoutPrice}
            coupon={checkoutPrice.coupon?.code}
            interval={subscription.interval}
            showDiscountTerms
          />
        </EditableLabel>
      </Box>
      <Box mb={2}>
        <EditableLabel
          changeTo={`/account/coverage/${policy.number}/change-address/1`}
          className={classes.editLabel}
          labelText="Address"
          showDivider={false}
        >
          <AddressSummary address={address} variant="subtitle2" />
        </EditableLabel>
      </Box>
      <Box mb={3}>
        <EditableLabel
          changeTo={`/account/coverage/${policy.number}/change-address/2`}
          className={classes.editLabel}
          labelText="Type"
          showDivider={false}
        >
          {capitalize(address.addressType)} coverage
        </EditableLabel>
      </Box>
      <Box mb={4}>
        <EditableLabel
          className={classes.editLabel}
          labelText="Amount"
          showDivider={false}
        >
          {productPrices.length > 1 && (
            <FormControl variant="outlined">
              <InputLabel>Coverage</InputLabel>
              <Select
                value={productPrice.productId}
                onChange={handleCoverageChange}
                label="Coverage"
              >
                {productPrices.map((p) => (
                  <MenuItem key={p.productId} value={p.productId}>
                    {numberToCurrency(p.coverageLimit)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {productPrices.length === 1 && (
            <>
              {numberToCurrency(productPrice.coverageLimit, {
                truncateZeroCents: true,
              })}{" "}
              of coverage
            </>
          )}
        </EditableLabel>
      </Box>
      <EndorsementForm
        address={address}
        onSubmitSuccess={onSubmitSuccess}
        policy={policy}
        productPrice={productPrice}
        user={user}
      />
    </Step>
  );
};

Checkout.propTypes = {
  address: PropTypes.object,
  handleCoverageChange: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  policy: PropTypes.object,
  productPrice: PropTypes.object,
  productPrices: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  checkoutPrice: PropTypes.object,
};

export default Checkout;
