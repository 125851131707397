import React, { useContext } from "react";
import { Box, Typography, Divider, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "config-context";
import HomeLink from "components/home-link";

const BounceTermsOfUse = () => {
  const config = useContext(ConfigContext);

  return (
    <div>
      <Typography variant="h5" component="p">
        (Last updated 19 February 2021)
      </Typography>
      <Box my={5}>
        <Divider />
      </Box>
      <Box align="left">
        <Typography paragraph>
          Welcome to Bounce Insurance Limited (“Bounce”).
        </Typography>
        <Typography paragraph>
          By accessing the Bounce website or using any of our services you agree
          that the following terms of use (“Terms”), including our Privacy
          Policy (which may be found at{" "}
          <Link component={RouterLink} to="/privacy-policy" target="_blank">
            {config.host}/privacy-policy
          </Link>
          ), will apply to your use of our website and our services. In these
          Terms, “you” and “your” refer to anyone accessing our website and
          users of the Services. If you access our website or the Services on
          behalf of a company or other legal entity, you represent and warrant
          that you have the authority to bind that legal entity and, in such
          event, “you” and “your” will refer to that legal entity. “We”, “us”,
          or “our” refer to Bounce.
        </Typography>
        <Typography>
          If you have any questions, concerns or comments about our Terms of
          Use, please contact us by either:
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            Emailing us on{" "}
            <Link href={`mailto:${config.supportEmail}`} target="_blank">
              {config.supportEmail}
            </Link>
            ;
          </li>
          <li>
            Visiting us online at{" "}
            <HomeLink target="_blank">{config.marketingHost}</HomeLink>; or
          </li>
          <li>
            Calling us on{" "}
            <Link href={`tel:${config.supportPhone}`}>
              {config.supportPhone}
            </Link>
          </li>
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          1. Acceptance of Terms
        </Typography>
        <Typography paragraph>
          Welcome to <HomeLink target="_blank">{config.marketingHost}</HomeLink>
          , owned and operated by Bounce Insurance Limited (“Bounce”), a New
          Zealand Financial Service Provider (FSP749731). We operate this
          website to enable submission of applications for insurance through
          Bounce’s direct, online, and mobile application services (the
          “Services”). Note that the Services do not include any insurance
          policy which may be issued to you; any insurance policy will be
          subject to its own terms.
        </Typography>
        <Typography paragraph>
          The insurance product offered through Bounce may not be available to
          all persons in New Zealand. Certain restrictions, conditions, and
          eligibility requirements apply. The information contained in any
          Bounce website, mobile application, or other technology is not
          intended to be an offer to sell or a solicitation in connection with
          any product or service in any jurisdiction where such an offer or
          solicitation would be unlawful or in which we, our insurance
          carrier(s), any managing general underwriters or other service
          provider, are not qualified to do so.
        </Typography>
        <Typography paragraph>
          The content of our website is for your information only and should not
          be construed as financial advice. The posting of policy information is
          not a recommendation or opinion for you to buy or sell any insurance
          product. You should consider the appropriateness of any insurance
          policy with regard to your objectives, financial situation and needs.
          You need to decide if the limits, type and level of cover are
          appropriate for You and will cover any potential losses. We encourage
          you to seek advice before acting on any information contained on our
          website or purchasing an insurance policy through us.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          2. Modification
        </Typography>
        <Typography paragraph>
          Bounce reserves the right, at its sole discretion, to modify or
          discontinue, temporarily or permanently, the Services or to modify
          these Terms at any time and without prior notice. If we modify these
          Terms, we will post the modification on our website or via our
          application. We will also update the “Last Updated Date” at the top of
          these Terms. Modifications to these Terms will automatically take
          effect upon posting; provided, however, that material changes to the
          Terms will be effective as to an existing User thirty (30) days after
          posting. By continuing to access or use the Services after we have
          posted a modification, you are indicating that you agree to be bound
          by the modified Terms. If the modified Terms are not acceptable to
          you, your only recourse is to stop using the Services.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          3. License to Services and Content
        </Typography>
        <Typography paragraph>
          Subject to your compliance with these Terms, Bounce grants you a
          limited, non-exclusive, revocable, non-transferable right to access
          and view any Bounce content solely for your personal use or personal
          commercial use as expressly provided in these Terms. You have no right
          to sublicense the rights granted in this section.
        </Typography>
        <Typography paragraph>
          You will not use, copy, adapt, modify, prepare derivative works based
          upon, distribute, license, collect, aggregate, sell, transfer,
          publicly display, publicly perform, transmit, broadcast or otherwise
          exploit the Services or content, except as expressly permitted in
          these Terms. No licenses or rights are granted to you by implication
          or otherwise under any intellectual property rights owned or
          controlled by Bounce or its licensors, except for the licenses and
          rights expressly granted in these Terms. No license or rights are
          granted to use data mining, robots, spiders or similar data gathering
          and extraction tools without our prior written permission; provided,
          however, that a limited exception from the foregoing exclusion is
          provided to general purpose internet search engines and non-commercial
          public archives that use tools to gather information for the sole
          purpose of displaying hyperlinks to the Services, provided that they
          each do so from a stable IP address or range of IP addresses using an
          easily identifiable agent and comply with our robots.txt file. Use of
          the Services by a competitor company, a non-consumer third party, or
          for any purpose not expressly intended by Bounce is prohibited.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          4. User Affirmations, Conduct and Use
        </Typography>
        <Typography paragraph>
          You represent, warrant and agree to the following:
        </Typography>
        <Typography paragraph component="ul">
          <li>You are at least 18 years of age.</li>
          <li>
            You are not legally prohibited from receiving or using the website
            or the Services under the laws of the country in which you access or
            use the Service.
          </li>
          <li>
            You will comply with any and all laws, rules, regulations, and tax
            obligations that may apply to your use of our website and the
            Services.
          </li>
          <li>
            Nothing that you post, upload, publish, represent, warrant or
            transmit will infringe, misappropriate or violate a third party’s
            intellectual property rights, or rights of publicity or privacy, or
            result in the violation of any applicable law or regulation.
          </li>
          <li>
            You will not use manual or automated software, devices, scripts,
            robots, other means or processes to access, “scrape”, “crawl” or
            “spider” our website or other services contained in the Services.
          </li>
          <li>
            You will not use or website or the Services for any commercial or
            other purposes that are not expressly permitted by these Terms.
          </li>
          <li>
            You will not copy, store or otherwise access any information
            contained on our website or that is related to the Services for
            purposes not expressly permitted by these Terms.
          </li>
          <li>
            You will not interfere with or damage our website or the Services,
            including, without limitation, through the use of viruses, cancel
            bots, Trojan horses, harmful code, flood pings, denial-of-service
            attacks, packet or IP spoofing, forged routing or electronic mail
            address information or similar methods or technology;
          </li>
          <li>
            All information you submit will be complete and accurate, and you
            will not impersonate any person or entity, or falsify or otherwise
            misrepresent yourself or your affiliation with any person or entity.
          </li>
          <li>
            You will not use, display, mirror or frame the Services, Bounce’s
            name, any Bounce trademark, logo or other proprietary information,
            or the layout and design of any page or form contained on a page,
            without Bounce’s express written consent.
          </li>
          <li>
            You will not access, tamper with, or use non-public areas of any
            website or application, Bounce’s computer systems, or the technical
            delivery systems of the Services or any third-party provider system.
          </li>
          <li>
            You will not attempt to probe, scan, or test the vulnerability of
            any Bounce system or network or breach any security or
            authentication measures.
          </li>
          <li>
            You will not attempt to avoid, bypass, remove, deactivate, impair,
            descramble, or otherwise circumvent any technological measure
            implemented by Bounce or any of Bounce’s providers or any other
            third party to protect the Services.
          </li>
          <li>
            You will not advocate, encourage, or assist any third party in doing
            any of the foregoing.
          </li>
        </Typography>
        <Typography paragraph>
          You acknowledge that Bounce has no obligation to monitor your access
          to or use of the Services or to review or edit any content, but has
          the right to do so for the purpose of operating the Services, to
          ensure your compliance with these Terms, or to comply with applicable
          law or the order or requirement of a court, administrative agency or
          other government or regulatory body.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          5. Ownership
        </Typography>
        <Typography paragraph>
          The Services and content are protected by copyright, trademark, and
          other laws of the New Zealand. You acknowledge and agree that our
          website and the Services, including all associated intellectual
          property rights, are the exclusive property of Bounce and its
          licensors. You will not remove, alter or obscure any copyright,
          trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the Services. You may use content
          obtained from the Services for the permitted purposes set forth in
          these Terms, provided that the following copyright notice appears in
          all copies of any downloaded or printed materials: &quot;©{" "}
          {new Date().getFullYear()} Bounce Insurance Limited. All rights
          reserved.&quot; Any other use is prohibited.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          6. Links
        </Typography>
        <Typography paragraph>
          The Services may contain links to third-party websites or resources.
          You acknowledge and agree that Bounce is not responsible or liable
          for: (i) the availability or accuracy of such websites or resources;
          or (ii) the content, products, or services on or available from such
          websites or resources. Links to such websites or resources do not
          imply any endorsement by Bounce. You acknowledge sole responsibility
          for and assume all risk arising from your use of any such websites or
          resources or the content, products or services on or available from
          such websites or resources.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          7. Advertisements
        </Typography>
        <Typography paragraph>
          Bounce may include advertisements on its own behalf or paid
          advertisements on behalf of interested companies and/or individuals on
          the Services. By clicking on the advertisements, you may be shifted to
          a Website of the advertiser or receive other messages, information, or
          offers from the advertiser. You acknowledge and agree that Bounce is
          not liable for the privacy practices of advertisers or the content of
          their Websites, information, messages or offers. Users are wholly
          liable for all communications with advertisers and for all
          transactions subsequently executed.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          8. Termination
        </Typography>
        <Typography paragraph>
          We may, in our discretion and without liability to you, with or
          without cause, with or without prior notice, and at any time: (a)
          terminate these Terms or your access to our Services, and (b)
          deactivate or cancel any user’s account.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          9. Disclaimers
        </Typography>
        <Typography paragraph>
          To the extent permitted by law: (a) if you choose to use our website
          or the Services, you do so at your sole risk. the services are
          provided “as is” and “as available,” without warranty of any kind,
          either express or implied, (b) Bounce makes no representation or
          warranty that: (i) the operation of the service will be error-free or
          uninterrupted; (ii) any defects will be corrected; (iii) the services,
          communications from us, or any server that makes the services
          available to you are free of viruses or other harmful code or tools;
          (iv) the services will be available to you or that you qualify for the
          products or services offered; (v) the content on the services will be
          accurate as of any particular date; or (vi) the products or services
          offered through the site are available outside of New Zealand or in
          jurisdictions where in which bounce is not properly licensed. To the
          extent you access this website or use our Services for business
          purposes, the Consumer Guarantees Act 1993 will not apply.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          10. Limitation of Liability
        </Typography>
        <Typography paragraph>
          Neither Bounce nor any other party involved in creating, producing or
          delivering the services or content will be liable for any incidental,
          special, exemplary or consequential damages, including lost profits,
          loss of data or loss of goodwill, service interruption, computer
          damage or system failure or the cost of substitute products or
          services, or for any damages for personal or bodily injury or
          emotional distress arising out of or in connection with these Terms,
          from the use of or inability to use our website or the Services or
          content, from any communications, interactions or meetings as a result
          of your use of the services, whether based on warranty, contract, tort
          (including negligence), or otherwise, and whether or not Bounce has
          been informed of the possibility of such damage, even if a limited
          remedy set forth herein is found to have failed of its essential
          purpose.
        </Typography>
        <Typography paragraph>
          In no event will bounce’s aggregate liability arising out of or in
          connection with these terms and your use of our website or the
          Services, whether based on warranty, contract, tort (including
          negligence), or otherwise , exceed one hundred dollars ($100).
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          11. Indemnification
        </Typography>
        <Typography paragraph>
          You agree to release, defend, indemnify, and hold Bounce and its
          affiliates and subsidiaries, and their officers, directors, employees
          and agents, harmless from and against any claims, liabilities,
          damages, losses, and expenses, including, without limitation,
          reasonable legal fees, arising out of or in any way connected with (a)
          your access to or use of the Services, or your violation of these
          Terms; (b) your reliance on the Services; (d) your violation of any
          third party right, including without limitation any copyright,
          property, or privacy right; or (e) any claim that your use of the
          Services caused damage to a third party. Bounce shall have the right
          to control all defence and settlement activities.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          12. Assignment
        </Typography>
        <Typography paragraph>
          You may not assign or transfer these Terms, by operation of law or
          otherwise, without Bounce’s prior written consent. Any attempt by you
          to assign or transfer these Terms, without such consent, will be null
          and of no effect. Bounce may assign or transfer these Terms, at its
          sole discretion, without restriction. Subject to the foregoing, these
          Terms will bind and inure to the benefit of the parties, their
          successors and permitted assigns.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          13. Notices
        </Typography>
        <Typography paragraph>
          Unless otherwise specified herein, any notices or other communications
          permitted or required hereunder, including those regarding
          modifications to these Terms, will be in writing and given by Bounce
          by posting to the website or via the application. For notices or
          communications by Bounce made by e-mail, if any, the date of receipt
          will be deemed the date on which such notice is transmitted.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          14. Controlling Law and Jurisdiction
        </Typography>
        <Typography paragraph>
          These Terms shall be governed by the laws of New Zealand. New Zealand
          courts have exclusive jurisdiction.
        </Typography>
        <Typography paragraph>
          You and bounce agree that any cause of action arising out of or
          related to our website or the Services or content must commence within
          one (1) year after the cause of action accrues. Otherwise, such cause
          of action is permanently barred and waived.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          15. Severability
        </Typography>
        <Typography paragraph>
          These Terms are intended to govern the agreement between Bounce and
          you to the extent permitted by all applicable laws, ordinances, rules,
          and regulations. If any provision of these Terms or the application
          thereof to any person or circumstances shall, for any reason or to any
          extent, be invalid or unenforceable, the remainder of these Terms and
          the application of such provision to other persons or circumstances
          shall not be affected thereby, but rather shall be enforced to the
          greatest extent permitted by law.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          16. Dispute Resolution Provision
        </Typography>
        <Typography paragraph>
          You and Bounce agree that any dispute, claim or controversy arising
          out of or relating to these Terms or the breach, termination,
          enforcement, interpretation or validity thereof, or to the use of the
          Services (collectively, “Disputes”) will be settled by binding
          arbitration; except that each party retains the right to seek
          injunctive or other equitable relief in a court of competent
          jurisdiction to prevent the actual or threatened infringement,
          misappropriation or violation of a party’s copyrights, trademarks,
          trade secrets, patents, or other intellectual property rights.
        </Typography>
        <Typography paragraph>
          Arbitration Rules and Governing Law. The arbitration will be conducted
          under the Arbitration Act 1996 and administered by the New Zealand
          Dispute Resolution Centre (“NZDRC”) in accordance with the Commercial
          Arbitration Rules for Consumer Related Disputes (the “NZDRC Rules”).
          The NZDRC Rules are available at Arbitration Rules - New Zealand
          Dispute Resolution Centre (
          <Link href="https://www.nzdrc.co.nz/" target="_blank">
            nzdrc.co.nz
          </Link>
          ). Arbitration Location and Procedure. Unless you and Bounce otherwise
          agree, the arbitration will be conducted in Wellington, New Zealand.
        </Typography>
        <Typography paragraph>
          No Class Action. You and Bounce agree that each may bring claims
          against the other only in your or its individual capacity and not as a
          plaintiff or class member in any purported class or representative
          proceeding. Further, unless both you and we agree otherwise, the
          arbitrator may not consolidate more than one person’s claims, and may
          not otherwise preside over any form of a representative or class
          proceeding.
        </Typography>
        <Typography paragraph>
          Changes. Notwithstanding the provisions of the “Modification” section
          above, if Bounce amends this “Dispute Resolution” section after the
          date you first accepted these Terms (or accepted any subsequent
          changes to these Terms) you will be notified in accordance with these
          Terms. You may reject any such change by sending us written notice
          (including by email to{" "}
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>{" "}
          within 30 days of the date such change became effective, as indicated
          in the “Last Updated Date” above or in the date of Bounce’s email to
          you notifying you of such change. By rejecting any change, you are
          agreeing that you will arbitrate any Dispute between you and Bounce in
          accordance with the provisions of this “Dispute Resolution” section as
          of the date you first accepted these Terms (or accepted any subsequent
          changes to these Terms).
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          17. International Users
        </Typography>
        <Typography paragraph>
          Bounce makes no claim that the Services are appropriate or may be
          downloaded outside New Zealand. If you access our website or the
          Services from a location outside New Zealand, you do so at your own
          risk and are responsible for compliance with all applicable laws,
          rules, regulations or decrees of your jurisdiction.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          18. General
        </Typography>
        <Typography paragraph>
          The failure of Bounce to enforce any right or provision of these Terms
          will not constitute a waiver of future enforcement of that right or
          provision. The waiver of any such right or provision will be effective
          only if in writing and signed by a duly authorised representative of
          Bounce. Except as expressly set forth in these Terms, the exercise by
          either party of any of its remedies under these Terms will be without
          prejudice to its other remedies under these Terms or otherwise.
        </Typography>
        <Typography paragraph>
          Certain Service features, or website and/or application areas (and
          your access to or use of certain aspects of the Services or content)
          may have different terms and conditions posted or may require you to
          agree with and accept additional terms and conditions. If there is a
          conflict between these Terms and terms and conditions posted for a
          specific area of the website, application, Services, or content, the
          latter terms and conditions will take precedence with respect to your
          use of or access to that area of the website, application, Services,
          or content.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          19. Entire Agreement
        </Typography>
        <Typography paragraph>
          These Terms constitute the entire and exclusive understanding and
          agreement between Bounce and you regarding the Services, content and
          any profile, registration or account made via the Services, and these
          Terms supersede and replace any and all prior oral or written
          understandings or agreements between Bounce and you regarding the
          same.
        </Typography>
      </Box>
    </div>
  );
};

const JumpstartTermsOfUse = () => {
  const config = useContext(ConfigContext);

  return (
    <div>
      <Typography variant="h5" component="p">
        (Last Updated March 21, 2022)
      </Typography>
      <Box my={5}>
        <Divider />
      </Box>
      <Box align="left">
        <Typography paragraph>
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT
          INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. IF
          YOU RESIDE IN THE UNITED STATES, PLEASE NOTE: SECTION 20 OF THESE
          TERMS OF SERVICE CONTAINS AN ARBITRATION CLAUSE AND CLASS ACTION
          WAIVER. IT AFFECTS HOW DISPUTES WITH US ARE RESOLVED. BY ACCEPTING
          THESE TERMS OF SERVICE, YOU AGREE TO BE BOUND BY THIS ARBITRATION
          PROVISION. PLEASE READ IT CAREFULLY. IF YOU DO NOT WISH TO BE SUBJECT
          TO ARBITRATION, YOU MAY OPT OUT OF THE ARBITRATION PROVISION BY
          FOLLOWING THE INSTRUCTIONS PROVIDED IN SECTION 20.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          1. Acceptance of Terms
        </Typography>
        <Typography paragraph>
          Welcome to <HomeLink>{config.marketingHost}</HomeLink>, owned and
          operated by Jumpstart Insurance Solutions, Inc., a Florida corporation
          (“Jumpstart”). Jumpstart is a licensed insurance broker (Surplus Lines
          Broker no. CA #0K67793, OR #3000850129, WA #1053385). We operate this
          website for consumers who may submit an application for insurance
          through Jumpstart’s direct, online, and mobile application services
          (collectively, the “Services”). Note that the Services do not include
          any insurance policy which may be issued to you; any insurance policy
          will be subject to its own terms.
        </Typography>
        <Typography paragraph>
          The insurance product offered through Jumpstart may not be available
          to all persons in a State where the Services are offered, and is not
          available in all States at this time. Certain restrictions,
          conditions, and eligibility requirements apply. The information
          contained in any Jumpstart website, mobile application, or other
          technology is not intended to be an offer to sell or a solicitation in
          connection with any product or service in any jurisdiction where such
          an offer or solicitation would be unlawful or in which we, our
          insurance carrier(s), any managing general underwriters or other
          service provider, are not qualified to do so.
        </Typography>
        <Typography paragraph>
          By using any Service, you agree to comply with and be legally bound by
          the terms, conditions, and restrictions of these Terms of Use
          (“Terms”). These Terms govern your access to and use of the Services
          and constitute a binding legal agreement between you and Jumpstart. In
          these Terms, “you” and “your” refer to users of the Services. If you
          access the Services or accept these Terms on behalf of a company or
          other legal entity, you represent and warrant that you have the
          authority to bind that legal entity and, in such event, “you” and
          “your” will refer to that legal entity. “We”, “us”, or “our” refer to
          Jumpstart. Unless the context requires otherwise, in these Terms words
          in one gender include all genders and words in the singular include
          the plural and vice-versa.
        </Typography>
        <Typography paragraph>
          Please read carefully these Terms and our Privacy Policy, which may be
          found at{" "}
          <Link component={RouterLink} to="/privacy-policy">
            {config.host}/privacy-policy
          </Link>
          , and which is incorporated by reference into these Terms. If you do
          not agree to these Terms. YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING
          OR USING THE SERVICES, YOU ARE INDICATING THAT YOU HAVE READ, AND THAT
          YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT
          AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE
          SERVICES.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          2. Modification
        </Typography>
        <Typography paragraph>
          Jumpstart reserves the right, at its sole discretion, to modify or
          discontinue, temporarily or permanently, the Services or to modify
          these Terms at any time and without prior notice. If we modify these
          Terms, we will post the modification on our website or via our
          application. We will also update the “Last Updated Date” at the top of
          these Terms. Modifications to these Terms will automatically take
          effective upon posting; provided, however, that material changes to
          the Terms will be effective as to an existing User thirty (30) days
          after posting. By continuing to access or use the Services after we
          have posted a modification, you are indicating that you agree to be
          bound by the modified Terms. If the modified Terms are not acceptable
          to you, your only recourse is to stop accessing or using the Services.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          3. License to Services
        </Typography>
        <Typography paragraph>
          Subject to your compliance with these Terms, Jumpstart grants you a
          limited, non-exclusive, revocable, non-transferable right to access
          and view any Jumpstart content solely for your personal use or
          personal commercial use as expressly provided in these Terms. You have
          no right to sublicense the rights granted in this section.
        </Typography>
        <Typography paragraph>
          You will not use, copy, adapt, modify, prepare derivative works based
          upon, distribute, license, collect, aggregate, sell, transfer,
          publicly display, publicly perform, transmit, broadcast or otherwise
          exploit the Services or content, except as expressly permitted in
          these Terms. No licenses or rights are granted to you by implication
          or otherwise under any intellectual property rights owned or
          controlled by Jumpstart or its licensors, except for the licenses and
          rights expressly granted in these Terms. No license or rights are
          granted to use data mining, robots, spiders or similar data gathering
          and extraction tools without our prior written permission; provided,
          however, that a limited exception from the foregoing exclusion is
          provided to general purpose internet search engines and non-commercial
          public archives that use tools to gather information for the sole
          purpose of displaying hyperlinks to the Services, provided that they
          each do so from a stable IP address or range of IP addresses using an
          easily identifiable agent and comply with our robots.txt file. Use of
          the Services by a competitor company, a non-consumer third party, or
          for any purpose not expressly intended by Jumpstart is prohibited.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          4. Eligibility; Digital Signature
        </Typography>
        <Typography paragraph>
          The Services are intended solely for persons who are 18 or older and
          are legally able to enter into a contract. By accessing or using the
          Services you represent and warrant that you are not legally prohibited
          from receiving or using the Services under the laws of the country in
          which you access or use the Service.
        </Typography>
        <Typography paragraph>
          By clicking to accept these Terms, you are deemed to have executed
          these Terms electronically, effective on the date you register your
          Account, pursuant to the U.S. Electronic Signatures in Global and
          National Commerce Act of 2000 (the E-Sign Act) (15 U.S.C. § 7001, et
          seq.). Your use of the Services constitutes an acknowledgement that
          you are able to electronically receive, download, and print these
          Terms, and any amendments, and that you accept that your electronic
          signature is your valid and binding signature as to any document
          between you and Jumpstart or any associated entity.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          5. Registration
        </Typography>
        <Typography paragraph>
          Users do not have to create an account to access the Services.
          However, some feature, such as joining a newsletter mailing list or
          submitting an application for insurance, require registration.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          6. User Affirmations, Conduct and Use
        </Typography>
        <Typography>
          By using the Service, you represent, warrant and agree to the
          following:
        </Typography>
        <Typography paragraph component="ul">
          <li>You are at least 18 years of age.</li>
          <li>
            You agree to comply with any and all laws, rules, regulations, and
            tax obligations that may apply to your use of the Services.
          </li>
          <li>
            Nothing that you upload, publish, represent, warrant or transmit
            using the Services, will infringe, misappropriate or violate a third
            party’s patent, copyright, trademark, trade secret, moral rights or
            other proprietary or intellectual property rights, or rights of
            publicity or privacy, or result in the violation of any applicable
            law or regulation.
          </li>
        </Typography>
        <Typography>
          By using the Service, you represent, warrant and agree to the
          following:
        </Typography>
        <Typography component="ul" paragraph>
          <li>
            You will not use manual or automated software, devices, scripts,
            robots, other means or processes to access, “scrape”, “crawl” or
            “spider” any web pages or other services contained in the Services.
          </li>
          <li>
            You will not use the Services for any commercial or other purposes
            that are not expressly permitted by these Terms.
          </li>
          <li>
            You will not copy, store or otherwise access any information
            contained on the Services for purposes not expressly permitted by
            these Terms.
          </li>
          <li>
            You will not interfere with or damage the Services, including,
            without limitation, through the use of viruses, cancel bots, Trojan
            horses, harmful code, flood pings, denial-of-service attacks, packet
            or IP spoofing, forged routing or electronic mail address
            information or similar methods or technology;
          </li>
          <li>
            All information you submit will be complete and accurate, and you
            will not impersonate any person or entity, or falsify or otherwise
            misrepresent yourself or your affiliation with any person or entity.
          </li>
          <li>
            You will not use, display, mirror or frame the Services, Jumpstart’s
            name, any Jumpstart trademark, logo or other proprietary
            information, or the layout and design of any page or form contained
            on a page, without Jumpstart’s express written consent.
          </li>
          <li>
            You will not access, tamper with, or use non-public areas of any
            website or application, Jumpstart’s computer systems, or the
            technical delivery systems of the Services or any third-party
            provider system.
          </li>
          <li>
            You will not attempt to probe, scan, or test the vulnerability of
            any Jumpstart system or network or breach any security or
            authentication measures.
          </li>
          <li>
            You will not attempt to avoid, bypass, remove, deactivate, impair,
            descramble, or otherwise circumvent any technological measure
            implemented by Jumpstart or any of Jumpstart’s providers or any
            other third party to protect the Services.
          </li>
          <li>
            You will not advocate, encourage, or assist any third party in doing
            any of the foregoing.
          </li>
        </Typography>
        <Typography paragraph>
          Jumpstart will have the right to investigate and prosecute violations
          of any of the above to the fullest extent of the law. Jumpstart may
          involve and cooperate with law enforcement authorities in prosecuting
          Users who violate these Terms.
        </Typography>
        <Typography paragraph>
          You acknowledge that Jumpstart has no obligation to monitor your
          access to or use of the Services or to review or edit any content, but
          has the right to do so for the purpose of operating the Services, to
          ensure your compliance with these Terms, or to comply with applicable
          law or the order or requirement of a court, administrative agency or
          other governmental body.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          7. Privacy
        </Typography>
        <Typography paragraph>
          See Jumpstart’s Privacy Policy at{" "}
          <Link component={RouterLink} to="/privacy-policy">
            {config.host}/privacy-policy
          </Link>{" "}
          for information and notices concerning Jumpstart’s collection and use
          of your personal information.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          8. Ownership
        </Typography>
        <Typography paragraph>
          The Services and content are protected by copyright, trademark, and
          other laws of the United States and foreign countries. You acknowledge
          and agree that the Services, including all associated intellectual
          property rights, are the exclusive property of Jumpstart and its
          licensors. You will not remove, alter or obscure any copyright,
          trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the Services. You may use content
          obtained from the Services for the permitted purposes set forth in
          these Terms, provided that the following copyright notice appears in
          all copies of any downloaded or printed materials: &quot;©{" "}
          {new Date().getFullYear()} Jumpstart Insurance Solutions, Inc. All
          rights reserved.&quot; Any other use is prohibited.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          9. Links
        </Typography>
        <Typography paragraph>
          The Services may contain links to third-party websites or resources.
          You acknowledge and agree that Jumpstart is not responsible or liable
          for: (i) the availability or accuracy of such websites or resources;
          or (ii) the content, products, or services on or available from such
          websites or resources. Links to such websites or resources do not
          imply any endorsement by Jumpstart. You acknowledge sole
          responsibility for and assume all risk arising from your use of any
          such websites, resources, content, products or services on or
          available from such websites or resources.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          10. Advertisements
        </Typography>
        <Typography paragraph>
          Jumpstart may include advertisements on its own behalf or paid
          advertisements on behalf of interested companies and/or individuals on
          the Services. By clicking on the advertisements, you may be shifted to
          a Website of the advertiser or receive other messages, information, or
          offers from the advertiser. You acknowledge and agree that Jumpstart
          is not liable for the privacy practices of advertisers or the content
          of their Websites, information, messages or offers. Users are wholly
          liable for all communications with advertisers and for all
          transactions subsequently executed.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          11. Proprietary Rights Notices
        </Typography>
        <Typography paragraph>
          All trademarks, service marks, logos, trade names and any other
          proprietary designations of Jumpstart used herein are trademarks or
          registered trademarks of Jumpstart. Any other trademarks, service
          marks, logos, trade names and any other proprietary designations are
          the trademarks or registered trademarks of their respective parties.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          12. Termination
        </Typography>
        <Typography paragraph>
          We may, in our discretion and without liability to you, with or
          without cause, with or without prior notice, and at any time: (a)
          terminate these Terms or your access to our Services, and (b)
          deactivate or cancel any user’s account.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          13. Disclaimers
        </Typography>
        <Typography paragraph>
          IF YOU CHOOSE TO USE THE SERVICES, YOU DO SO AT YOUR SOLE RISK. THE
          SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT WARRANTY OF
          ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING,
          JUMPSTART EXPLICITLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES OF
          TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
          ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF
          COURSE OF DEALING OR USAGE OF TRADE. JUMPSTART MAKES NO REPRESENTATION
          OR WARRANTY THAT: (i) THE OPERATION OF THE SERVICE WILL BE ERROR-FREE
          OR UNINTERRUPTED; (ii) ANY DEFECTS WILL BE CORRECTED; (iii) THE
          SERVICES, COMMUNICATIONS FROM US, OR ANY SERVER THAT MAKES THE
          SERVICES AVAILABLE TO YOU ARE FREE OF VIRUSES OR OTHER HARMFUL CODE OR
          TOOLS; (iv) THE SERVICES WILL BE AVAILABLE TO YOU OR THAT YOU QUALIFY
          FOR THE PRODUCTS OR SERVICES OFFERED; (v) THE CONTENT ON THE SERVICES
          WILL BE ACCURATE AS OF ANY PARTICULAR DATE; OR (vi) THE PRODUCTS OR
          SERVICES OFFERED THROUGH THE SITE ARE AVAILABLE OUTSIDE OF THE UNITED
          STATES OR IN JURISDICTIONS WHERE IN WHICH JUMPSTART IS NOT PROPERLY
          LICENSED. If you are in a jurisdiction that does not allow a disclaim
          or implied warranties, the above disclaimer or a portion of it may not
          apply to you.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          14. Limitation of Liability
        </Typography>
        <Typography paragraph>
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
          LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
          SERVICES REMAINS WITH YOU. NEITHER JUMPSTART NOR ANY OTHER PARTY
          INVOLVED IN CREATING, PRODUCING OR DELIVERYING THE SERVICES WILL BE
          LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
          DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL,
          SERVICES INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST
          OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR
          BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUR OF OR IN CONNECTION
          WITH THESE TERMS, FROM THE USE OF OR INABILITY TO USE THE SERVICES,
          FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS AS A RESULT OF YOUR
          USE OF THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
          (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY,
          AND WHETHER OR NOT JUMPSTART HAS BEEN INFORMED OF THE POSSBILITY OF
          SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO
          HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        </Typography>
        <Typography paragraph>
          IN NO EVENT WILL JUMPSTART’S AGGREGATE LIABILITY ARISING OUT OF OR IN
          CONNECTION WITH THESE TERMS AND YOUR USE OF THE SERVICES, INCLUDING,
          BUT NOT LIMITED TO, FROM A GOOD OFFERED FOR SALE, OR FROM THE USE OF
          OR INABILITY TO USE THE SERVICES AND IN CONNECTION WITH ANY
          INTERACTIONS WITH ANY MERCHANT, EXCEED ONE HUNDRED DOLLARS ($100). THE
          LIMITATION OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE
          BASIS OF THE BARGAIN BETWEEN JUMPSTART AND YOU. Some jurisdictions do
          not allow the exclusion of limitation of liability for consequential
          or incidental damages, so the above limitation may not apply to you.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          15. Indemnification
        </Typography>
        <Typography paragraph>
          You agree to release, defend, indemnify, and hold Jumpstart and its
          affiliates and subsidiaries, and their officers, directors, employees
          and agents, harmless from and against any claims, liabilities,
          damages, losses, and expenses, including, without limitation,
          reasonable legal fees, arising out of or in any way connected with (a)
          your access to or use of the Services, or your violation of these
          Terms; (b) your reliance on the Services; (d) your violation of any
          third party right, including without limitation any copyright,
          property, or privacy right; or (e) any claim that your use of the
          Services caused damage to a third party. Jumpstart shall have the
          right to control all defense and settlement activities.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          16. Assignment
        </Typography>
        <Typography paragraph>
          You may not assign or transfer these Terms, by operation of law or
          otherwise, without Jumpstart’s prior written consent. Any attempt by
          you to assign or transfer these Terms, without such consent, will be
          null and of no effect. Jumpstart may assign or transfer these Terms,
          at its sole discretion, without restriction. Subject to the foregoing,
          these Terms will bind and inure to the benefit of the parties, their
          successors and permitted assigns.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          17. Notices
        </Typography>
        <Typography paragraph>
          Unless otherwise specified herein, any notices or other communications
          permitted or required hereunder, including those regarding
          modifications to these Terms, will be in writing and given by
          Jumpstart by posting to the website or via the application. For
          notices or communications by Jumpstart made by e-mail, if any, the
          date of receipt will be deemed the date on which such notice is
          transmitted.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          18. Controlling Law and Jurisdiction
        </Typography>
        <Typography paragraph>
          You agree that (i) the Services shall be deemed solely based in
          California, and (ii) the Services shall be deemed a passive website
          that does not give rise to personal jurisdiction over Jumpstart,
          either specific or general, in jurisdictions other than California
          (for the avoidance of doubt, this provision does not apply to any
          policy of insurance you may purchase). These Terms shall be governed
          by the internal substantive laws of the State of California, without
          respect to its conflict of laws principles. You and we agree to submit
          to the personal jurisdiction of a state court located in San
          Francisco, California for any actions for which the parties retain the
          right to seek injunctive or other equitable relief in a court of
          competent jurisdiction to prevent the actual or threatened
          infringement, misappropriation or violation of a party’s copyrights,
          trademarks, trade secrets, patents, or other intellectual property
          rights, as set forth in the Dispute Resolution Provision below.
        </Typography>
        <Typography paragraph>
          YOU AND JUMPSTART AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR
          RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
          CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
          PERMANENTLY BARRED AND WAIVED.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          19. Severability
        </Typography>
        <Typography paragraph>
          These Terms are intended to govern the agreement between Jumpstart and
          you to the extent permitted by all applicable laws, ordinances, rules,
          and regulations. If any provision of these Terms or the application
          thereof to any person or circumstances shall, for any reason or to any
          extent, be invalid or unenforceable, the remainder of these Terms and
          the application of such provision to other persons or circumstances
          shall not be affected thereby, but rather shall be enforced to the
          greatest extent permitted by law.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          20. Dispute Resolution Provision
        </Typography>
        <Typography paragraph>
          You and Jumpstart (for itself and all insurers, partners, suppliers or
          resellers) agree that any dispute, claim or controversy arising out of
          or relating to these Terms or the breach, termination, enforcement,
          interpretation or validity thereof, or to the use of the Services
          (collectively, “Disputes”) will be settled by binding arbitration;
          except that each party retains the right to seek injunctive or other
          equitable relief in a court of competent jurisdiction to prevent the
          actual or threatened infringement, misappropriation or violation of a
          party’s copyrights, trademarks, trade secrets, patents, or other
          intellectual property rights.
        </Typography>
        <Typography paragraph>
          You acknowledge and agree that you and Jumpstart are each waiving the
          right to a trial by jury or to participate as a plaintiff or class
          member in any purported class action or representative proceeding.
          Further, unless both you and Jumpstart otherwise agree in writing, the
          arbitrator may not consolidate more than one person’s claims, and may
          not otherwise preside over any form of any class or representative
          proceeding. If this specific paragraph is held unenforceable, then the
          entirety of this Dispute Resolution section will be deemed void.
          Except as provided in the preceding sentence, this Dispute Resolution
          section will survive any termination of these Terms.
        </Typography>
        <Typography paragraph>
          Arbitration Rules and Governing Law. The arbitration will be
          administered by the American Arbitration Association (“AAA”) in
          accordance with the Commercial Arbitration Rules and the Supplementary
          Procedures for Consumer Related Disputes (the “AAA Rules”) then in
          effect, except as modified by this “Dispute Resolution” section. (The
          AAA Rules are available at{" "}
          <Link href="https://www.adr.org" target="_blank">
            www.adr.org
          </Link>{" "}
          or by calling the AAA at 1-800- 778-7879.) The Federal Arbitration Act
          will govern the interpretation and enforcement of this section.
        </Typography>
        <Typography paragraph>
          Arbitration Process. A party who desires to initiate arbitration must
          provide the other party with a written Demand for Arbitration as
          specified in the AAA Rules. (The AAA provides an online Demand for
          Arbitration link at{" "}
          <Link href="https://www.adr.org" target="_blank">
            https://www.adr.org
          </Link>
          .) The arbitrator will be either a retired judge or an attorney
          licensed to practice law and will be selected by the parties from the
          AAA’s roster of consumer dispute arbitrators. If the parties are
          unable to agree upon an arbitrator within seven (7) days of delivery
          of the Demand for Arbitration, then the AAA will appoint the
          arbitrator in accordance with the AAA Rules.
        </Typography>
        <Typography paragraph>
          Arbitration Location and Procedure. Unless you and Jumpstart otherwise
          agree, the arbitration will be conducted in the county where you
          reside. If your claim does not exceed $10,000, then the arbitration
          will be conducted solely on the basis of documents you and Jumpstart
          submit to the arbitrator, unless you request a hearing or the
          arbitrator determines that a hearing is necessary. If your claim
          exceeds $10,000, your right to a hearing will be determined by the AAA
          Rules. Subject to the AAA Rules, the arbitrator will have the
          discretion to direct a reasonable exchange of information by the
          parties, consistent with the expedited nature of the arbitration.
        </Typography>
        <Typography paragraph>
          Arbitrator’s Decision. The arbitrator will render an award within the
          time frame specified in the AAA Rules. The arbitrator’s decision will
          include the essential findings and conclusions upon which the
          arbitrator based the award. Judgment on the arbitration award may be
          entered in any court having jurisdiction thereof. The arbitrator’s
          award damages must be consistent with the terms of the “Limitation of
          Liability” section above as to the types and the amounts of damages
          for which a party may be held liable. The arbitrator may award
          declaratory or injunctive relief only in favor of the claimant and
          only to the extent necessary to provide relief warranted by the
          claimant’s individual claim. If you prevail in arbitration you will be
          entitled to an award of attorneys’ fees and expenses, to the extent
          provided under applicable law.
        </Typography>
        <Typography paragraph>
          Fees. Your responsibility to pay any AAA filing, administrative and
          arbitrator fees will be solely as set forth in the AAA Rules. However,
          if your claim for damages does not exceed $20,000, Jumpstart will pay
          all such fees unless the arbitrator finds that either the substance of
          your claim or the relief sought in your Demand for Arbitration was
          frivolous or was brought for an improper purpose (as measured by the
          standards set forth in Federal Rule of Civil Procedure 11(b)).
        </Typography>
        <Typography paragraph>
          No Class Action. No Class Action. YOU AND JUMPSTART AGREE THAT EACH
          MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
          CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
          OR REPRESENTATIVE PROCEEDING. Further, unless both you and we agree
          otherwise, the arbitrator may not consolidate more than one person’s
          claims, and may not otherwise preside over any form of a
          representative or class proceeding.
        </Typography>
        <Typography paragraph>
          Changes. Notwithstanding the provisions of the “Modification” section
          above, if Jumpstart amends this “Dispute Resolution” section after the
          date you first accepted these Terms (or accepted any subsequent
          changes to these Terms) you will be notified in accordance with these
          Terms. You may reject any such change by sending us written notice
          (including by email to{" "}
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>{" "}
          within 30 days of the date such change became effective, as indicated
          in the “Last Updated Date” above or in the date of Jumpstart’s email
          to you notifying you of such change. By rejecting any change, you are
          agreeing that you will arbitrate any Dispute between you and Jumpstart
          in accordance with the provisions of this “Dispute Resolution” section
          as of the date you first accepted these Terms (or accepted any
          subsequent changes to these Terms).
        </Typography>
        <Typography paragraph>
          Enforceability. If the “No Class Action” section of this Dispute
          Resolution Provision is found to be unenforceable, or if the entire
          Dispute Resolution Provision is found to be unenforceable, then the
          entirety of the entirety of the Dispute Resolution Provision will be
          null and void and, in that case, the parties agree that the exclusive
          jurisdiction and venue described above will govern any action arising
          out of or related to these Terms.
        </Typography>
        <Typography paragraph>
          Right to Opt Out of Arbitration and Class Action/Jury Trial Waiver.
          You may opt out of the foregoing arbitration and class action/jury
          trial waiver provision of this Agreement by notifying us in writing
          within 30 days of the date you first registered for the Services. To
          opt out, you must send a written notification to Jumpstart Insurance
          Solutions, Inc., Attn: Legal, 344 Thomas L Berkley Way, Oakland, CA
          94612 that includes (a) your account username, (b) your name, (c) your
          address, (d) your telephone number, (e) your email address, and (f) a
          clear statement indicating that you do not wish to resolve claims
          through arbitration and demonstrating compliance with the 30-day time
          limit to opt out of the above arbitration and class action/jury trial
          waiver provisions. Alternatively or in addition, you may send this
          written notification to{" "}
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>
          .
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          21. International Users
        </Typography>
        <Typography paragraph>
          Jumpstart makes no claim that the Services are appropriate or may be
          downloaded outside the United States. If you access the Services from
          a location outside the United States, you do so at your own risk and
          are responsible for compliance with all applicable laws, rules,
          regulations or decrees of your jurisdiction.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          22. General
        </Typography>
        <Typography paragraph>
          The failure of Jumpstart to enforce any right or provision of these
          Terms will not constitute a waiver of future enforcement of that right
          or provision. The waiver of any such right or provision will be
          effective only if in writing and signed by a duly authorized
          representative of Jumpstart. Except as expressly set forth in these
          Terms, the exercise by either party of any of its remedies under these
          Terms will be without prejudice to its other remedies under these
          Terms or otherwise. If for any reason an arbitrator or a court of
          competent jurisdiction finds any provision of these Terms invalid or
          unenforceable, that provision will be enforced to the maximum extent
          permissible and the other provisions of these Terms will remain in
          full force and effect.
        </Typography>
        <Typography paragraph>
          Certain Services features, or website and/or application areas (and
          your access to or use of certain aspects of the Services) may have
          different terms and conditions posted or may require you to agree with
          and accept additional terms and conditions. If there is a conflict
          between these Terms and terms and conditions posted for a specific
          area of the website, application, or Services, the latter terms and
          conditions will take precedence with respect to your use of or access
          to that area of the website, application, or Services.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          23. Notice to California Residents.
        </Typography>
        <Typography paragraph>
          If you are a California resident, under California Civil Code §
          1789.3, you may contact the Complaint Assistance Unit of the Division
          of Consumer Services of the California Department of Consumer Affairs
          in writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
          California 95834, or by telephone at (800) 952-5210 in order to
          resolve a complaint regarding the Service or to receive further
          information regarding use of the Service.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          24. Entire Agreement
        </Typography>
        <Typography paragraph>
          These Terms constitute the entire and exclusive understanding and
          agreement between Jumpstart and you regarding the Services and these
          Terms supersede and replace any and all prior oral or written
          understandings or agreements between Jumpstart and you regarding the
          same.
        </Typography>
        <Typography variant="body1">
          Disclaimer: The original, legally binding version of this document is
          written in English. If it is translated into other languages by
          non-native English-speakers or by software, there may be discrepancies
          between the English version and the translated version. If so, the
          English version supersedes the translated version.
        </Typography>
      </Box>
    </div>
  );
};

const terms = {
  bounce: <BounceTermsOfUse />,
  jumpstart: <JumpstartTermsOfUse />,
};

const TermsOfUse = () => {
  const config = useContext(ConfigContext);

  return (
    <Box align="center" py={10}>
      <Typography variant="h3" component="h1">
        Terms of Service
      </Typography>
      {terms[config.tenantSlug]}
    </Box>
  );
};

export default TermsOfUse;
