import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
// Use same nav as Onboarding since renewal will redirect into onboarding flow
import OnboardingNav from '../onboarding/components/onboarding-nav';
// Use global footer for consistency
import Footer from "../components/footer"

export const AppLayout = ({children, prevPath}) => {
  return (
    <>
      <Box style={{ minHeight: '100vh' }}>
        <OnboardingNav prevPath={prevPath} />
        {children}
      </Box>
      <Footer/>
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node,
  prevPath: PropTypes.string,
}
