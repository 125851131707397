import React, { useContext } from "react";
import PropTypes from "prop-types";
import Link from "./link";
import ConfigContext from "../config-context";

const HomeLink = React.forwardRef((props, ref) => {
  const config = useContext(ConfigContext);
  const { children, ...rest } = props;
  const to = window.location.host === config.marketingHost ? "/" : undefined;
  const href = to ? undefined : `//${config.marketingHost}`;

  return (
    <Link to={to} href={href} aria-label="home" {...rest} ref={ref}>
      {children}
    </Link>
  );
});

HomeLink.propTypes = {
  children: PropTypes.node,
};

HomeLink.displayName = "HomeLink";

export default HomeLink;
