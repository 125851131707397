import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "core-js/stable"
import "regenerator-runtime/runtime"

import React from "react"
import ReactDOM from "react-dom"
import App from "./app"
import { boot as bootIntercom } from "./lib/intercom"
import "./index.css"
import "./application.css"

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <App flash={window.flash || []} />,
    document.body.appendChild(document.createElement("div"))
  )

  bootIntercom()
})
