import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import ConfigContext from "../config-context";
import { useHistory } from "react-router-dom";
import { EmailSent } from "./email-sent";
import { EmailRequestForm } from "./email-request-form";
import { apiClient } from "api";

const LoginApp = ({ location }) => {
  const history = useHistory();
  const config = useContext(ConfigContext);
  const defaultEmail = location.state?.email;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState(null);

  const onCancel = () => {
    history.push("/start/1");
  };

  const onSubmit = async (form) => {
    try {
      setError(null);
      setLoading(true);
      // 1. split on non-digit characters
      // 2. filter empty strings from split on non-digit characters
      // 3. join using '-'
      const formatted = form.phone.split(/\D/).filter(Boolean).join("-");
      const response = await apiClient.post(
        "/api/v2/auth/policyholder/requestEmail",
        {
          email: form.email,
          phone: formatted,
        }
      );

      if (!response.status || response.status !== 200) {
        console.log("response.data", response.data);
        if (response.data?.Message) {
          throw response.data.Message;
        } else {
          throw "error encountered while requesting email.";
        }
      }

      if (!response.data?.EmailSent) {
        if (response.data?.Message) {
          throw new Error(response.data.Message);
        } else {
          throw "we were unable to complete your request.";
        }
      }

      setEmailSentTo(form.email);
      setEmailSent(true);
    } catch (e) {
      setError(e);

    } finally {
      setLoading(false);
    }
  };

  const onResend = () => {
    setEmailSent(false);
  };

  return (
    <>
      {emailSent ? (
        <EmailSent email={emailSentTo} onResend={onResend} />
      ) : (
        <EmailRequestForm
          error={error}
          email={defaultEmail}
          loading={loading}
          productName={config.productName}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

LoginApp.propTypes = {
  location: PropTypes.object,
};

export default LoginApp;
