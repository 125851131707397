import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import { getAddress, setUser, getJumpstartPolicy } from "../actions/user";
import { numberToCurrency } from "lib/money";
import { format as formatDate, format, parseISO as parseDate } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { findLast, groupBy, last, lowerCase, map, startCase } from "lodash";
import PriceLabel from "../components/price-label";
import { makeStyles } from "@material-ui/core/styles";
import pattern from "images/pattern-zebra.png";
import ConfigContext from "../config-context";
import ChangeAlert from "./change-address/components/change-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
  },
  gradientBox: {
    background: `linear-gradient(-225deg, ${theme.palette.background.light}, ${theme.palette.background.secondaryLight})`,
    borderRadius: 3,
  },
  pattern: {
    backgroundImage: `url(${pattern})`,
    backgroundRepeat: "repeat",
    backgroundSize: "30%",
  },
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
  green: {
    color: theme.palette.success.main,
  },
  labelText: {
    color: theme.palette.primary.textLight,
    fontWeight: 400,
  },
  labelTextDefault: {
    fontWeight: 400,
  },
  supOffset: {
    marginTop: -1 * theme.spacing(0.5),
  },
}));

const policyStatus = (status) => {
  const displayStatus = startCase(lowerCase(status.split("_").join(" ")));
  switch (displayStatus) {
    case "Flagged For Review":
      return "Pending Review";
    default:
      return displayStatus;
  }
};

const policyStatusClassName = (status) => {
  const classes = useStyles();
  switch (status) {
    case "ACTIVE":
      return classes.green;
    case "PENDING_ACTIVATION":
    case "PENDING_CANCELLATION":
    case "FLAGGED_FOR_REVIEW":
      return classes.yellow;
    case "CANCELED":
    case "EXPIRED":
      return classes.red;
    default:
      return "";
  }
};

const Coverage = () => {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const { state } = useStateMachine({ setUser });

  // const policies = groupBy(
  //   state.user.policies,
  //   (policy) => policy.number?.split("-")[0]
  // );

  const policies = useMemo(() => {
    if (!state.user.policyNo) {
      return [];
    }

    return [
      ...state.user.policies.map((x) => getJumpstartPolicy(state, x.PolicyNo)),
    ];
  }, [state.user.policyNo]);

  return (
    <Box className={classes.root} mt={-2}>
      {map(policies, (policy) => {
        // const policy = maxBy(policyGroup, p => parseInt(p.number.split('-')[1]))
        const subscription = last(policy.subscriptions);
        const fee =
          subscription.interval === "month"
            ? state.user.transactionFees.monthly
            : state.user.transactionFees.yearly;
        const pendingEndorsement = findLast(
          policy.endorsements,
          (e) =>
            e.status === "FLAGGED_FOR_REVIEW" ||
            e.status === "PENDING_ACTIVATION"
        );

        return (
          <div key={policy.number}>
            <Box py={2}>
              <Box display="flex">
                <Box flexGrow={1} mb={2}>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.labelText}
                    data-test="policy-number__title"
                  >
                    Policy Number
                  </Typography>
                  <Typography
                    data-test="policy-number"
                    variant="subtitle2"
                    component="p"
                  >
                    {policy.number}
                  </Typography>
                </Box>
                <Box>
                  {/* <Button
                      component={RouterLink}
                      color="primary"
                      to="/account/coverage/new"
                      disableElevation
                    >
                      <AddCircleIcon fontSize="inherit"/>&nbsp;Add a policy!
                    </Button> */}
                </Box>
              </Box>
              {pendingEndorsement && (
                <Box mb={2}>
                  <ChangeAlert
                    endorsement={pendingEndorsement}
                    severity="info"
                  />
                </Box>
              )}
              <Box className={classes.gradientBox} p={1}>
                <Box className={classes.pattern} p={1} px={2}>
                  <StatusHeader
                    status={policyStatus(policy.status)}
                    cssClass={policyStatusClassName(policy.status)}
                    policyNumber={policy.number}
                  />
                  <Grid container>
                    <DisplayLabel label="Insured">{policy.name}</DisplayLabel>
                    <DisplayLabel label="Insured Location">
                      <AddressFormatter address={getAddress(state, policy)} />
                    </DisplayLabel>
                    <DisplayLabel label="Coverage Limit" supOffset>
                      {numberToCurrency(policy.EarthQuakeCoverage, {
                        truncateZeroCents: true,
                      })}
                      <sup>†</sup>
                    </DisplayLabel>
                    <DisplayLabel label="Deductible">
                      {numberToCurrency(0, { truncateZeroCents: true })}
                    </DisplayLabel>
                    {/* TODO: test canceled state */}
                    <DisplayLabel label="Policy Period">
                      {policy.canceledAt ? (
                        <>
                          Canceled on{" "}
                          {formatDate(
                            parseDate(policy.canceledAt),
                            "MMMM d, yyyy"
                          )}
                        </>
                      ) : (
                        <>
                          <span>
                            {formatDate(
                              parseDate(policy.effectiveAt),
                              "MMMM d, yyyy"
                            )}{" "}
                            -{" "}
                            {formatDate(
                              parseDate(policy.expiresAt),
                              "MMMM d, yyyy"
                            )}
                          </span>
                          <span
                            data-test="policy-expiration"
                            style={{
                              display: "block",
                              fontSize: "0.75rem",
                              color: "#757575",
                              fontWeight: 400,
                            }}
                          >
                            both days at 12:01 a.m. local time at the insured
                            address.
                          </span>
                        </>
                      )}
                    </DisplayLabel>

                    <DisplayLabel label="Price" supOffset>
                      {subscription ? (
                        <PriceLabel
                          coupon={subscription.couponCode}
                          checkoutPrice={subscription.checkoutPrice}
                          interval={subscription.interval}
                          showDiscountTerms
                        />
                      ) : (
                        "N/A"
                      )}
                      <sup>*</sup>
                    </DisplayLabel>

                    {policy.status === "ACTIVE" && policy.policyPdfUrl && (
                      <Box mt={2}>
                        <Link href={policy.policyPdfUrl} target="_blank">
                          View / download policy
                        </Link>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
            {policy.status === "ACTIVE" && !pendingEndorsement && (
              <Box>
                <Link
                  color="primary"
                  variant="caption"
                  aria-label="Change Address"
                  alt="Change Address"
                  className={classes.labelTextDefault}
                  component={RouterLink}
                  to={`/account/coverage/${policy.number}/change-address/1`}
                >
                  Moved recently? Need to change your address?
                </Link>
              </Box>
            )}
            <Box textAlign="left" mt={3}>
              <Footnote>
                <sup>*</sup>Including base monthly premium, taxes, and fees.
                Additional credit card convenience fee of{" "}
                {numberToCurrency(fee)} applies.
              </Footnote>
              <Footnote>
                <sup>†</sup>Please refer to your policy for coverage terms and
                conditions.
              </Footnote>
              <Footnote>
                To contact customer service for any reason, please email
                <a href="mailto:support@jumpstartinsurance.com">
                  support@jumpstartinsurance.com
                </a>{" "}
                or call <a href="tel:510-891-1753">510-891-1753</a>
              </Footnote>
            </Box>
          </div>
        );
      })}
    </Box>
  );
};

const Footnote = ({ children }) => {
  return (
    <Typography variant="caption" component="p">
      {children}
    </Typography>
  );
};

Footnote.propTypes = {
  children: PropTypes.node,
};

const DisplayLabel = ({ children, label, supOffset }) => {
  const classes = useStyles();
  const offset = supOffset ? classes.supOffset : "";

  return (
    <Grid item sm={6} xs={12}>
      <Box mt={2}>
        <Typography
          variant="caption"
          component="span"
          className={classes.labelText}
          data-test={"title__" + label}
        >
          {label}
        </Typography>
        <Typography
          variant="subtitle2"
          component="p"
          className={offset}
          data-test={"text__" + label}
        >
          {children}
        </Typography>
      </Box>
    </Grid>
  );
};

DisplayLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  supOffset: PropTypes.bool,
};

const StatusHeader = ({ status, policyNumber, cssClass }) => {
  const classes = useStyles();
  const todayStr = format(new Date(), "MMMM d, yyyy");

  return (
    <Box mb={2}>
      <Typography variant="subtitle1" component="p">
        Coverage is:{" "}
        <Typography
          className={cssClass}
          variant="subtitle1"
          component="span"
          display="inline"
        >
          {status}
        </Typography>
      </Typography>
      <Typography
        variant="caption"
        component="p"
        className={classes.labelTextDefault}
        data-test="coverage__start"
      >
        As of {todayStr}
      </Typography>
    </Box>
  );
};

StatusHeader.propTypes = {
  status: PropTypes.string,
  policyNumber: PropTypes.string,
  cssClass: PropTypes.string,
};

const AddressFormatter = ({
  address: { address1, address2, localities, zip },
}) => {
  return (
    <>
      {address1}
      {address2 ? ` ${address2}` : null}
      {", "}
      {localities}
      {"  "}
      {zip}
    </>
  );
};

AddressFormatter.propTypes = {
  address: PropTypes.object,
};

export default Coverage;
