import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { NextButton } from "./buttons";
import { makeStyles } from "@material-ui/core/styles";
import { useClientMutation } from "api";
import { formatPhone } from "../lib/format";
import ConfigContext from "config-context";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  code: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "220px",
    },
  },
  supportContainer: {
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "50%",
    }
  },
  supportLink: {
    color: "#000",
    textDecoration: "underline",
  },
}));

const VerificationCodeForm = ({
  userId,
  phoneNumber,
  onSubmitSuccess,
  onTryAgain,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setError, isSubmitting } = useForm();
  const config = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const { request: verifyPhone } = useClientMutation(
    "/api/v2/jumpstart/phone/verify",
    {
      name: "verify_phone",
      method: "PUT",
      onCompleted: async (verifyPhoneData) => {
        const errors = (verifyPhoneData.userErrors || []).filter(Boolean);
        if (errors.length > 0 || !verifyPhoneData.Successful) {
          let message = errors[0];
          const fallback = "The code provided does not match. Please check your code or update your phone number and try again.";
          setError("code", {
            type: "manual",
            message: message ?? fallback,
          });
          setLoading(false);
        } else {
          await onSubmitSuccess(phoneNumber);
        }
      },
    }
  );

  const showSupport = useMemo(() => {
    if (!errors.code || !errors.code.message) {
      return false;
    }
    return /max check attempts reached/i.test(errors.code.message);
  }, [errors.code]);

  const onSubmit = async (data) => {
    setLoading(true);

    await verifyPhone({
      body: {
        phone: phoneNumber,
        userId: userId,
        code: data.code,
      },
    });
  };

  const formattedPhone = formatPhone(
    phoneNumber,
    config.phoneFormat,
    config.countryCallingCode
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box textAlign="center">
        <TextField
          autoFocus
          error={!!errors.code}
          className={classes.code}
          data-test="phone__verification"
          helperText={
            errors.code &&
            (errors.code.message || "Must be a valid verification code")
          }
          label="Verification Code"
          name="code"
          inputRef={register({ required: true, minLength: 4, maxLength: 4 })}
          type="number"
          variant="outlined"
        />
        <Typography data-test="phone__code-sent" style={{ marginTop: "1rem" }}>
          We sent a code to {formattedPhone}. Please enter it here.
        </Typography>
        <Box mt={5}>
          <NextButton data-test="phone__code-confirm" disabled={isSubmitting || loading} type="submit">
            Confirm
          </NextButton>
        </Box>
        <Box mt={2}>
          <Button data-test="phone__code-retry" color="primary" onClick={onTryAgain}>
            Try Again
          </Button>
        </Box>
        {showSupport && (
          <Box mt={4} className={classes.supportContainer}>
            <Alert severity="info">
              <AlertTitle data-test="phone__alert">Need assistance?</AlertTitle>
              Contact our Support Team at{" "}
              <a
                href={`tel:${config.supportPhone}`}
                className={classes.supportLink}
              >
                {config.supportPhone}
              </a>{" "}
              or{" "}
              <a
                href={`mailto:${config.supportEmail}`}
                className={classes.supportLink}
              >
                {config.supportEmail}
              </a>{" "}
              for further assistance
            </Alert>
          </Box>
        )}
      </Box>
    </form>
  );
};

VerificationCodeForm.propTypes = {
  userId: PropTypes.string,
  phoneNumber: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  onTryAgain: PropTypes.func,
};

export default VerificationCodeForm;
