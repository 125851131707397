import React, {useContext, useEffect, useMemo, useState} from 'react'
import PropTypes from "prop-types";
import {Switch, Redirect, Route, useLocation, useRouteMatch} from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import {Box} from '@material-ui/core'

import OnboardingNav from './components/onboarding-nav';
import PriceQuote from './steps/price-quote'
import PriceResult from './steps/price-result'
import Name from './steps/name'
import Address from './steps/address'
import BuildingType from './steps/building-type'
import Unavailable from './steps/unavailable'
import Disabled from './steps/disabled'
import CalculatePrice from './steps/calculate-price'
import PhoneVerification from './steps/phone-verification'
import PaymentMethod from './steps/payment-method'
import ExistingAccount from './steps/existing-account'
import InquiryComplete from './steps/inquiry-complete'
import Checkout from './steps/checkout'
import PriceChange from './steps/price-change'
import BuildingTypeDeeplink from './steps/building-type-deeplink'
import CoverageLimitDeeplink from './steps/coverage-limit-deeplink'
import {isActiveUser} from '../actions/user'
import {setOnboarding} from '../actions/onboarding'
import Footer from "../components/footer"
import Spinner from '../components/spinner'
import Slide from './components/slide'
import {getPreferredProductPrice} from '../lib/product-prices'
import ConfigContext from '../config-context'
import QuoteDeeplink from './steps/quote-deeplink'
import { useClientMutation, useClientRequest } from 'api'

export const PATHS = {
  BASE              : 'start',
  DISABLED          : 'disabled',
  PRICE_QUOTE       : '1',
  CALCULATE_PRICE   : '2',
  PRICE_RESULT      : '3',
  NAME              : '4',
  ADDRESS           : '5',
  BUILDING_TYPE     : '6',
  PHONE_VERIFICATION: '7',
  PAYMENT_METHOD    : '8',
  CHECKOUT          : '9',
  PRICE_CHANGE      : 'price-change',
  UNAVAILABLE       : 'unavailable',
  LOGIN             : 'login',
  INQUIRY_COMPLETE  : 'unavailable/thanks',
  RESIDENTIAL       : 'residential',
  BUSINESS          : 'business',
  TEN_K             : '10k',
  TWENTY_K          : '20k',
  QUOTE_DEEPLINK    : 'quote',
}

const OnboardingLayout = ({children, prevPath}) => {
  return (
    <>
      <Box style={{ minHeight: '100vh' }}>
        <OnboardingNav prevPath={prevPath} />
        {children}
      </Box>
      <Footer/>
    </>
  )
}

OnboardingLayout.propTypes = {
  children: PropTypes.node,
  prevPath: PropTypes.string,
}

function useStartTracking() {
  let location = useLocation()
  React.useEffect(() => {
    if (location.pathname === `/${PATHS.BASE}`) {
      // Track start checkout
      window.fbq && window.fbq('track', 'InitiateCheckout')
      window.gtag && window.gtag('event', 'begin_checkout', { event_category: 'checkout', event_label: location.state?.source })
      window.heap && window.heap.track('InitiateCheckout')
    }
  }, [location])
}

const OnboardingApp = () => {
  const config = useContext(ConfigContext)
  useStartTracking()

  const { path, url } = useRouteMatch()
  const { actions, state } = useStateMachine({setOnboarding});
  const [fetchingProductPrices, setFetchingProductPrices] = useState(false)
  const [fetchingCheckoutPrice, setFetchingCheckoutPrice] = useState(false)

  // location
  const location = useLocation();

  // PRICE LIST
  const { request: getProductPrices } = useClientRequest("/api/v2/jumpstart/price/list", {
    lazy: true,
    onCompleted: (data) => {
      actions.setOnboarding({ productPrices: data.pricing });
    },
  });

  // PRICE CHECKOUT

  // check price triton
  const { request: getCheckoutPrice } = useClientRequest(`/api/v2/jumpstart/price/check`, {
    lazy: true,
    onCompleted: (data) => {
      if (!data.productPricing || !Array.isArray(data.productPricing)) {
        return;
      }

      const [checkoutPrice] = data.productPricing;
      actions.setOnboarding({ checkoutPrice });
    },
  });

  useEffect(() => {
    if (state.onboarding.quoteZip && !state.onboarding.productPrices && !fetchingProductPrices) {
      setFetchingProductPrices(true);
      getProductPrices({
        args: {
          zip: state.onboarding.quoteZip,
        },
      });
    }
  })

  useEffect(() => {
    if (state.onboarding.userId && state.onboarding.productPrices && !state.onboarding.checkoutPrice && !fetchingCheckoutPrice) {
      setFetchingCheckoutPrice(true)
      getCheckoutPrice({
        args: {
          zip: state.onboarding.quoteZip,
          product: state.onboarding.productId,
        },
      })
    }
  })

  const prevPath = useMemo(() => {
    if (location.state?.prevPath) {
      return location.state.prevPath;
    }

    let prevPath
    switch(location.pathname) {
      case `${path}/${PATHS.CHECKOUT}`:
        prevPath = `${path}/${PATHS.PAYMENT_METHOD}`
        break
      case `${path}/${PATHS.PAYMENT_METHOD}`:
        prevPath = `${path}/${PATHS.PHONE_VERIFICATION}`
        break
      case `${path}/${PATHS.PHONE_VERIFICATION}`:
        prevPath = `${path}/${PATHS.BUILDING_TYPE}`
        break
      case `${path}/${PATHS.BUILDING_TYPE}`:
        prevPath = `${path}/${PATHS.ADDRESS}`
        break
      case `${path}/${PATHS.ADDRESS}`:
        prevPath = `${path}/${PATHS.NAME}`
        break
      case `${path}/${PATHS.NAME}`:
        prevPath = `${path}/${PATHS.PRICE_RESULT}`
        break
      case `${path}/${PATHS.CALCULATE_PRICE}`:
      case `${path}/${PATHS.PRICE_RESULT}`:
        prevPath = `${path}/${PATHS.PRICE_QUOTE}`
        break
      default:
        prevPath = '/'
        break
    }

    return prevPath
  }, [location]);

  const activeUser = isActiveUser(state)
  if (activeUser && !state.onboarding.multiPolicy) {
    return <Redirect to="/account" />
  }

  let loading = false

  if (state.onboarding.userId && !state.onboarding.email) {
    loading = true
  }

  if (location.pathname === `${path}/${PATHS.PRICE_RESULT}` && !state.onboarding.productPrices && state.onboarding.quoteZip) {
    loading = true
  }

  if (location.pathname === `${path}/${PATHS.PAYMENT_METHOD}` && !state.onboarding.checkoutPrice && state.onboarding.phoneVerified) {
    loading = true
  }

  if (location.pathname === `${path}/${PATHS.CHECKOUT}` && (!state.onboarding.productPrices || !state.onboarding.checkoutPrice) && state.onboarding.card) {
    loading = true
  }

  if (loading) {
    return (
      <OnboardingLayout>
        <Slide title="One sec. We're loading your application details.">
          <Box>
            <Spinner/>
          </Box>
        </Slide>
      </OnboardingLayout>
    )
  }

  // start -> current step
  if (location.pathname === path) {
    if (state.onboarding.card) {
      return <Redirect to={`${path}/${PATHS.CHECKOUT}`}/>
    } else if (state.onboarding.phoneVerified) {
      return <Redirect to={`${path}/${PATHS.PAYMENT_METHOD}`}/>
    } else if (state.onboarding.address?.id && state.onboarding.address?.addressType) {
      return <Redirect to={`${path}/${PATHS.PHONE_VERIFICATION}`}/>
    } else if (state.onboarding.address?.id) {
      return <Redirect to={`${path}/${PATHS.BUILDING_TYPE}`}/>
    } else if (state.onboarding.userId && state.onboarding.productPrices) {
      return <Redirect to={`${path}/${PATHS.ADDRESS}`}/>
    } else if (state.onboarding.productPrices) {
      return <Redirect to={`${path}/${PATHS.PRICE_RESULT}`}/>
    } else {
      return <Redirect to={`${path}/${PATHS.PRICE_QUOTE}`} />
    }
  }

  if (location.pathname === `${path}/${PATHS.CHECKOUT}` && !state.onboarding.card) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.PAYMENT_METHOD}` && !state.onboarding.phoneVerified) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.PHONE_VERIFICATION}` && (!state.onboarding.address?.addressType || !state.onboarding.address?.id)) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.BUILDING_TYPE}` && !state.onboarding.address?.id) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.ADDRESS}` && (!state.onboarding.userId || !state.onboarding.productPrices)) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.NAME}` && !state.onboarding.productPrices) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.PRICE_CHANGE}` && !state.onboarding.productPrices) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.PRICE_RESULT}` && !state.onboarding.quoteZip) {
    return <Redirect to={path} />
  }

  if (location.pathname === `${path}/${PATHS.CALCULATE_PRICE}` && !state.onboarding.quoteZip) {
    return <Redirect to={path} />
  }

  // start/unavailable -> start/1
  if (!state.onboarding.quoteZip && location.pathname === `${path}/${PATHS.UNAVAILABLE}`) {
    return <Redirect to={`${path}/${PATHS.PRICE_QUOTE}`} />
  }

  // * -> start/disabled
  if (!state.systemConfiguration.policySignUpEnabled && location.pathname !== `${path}/${PATHS.DISABLED}` && location.pathname !== `${path}/${PATHS.INQUIRY_COMPLETE}`) {
    return <Redirect to={`${path}/${PATHS.DISABLED}`} />
  }

  return (
    <OnboardingLayout prevPath={prevPath} >
      <Switch>
        <Route
          path={`${path}/${PATHS.PRICE_QUOTE}`}
          exact
          render={props => <PriceQuote {...props} nextPath={`${path}/${PATHS.CALCULATE_PRICE}`} />}
        />
        <Route
          path={`${path}/${PATHS.CALCULATE_PRICE}`}
          exact
          render={props => <CalculatePrice {...props} nextPath={`${path}/${PATHS.PRICE_RESULT}`} />}
        />
        <Route
          path={`${path}/${PATHS.PRICE_RESULT}`}
          exact
          render={props => <PriceResult {...props} nextPath={`${path}/${PATHS.NAME}`} />}
        />
        <Route
          path={`${path}/${PATHS.NAME}`}
          exact
          render={props => <Name {...props} nextPath={`${path}/${PATHS.ADDRESS}`} />}
        />
        <Route
          path={`${path}/${PATHS.ADDRESS}`}
          exact
          render={props => <Address {...props} nextPath={`${path}/${PATHS.BUILDING_TYPE}`} />}
        />
        <Route
          path={`${path}/${PATHS.BUILDING_TYPE}`}
          exact
          render={props => <BuildingType {...props} nextPath={`${path}/${PATHS.PHONE_VERIFICATION}`} />}
        />
        <Route
          path={`${path}/${PATHS.PHONE_VERIFICATION}`}
          exact
          render={props => <PhoneVerification {...props} nextPath={`${path}/${PATHS.PAYMENT_METHOD}`} />}
        />
        <Route
          path={`${path}/${PATHS.PAYMENT_METHOD}`}
          exact
          render={props => {
            return (
              <PaymentMethod {...props} nextPath={`${path}/${PATHS.CHECKOUT}`} />
            );
        }}
        />
        <Route
          path={`${path}/${PATHS.CHECKOUT}`}
          exact
          render={props => <Checkout {...props} />}
        />
        <Route
          path={`${path}/${PATHS.PRICE_CHANGE}`}
          exact
          render={props => <PriceChange {...props} nextPath={`${path}/${PATHS.BUILDING_TYPE}`} />}
        />
        <Route
          path={`${path}/${PATHS.RESIDENTIAL}`}
          exact
          render={props => <BuildingTypeDeeplink {...props} addressType="RESIDENTIAL" />}
        />
        <Route
          path={`${path}/${PATHS.BUSINESS}`}
          exact
          render={props => <BuildingTypeDeeplink {...props} addressType="BUSINESS" />}
        />
        <Route
          path={`${path}/${PATHS.TEN_K}`}
          exact
          render={props => <CoverageLimitDeeplink {...props} coverageLimit={10000} />}
        />
        <Route
          path={`${path}/${PATHS.TWENTY_K}`}
          exact
          render={props => <CoverageLimitDeeplink {...props} coverageLimit={20000} />}
        />
        <Route
          path={`${path}/${PATHS.QUOTE_DEEPLINK}`}
          exact
          render={props => <QuoteDeeplink {...props} />}
        />
        <Route
          path={`${path}/${PATHS.UNAVAILABLE}`}
          exact
          render={props => <Unavailable {...props} nextPath={`${path}/${PATHS.INQUIRY_COMPLETE}`} />}
        />
        <Route
          path={`${path}/${PATHS.DISABLED}`}
          exact
          render={props => <Disabled {...props} nextPath={`${path}/${PATHS.INQUIRY_COMPLETE}`} />}
        />
        <Route
          path={`${path}/${PATHS.LOGIN}`}
          exact
          render={props => <ExistingAccount {...props} nextPath={`${path}/${PATHS.ADDRESS}`} />}
        />
        <Route
          path={`${path}/${PATHS.INQUIRY_COMPLETE}`}
          exact component={InquiryComplete}
        />
      </Switch>
    </OnboardingLayout>
  )
}

export default OnboardingApp
