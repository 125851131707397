import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Box, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const CouponForm = ({ onSubmitSuccess, zip, productId }) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const onSubmit = (form) => {
    clearErrors();
    // getCheckoutPrice({
    //   variables: {
    //     zip,
    //     productId,
    //     couponCode: form.couponCode,
    //   },
    // });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" alignContent="flex-start">
          <Box flexGrow={1} mr={1}>
            <TextField
              className={classes.couponCode}
              error={!!errors.couponCode}
              helperText={errors.couponCode && "Must be a valid coupon code"}
              label="Enter coupon code"
              name="couponCode"
              inputRef={register({ required: true })}
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.couponCode,
              }}
            />
          </Box>
          <Box>
            <Button
              className={classes.buttonCoupon}
              component="button"
              color="primary"
              disabled={false}
              size="large"
              type="submit"
              variant="contained"
            >
              Apply
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

CouponForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  productId: PropTypes.number,
  zip: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  buttonCoupon: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // Same height as the coupon code input
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875),
  },
  couponCode: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default CouponForm;
