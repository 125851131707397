import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import Slide from "onboarding/components/slide";
import { NextButton } from "components/buttons";
import { minBy } from "lodash";
import { numberToCurrency } from "lib/money";
import ZipIcon from "components/zip-icon";
import ProductPrice from "components/product-price";
import { PATHS } from "../onboarding-app";
import { setOnboarding, setCheckoutPrice } from "actions/onboarding";
import Spinner from "components/spinner";
import { useClientRequest } from "api";
import { getProductKey } from "lib/hash-code";

const PriceChange = ({ history, location, nextPath }) => {
  const { actions, state } = useStateMachine({ setOnboarding, setCheckoutPrice });
  const [fetchingProductPrices, setFetchingProductPrices] = useState(false);
  const [productPrices, setProductPrices] = useState(null);
  const toPath = location.state?.toPath ?? nextPath;

  const { request: checkProductPrices, errors: pricesError } = useClientRequest(
    "/api/v2/jumpstart/price/list",
    {
      lazy: true,
      onCompleted: (pd) => {
        if (!pd || !Object.prototype.hasOwnProperty.call(pd, "pricing")) {
          setFetchingProductPrices(false);
          return;
        }

        setProductPrices(pd.pricing);
        setFetchingProductPrices(false);
      },
      onError: () => {},
    }
  );

  useEffect(() => {
    if (!fetchingProductPrices && !productPrices && !pricesError) {
      setFetchingProductPrices(true);
      checkProductPrices({
        args: {
          zip: state.onboarding.address.zip,
        },
      });
    }
  }, [fetchingProductPrices, productPrices, pricesError]);

  const onNext = () => {
    const keyedPrices = productPrices.map((x) => ({
      ...x,
      id: getProductKey(x),
    }));


    actions.setOnboarding({
      quoteZip: state.onboarding.address.zip,
      productPrices: keyedPrices,
    });

    actions.setCheckoutPrice(checkoutPrice);
    history.push(toPath);
  };

  const isAnnual = useMemo(() => {
    return state.onboarding.subscriptionInterval === "YEAR";
  }, [state.onboarding.subscriptionInterval]);

  const checkoutPrice = useMemo(() => {
    if (!productPrices || !productPrices.length) {
      return {};
    }
    const filtered = productPrices.filter(
      (p) => p.productType === state.onboarding.address.addressType
    );

    const fallback = minBy(filtered, "monthlyTotal");
    const checkoutPrice = filtered.find(x => x.coverageLimit === state.quote?.earthquakeCoverage);
    return checkoutPrice ?? fallback;
  }, [productPrices, state.onboarding.address, state.quote?.earthquakeCoverage]);

  const priceLabel = useMemo(() => {
    if (!Object.prototype.hasOwnProperty.call(checkoutPrice, "monthlyTotal")) {
      return "";
    }

    const label = isAnnual ? "yearly" : "monthly";
    const value = isAnnual
      ? checkoutPrice.yearlyTotal
      : checkoutPrice.monthlyTotal;
    const displayValue = numberToCurrency(value, {
      truncateZeroCents: true,
    });

    return `Your new ${label} price is ${displayValue}`;
  }, [checkoutPrice, isAnnual]);

  if (fetchingProductPrices || !productPrices) {
    return (
      <Slide title="One sec. We're recalculating your monthly cost.">
        <Box>
          <Spinner />
        </Box>
      </Slide>
    );
  }

  return (
    <Slide
      title={`This looks like a different zip code than the one provided earlier. ${priceLabel}`}
      iconComponent={<ZipIcon zip={state.onboarding.address.zip} />}
    >
      <ProductPrice productPrice={checkoutPrice} />
      <Box mt={2}>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <Typography align="center" component="p" variant="subtitle1">
              Lump-sum payment after a disruptive earthquake to cover your extra
              expenses.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          color="primary"
          onClick={() => history.push(`/${PATHS.BASE}/${PATHS.ADDRESS}`)}
        >
          Change Address
        </Button>
      </Box>
      <Box mt={5} textAlign="center">
        <NextButton onClick={onNext}>Next</NextButton>
      </Box>
    </Slide>
  );
};

PriceChange.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  nextPath: PropTypes.string,
};

export default PriceChange;
