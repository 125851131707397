import React, { useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStateMachine } from 'little-state-machine'
import { setJumpstartSession } from 'actions/session'
import {
  clearSetupIntent,
  clearTransactionFees,
  setSetupIntent,
  setTransactionFees,
} from 'actions/onboarding'
import {
  Box,
  Button,
  Container,
  Typography,
  Checkbox,
  FormControl,
} from '@material-ui/core'
import PaymentForm from './payment-form'
import CheckIcon from '@material-ui/icons/Check'
import LockIcon from '@material-ui/icons/Lock'
import CardSummary from './card-summary'
import Spinner from 'components/spinner'
import ConfigContext from '../config-context'
import { useClientMutation, useClientRequest } from 'api'
import { PaymentFormContainer } from './payment-form-container'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { HelpAlert } from 'components/help-alert'
import { numberToCurrency } from 'lib/money'
import CheckboxFormControlLabel from './checkbox-form-control-label'
import { useForm } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import { NextButton } from './buttons'

function getTransactionFeeLabel(price, fee, interval) {
  const intervalLabel = interval === 'MONTH' ? 'monthly' : 'yearly'
  const priceLabel = numberToCurrency(price, {
    truncateZeroCents: false,
  })
  const feeLabel = numberToCurrency(fee, {
    truncateZeroCents: false,
  })
  const total = numberToCurrency(price + fee, {
    truncateZeroCents: false,
  })
  return (
    <span data-test="payment__ack-note">
      I acknowledge that by paying via credit card, a nonrefundable credit card
      convenience fee of <span>{feeLabel}</span> has been added to my{' '}
      {intervalLabel} premium of <span>{priceLabel}</span>, for a total{' '}
      {intervalLabel} payment of <span>{total}</span>
    </span>
  )
}

const PaymentWizard = ({
  card,
  onSubmitSuccess,
  showAckCoverageFootnote,
  showUpdateCard,
  submitText,
  userId,
  showCouponHint,
  onUpdateCard,
}) => {
  const config = useContext(ConfigContext)
  const [updateCard, setUpdateCard] = useState(showUpdateCard)
  const [hasCleared, setHasCleared] = useState(false)
  const [error, setError] = useState(null)
  const { actions, state } = useStateMachine({
    clearSetupIntent,
    clearTransactionFees,
    setJumpstartSession,
    setSetupIntent,
    setTransactionFees,
  })

  const { errors, handleSubmit, isSubmitting, register } = useForm({
    defaultValues: {
      ackFee: false,
    },
  });

  const showCardSummary = useMemo(() => {
    return card && !updateCard;
  }, [card, updateCard]);

  const interval = useMemo(() => {
    if (!state.onboarding || !state.onboarding.subscriptionInterval) {
      return 'MONTH'
    }

    return state.onboarding.subscriptionInterval
  }, [state.onboarding.subscriptionInterval])

  const premium = useMemo(() => {
    return state.onboarding.checkoutPrice.yearlyTotal
  }, [state.onboarding.checkoutPrice])

  const price = useMemo(() => {
    if (state.onboarding.subscriptionInterval === 'YEAR') {
      return state.onboarding.checkoutPrice.yearlyTotal
    }

    return state.onboarding.checkoutPrice.monthlyTotal
  }, [state.onboarding.checkoutPrice, state.onboarding.subscriptionInterval])

  const transactionFee = useMemo(() => {
    if (!state.onboarding.transactionFees) {
      return undefined
    }

    return state.onboarding.subscriptionInterval === 'YEAR'
      ? state.onboarding.transactionFees.yearly
      : state.onboarding.transactionFees.monthly
  }, [state.onboarding.transactionFees, state.onboarding.subscriptionInterval])

  useEffect(() => {
    if (
      state.onboarding.setupIntent?.status !== 'succeeded' &&
      (!showUpdateCard || hasCleared)
    ) {
      return
    }

    ;(async () => {
      await clearIntent({})
    })()
  }, [])

  const {
    loading: transactionFeeLoading,
    request: getTransactionFee,
  } = useClientRequest('/api/v2/jumpstart/getTransactionFee', {
    lazy: true,
    onCompleted: (data) => {
      if (!data || (!data.FeeMonthly && !data.FeeYear)) {
        return
      }

      actions.setTransactionFees(data)
    },
    onError: (err) => {
      console.log('transactionFee error', err)
    },
  })

  const {
    request: clearIntent,
    loading: loadingClearIntent,
  } = useClientMutation('/api/v2/jumpstart/clearSetupIntent', {
    method: 'POST',
    name: 'clear_setup_intent',
    onCompleted: () => {
      actions.clearSetupIntent()
      actions.clearTransactionFees()
      setHasCleared(true)
      setUpdateCard(true)
    },
    onError: () => {
      setError('Unable to update card')
    },
  })

  const onSubmit = async (values) => {
    if (!values.ackFee) {
      return;
    }

    onSubmitSuccess()
  }

  const clearIntentForUpdate = async () => {
    if (loadingClearIntent) {
      return
    }

    await clearIntent({})
  }

  const onFormSubmit = async (card) => {
    onUpdateCard(card);
    setUpdateCard(false)
    await getTransactionFee({
      args: {
        card: card.brand,
        country: card.country,
        amount: premium,
      }
    });
  }

  return (
    <Container maxWidth="xs" disableGutters>
      {showCardSummary ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box textAlign="center">
            <Box>
              <CardSummary
                card={card}
                justifyContent="center"
                mb={2}
                variant="subtitle1"
              />
              <Button color="primary" onClick={() => clearIntentForUpdate()}>
                {loadingClearIntent ? <Spinner /> : 'Update'}
              </Button>
              {error && (
                <>
                  <Box data-test="payment__error" mt={4} textAlign="left">
                    <Alert severity="error">
                      <>
                        <AlertTitle>Error</AlertTitle>
                        <Typography>{error}</Typography>
                      </>
                    </Alert>
                  </Box>
                  <Box mt={2} textAlign="left">
                    <HelpAlert />
                  </Box>
                </>
              )}
            </Box>
            <Box my={2}>
              {transactionFee && (
                <FormControl
                  required
                  error={!!errors.ackFee}
                  component="fieldset"
                >
                  <CheckboxFormControlLabel
                    control={
                      <Checkbox
                        data-test="payment__ack-fee"
                        inputRef={register({ required: true })}
                        name="ackFee"
                        color="primary"
                      />
                    }
                    label={getTransactionFeeLabel(
                      price,
                      transactionFee,
                      interval
                    )}
                  />
                  {!!errors.ackFee && (
                    <FormHelperText>
                      You must acknowledge to continue
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Box>
            <Box>
              <NextButton
                data-test="payment__next"
                disabled={transactionFeeLoading}
                type="submit"
              >
                {transactionFeeLoading ? (
                  <Spinner
                    data-test="payment__spinner"
                    size={26}
                    color="inherit"
                  />
                ) : (
                  'Next'
                )}
              </NextButton>
            </Box>
          </Box>
        </form>
      ) : (
        <Box>
          {!loadingClearIntent &&
          (!updateCard || (updateCard && hasCleared)) ? (
            <PaymentFormContainer>
              <PaymentForm
                premium={premium}
                price={price}
                setupIntent={state.onboarding.setupIntent}
                showCouponHint={showCouponHint}
                submitText={submitText}
                transactionFee={transactionFee}
                interval={interval}
                userId={userId}
                onClearIntent={clearIntent}
                onSubmitSuccess={onFormSubmit}
                onSetSetupIntent={actions.setSetupIntent}
              />
            </PaymentFormContainer>
          ) : (
            <Spinner />
          )}
          {card && (
            <Box mt={2} textAlign="center">
              <Button
                data-test="payment__cancel"
                color="primary"
                onClick={() => setUpdateCard(false)}
              >
                Cancel
              </Button>
            </Box>
          )}
          <Box
            mt={5}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {showAckCoverageFootnote && (
              <Typography variant="body2" color="textSecondary">
                <Box component="span" display="flex" alignItems="flex-start">
                  <CheckIcon edge="start" />
                  <Box
                    data-test="payment__ack-footnote1"
                    component="span"
                    ml={1}
                    mb={1}
                  >
                    Your card won&apos;t be charged until you review and agree
                    to the coverage.
                  </Box>
                </Box>
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary">
              <Box component="span" display="flex" alignItems="flex-start">
                <LockIcon />
                <Box data-test="payment__ack-footnote2" component="span" ml={1}>
                  {config.productName} never has access to your card
                  information, it goes straight to the payment processor.
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  )
}

PaymentWizard.propTypes = {
  showAckCoverageFootnote: PropTypes.bool,
  showCouponHint: PropTypes.bool,
  showUpdateCard: PropTypes.bool,
  submitText: PropTypes.string,
  card: PropTypes.object,
  userId: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  saveWithoutChangesComp: PropTypes.object,
  onUpdateCard: PropTypes.func,
}

PaymentWizard.defaultProps = {
  showAckCoverageFootnote: false,
  showCouponHint: false,
  showUpdateCard: false,
  submitText: 'Save',
}

export default PaymentWizard
