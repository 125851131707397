import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 400,
    color: theme.palette.primary.textLight,
    display: "inline",
    width: theme.spacing(12),
  },
  wordBreak: {
    wordWrap: "break-word",
  },
}));

const EditableLabel = ({
  children,
  changeTo,
  labelText,
  showDivider,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Box>
        <Grid container>
          <Grid item md={2} xs={3}>
            <Typography
              variant="body2"
              component="span"
              className={classes.label}
              data-test={"label__"+labelText}
            >
              {labelText}
            </Typography>
          </Grid>
          <Grid item md={8} xs={6}>
            <Box mx={1}>
              <Typography
                variant="subtitle2"
                component="span"
                display="inline"
                className={classes.wordBreak}
                data-test={"text__"+labelText}
              >
                {children}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={2} xs={3}>
            {changeTo && (
              <Typography align="right">
                <Link
                  data-test={"edit__"+labelText}
                  variant="body2"
                  color="primary"
                  component={RouterLink}
                  to={changeTo}
                >
                  Change
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>

      {showDivider && (
        <Box py={1}>
          <Divider />
        </Box>
      )}
    </div>
  );
};

EditableLabel.propTypes = {
  children: PropTypes.node,
  changeTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelText: PropTypes.string,
  showDivider: PropTypes.bool,
  className: PropTypes.string,
};

export default EditableLabel;
