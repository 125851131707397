import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Link,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link as RouterLink, useHistory } from "react-router-dom";
import QueueIcon from "@material-ui/icons/Queue";
import parameterize from "parameterize";
import Image from "material-ui-image";

import { PATHS } from "../onboarding";
import { show as showIntercom } from "lib/intercom";
import ConfigContext from "config-context";
import Darfield from "images/bounce-darfield-4-sep 2010.jpg";
import Lyttleton from "images/bounce-lytleton-22-feb-2011.jpg";
import SoutheastChristchurch from "images/bounce-chch-13-Jun-2011.jpg";
import EastChristchurch from "images/bounce-chch-23-cec-2011.jpg";
import Kaikoura from "images/bounce-kaikoura-13-nov-2016.jpg";
import KaikouraWellington from "images/bounce-kaikoura-13-Nov-2016-wgtn.jpg";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "block",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    minWidth: theme.spacing(3),
  },
  ctaBox: {
    display: "flex",
    flexDirection: "row-reverse",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginBottom: theme.spacing(1),
    },
  },
  cta: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
  },
  h2Override: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
}));

const HeaderCTA = ({ headerName }) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();

  return (
    <Box justifyContent="space-between" my={1} className={classes.ctaBox}>
      <Button
        className={classes.cta}
        variant="contained"
        color="primary"
        component={RouterLink}
        to={`/${PATHS.BASE}`}
        startIcon={<QueueIcon />}
      >
        Get Your {config.productName} Quote
      </Button>
      <Typography component="h2" className={classes.h2Override} variant="h4">
        {headerName}
      </Typography>
    </Box>
  );
};

HeaderCTA.propTypes = {
  headerName: PropTypes.string,
};

const FAQItem = ({ children, question }) => {
  const classes = useStyles();
  const history = useHistory();

  const hash = window.location.hash.replace("#", "");
  const questionParam = parameterize(question);

  const [expanded, setExpanded] = useState(questionParam === hash);

  return (
    <Accordion
      id={questionParam}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <Link onClick={() => history.push(`#${questionParam}`)}>
            {question}
          </Link>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

FAQItem.propTypes = {
  children: PropTypes.node,
  question: PropTypes.string,
};

const BounceIntro = () => {
  return (
    <div>
      <Typography variant="body1" paragraph>
        Bounce is a Lloyd’s of London coverholder with a mission to help you
        build financial resilience to natural disasters.
      </Typography>
      <Typography variant="body1" paragraph>
        We are providing low cost, quick pay earthquake insurance with the aim
        of getting more Kiwis protected. Bounce coverage is based simply on an
        earthquake exceeding the seismic trigger, your insured location and your
        experience of extra expenses, not time-consuming claims paperwork.
      </Typography>
      <Typography variant="body1" paragraph>
        Here&apos;s how it works: You pay a low annual or monthly cost in
        premiums. If your insured location experiences earthquake shaking of a
        certain intensity (as set out in your policy), you simply reply to our
        text message to confirm that you&apos;ve been affected. Subject to the
        terms and conditions of the policy being met, we then deposit your
        payment directly to your bank account, after only a few short days.
      </Typography>
      <Typography variant="body1" paragraph>
        No paperwork, no deductible, no visit from an insurance assessor.
      </Typography>
    </div>
  );
};

const JumpstartIntro = () => {
  return (
    <div>
      <Typography variant="body1" paragraph>
        Jumpstart is a surplus lines insurance broker with a mission to help you
        build financial resilience to natural disasters.
      </Typography>
      <Typography variant="body1" paragraph>
        We are making a first layer of earthquake insurance accessible to more
        people. Jumpstart coverage is based simply on your insured location and
        your experience of extra expenses, not time-consuming claims paperwork.
        Low-cost, no-hassle earthquake insurance gets more people protected.
      </Typography>
      <Typography variant="body1" paragraph>
        Here&apos;s how it works: You pay a low monthly cost in premiums. If
        your insured location experiences intense earthquake shaking, you simply
        reply to our text message to confirm that you&apos;ve been affected. We
        then deposit your payment directly to your bank account, after only a
        few short days.
      </Typography>
      <Typography variant="body1" paragraph>
        No paperwork, no deductible, no visit from an insurance adjuster.
      </Typography>
    </div>
  );
};

const intros = {
  bounce: <BounceIntro />,
  jumpstart: <JumpstartIntro />,
};

const BounceGeneralFaqs = () => {
  return (
    <div>
      <FAQItem question="How is Bounce different?">
        <Typography paragraph>
          Conventional earthquake insurance is designed to cover significant
          loss, but Bounce is different. The Bounce insurance product is
          designed to support your financial resilience by assisting you with
          immediate cash flow for those expenses which you are unable to recover
          from other insurance policies. A Bounce cash payment following an
          insured earthquake event is paid in days and helps with any immediate
          financial losses or additional expenses that you may have as you start
          your journey towards recovery. A Bounce policy can be a useful
          complement to traditional insurance because it pays quickly, has no
          excess or deductible, and covers any extra expense, not just property
          damage.
        </Typography>
      </FAQItem>

      <FAQItem question="What can I use the payment money for?">
        <Typography>
          You may use the payment for quake related losses or costs, such as:
        </Typography>
        <Typography component="div">
          <ul>
            <li>Living expenses and food.</li>
            <li>Deductibles from other insurance policies.</li>
            <li>Loss of income.</li>
            <li>Professional and technical advice.</li>
            <li>Mental wellbeing support.</li>
            <li>Replacing broken items.</li>
            <li>Moving costs and temporary accommodation.</li>
            <li>Child/elder care.</li>
            <li>Emergency home repairs.</li>
            <li>Clean up and property security.</li>
          </ul>
        </Typography>
        <Typography>
          The only things not covered are losses already paid by other
          insurance, and losses due to bodily harm. Oh, and earthquakes caused
          by nuclear explosions, cyber attacks, wars and terrorist attacks.
          (Seriously.) Other than that, you&apos;re the one who will best know
          your needs—the payment is yours to spend as you need to recover.
        </Typography>
      </FAQItem>

      <FAQItem question="Is there a deductible?">
        <Typography>
          No. Each payment is deposited directly into your bank account.
        </Typography>
      </FAQItem>

      <FAQItem question="Can I buy more than $10k of coverage?">
        <Typography paragraph>
          Yes, we offer a couple of options for individuals with sum insured
          values of $10K and $20K available. We offer a slightly wider range of
          options for Business customers with sum insured values of $10K, $20K
          and $50K being available.
        </Typography>
        <Typography>
          That said, every adult (18+) at a given location can buy a policy.
          Businesses can buy a policy for up to $50k of coverage. This includes
          home-based businesses.
        </Typography>
      </FAQItem>

      <FAQItem question="Why do I need earthquake insurance?">
        <Typography paragraph>
          New Zealand has had 7 significant earthquakes in the past decade. The
          next Big One in New Zealand isn&apos;t a matter of &quot;if&quot; but
          &quot;when&quot;.
        </Typography>
        <Typography paragraph>
          Research by GNS Science has found that the 2016 Kaikoura quake has
          “loaded” the Wairarapa fault line and this could result in a major
          quake. Researchers have also found that the massive Alpine Fault is
          due for another big earthquake, and similarly, the likelihood of a
          rupture of Hikurangi fault line is increasing. Finally, in their
          forecasting, GeoNet think that the probability of a magnitude 5.0-5.9
          occurring in Canterbury is 38%, and there is a 34% probability of a
          magnitude 6.0-6.9 occurring in the Kaikoura region.
        </Typography>
        <Typography paragraph>So why buy earthquake insurance?</Typography>
        <Typography paragraph>
          The first reason is to be prudent. While New Zealand has the fourth
          highest insurance penetration in the world, we still have an
          underinsurance problem. Most kiwis are not insured for the full cost
          to replace their house or contents and if disaster strikes this leaves
          them vulnerable. Things like shared walls, retaining walls, shared
          boundaries and access ways, and multi-use buildings also adds to the
          complexity of claim resolution.
        </Typography>
        <Typography paragraph>
          Secondly, our product is designed to provide a quick payment to our
          customers to help our policy holders with any immediate financial
          losses or costs resulting from an earthquake. Due to the complexity
          and sheer scale of a major earthquake, the claims response and timing
          of settlement from your traditional insurer could take some time to
          come through.
        </Typography>
        <Typography>
          When there&apos;s more money in the local economy, we all recover more
          quickly.
        </Typography>
      </FAQItem>

      <FAQItem question="How is the premium determined?">
        <Typography>
          Bounce&apos;s innovative coverage has a low monthly premium, based on
          your postcode. Powered by GeoNet/ GNS Science data, we determine the
          likelihood of a major earthquake affecting you in the future.
          Locations with higher earthquake risk have higher premiums.
        </Typography>
      </FAQItem>
    </div>
  );
};

const JumpstartGeneralFaqs = () => {
  return (
    <div>
      <FAQItem question="How is Jumpstart different?">
        <Typography paragraph>
          Conventional earthquake insurance is designed to cover significant
          loss, but Jumpstart is different. Our goal is to quickly cover your
          immediate needs after the quake.
        </Typography>
        <Typography>
          Even in a large quake, most damage is minor, and a total loss is the
          exception, as shown repeatedly in post-quake studies by the Earthquake
          Engineering Research Institute and others.
        </Typography>
      </FAQItem>

      <FAQItem question="What can I use the payment money for?">
        <Typography>
          Use the money for any quake-related expense, such as:
        </Typography>
        <Typography component="div">
          <ul>
            <li>Living Expenses</li>
            <li>Replacing Broken Items</li>
            <li>Moving Costs</li>
            <li>Child/Elder Care</li>
            <li>Home Repairs</li>
          </ul>
        </Typography>
        <Typography>
          The only things not covered are losses already paid by other
          insurance, and losses due to bodily harm. Oh, and earthquakes caused
          by nuclear explosions. (Seriously.) Other than that, you&apos;re the
          one who will best know your needs—the payment is yours to spend as you
          need to recover.
        </Typography>
      </FAQItem>

      <FAQItem question="Is there a deductible?">
        <Typography>
          No. Each payment is deposited directly into your bank account.
        </Typography>
      </FAQItem>

      <FAQItem question="Can I buy more than $10k of coverage?">
        <Typography>
          At this time, we have only one limit of coverage ($10k). That said,
          every adult (18+) at a given location can buy a policy. Businesses can
          buy a policy for $20k of coverage. This includes home-based
          businesses.
        </Typography>
      </FAQItem>

      <FAQItem question="Why do I need earthquake insurance?">
        <Typography paragraph>
          The next Big One isn&apos;t a matter of &quot;if&quot; but
          &quot;when.&quot;
        </Typography>
        <Typography paragraph>
          Currently less than 20% of residents in California, Oregon, and
          Washington are insured for earthquakes, and because of deductibles,
          many fewer will experience a payout. In the 2014 South Napa
          earthquake, 40,000 households experienced strong shaking but fewer
          than 200 residential insurance claims resulted in payment because the
          losses experienced did not exceed the deductible.
        </Typography>
        <Typography>
          When there&apos;s more money in the local economy, we all recover more
          quickly.
        </Typography>
      </FAQItem>

      <FAQItem question="How is the premium determined?">
        <Typography>
          Jumpstart&apos;s innovative coverage has a low monthly premium, based
          on your zip code. Powered by USGS data, we determine the likelihood of
          a major earthquake affecting you in the future. Locations with higher
          earthquake risk have higher premiums.
        </Typography>
      </FAQItem>
    </div>
  );
};

const generalFaqs = {
  bounce: <BounceGeneralFaqs />,
  jumpstart: <JumpstartGeneralFaqs />,
};

const BounceAvailabilityFaqs = () => {
  return (
    <div>
      <FAQItem question="Is Bounce available to renters?">
        <Typography>
          Yes. Bounce is available to both renters and homeowners. Bounce maybe
          attractive to renters because the payment covers any extra expense,
          not just damage to your contents—relocation expenses, daycare—whatever
          you need.
        </Typography>
      </FAQItem>

      <FAQItem question="Is Bounce available to homeowners?">
        <Typography>
          Yes. Bounce is available to both homeowners and renters. Bounce maybe
          attractive to homeowners because it can provide a quick cash infusion
          after a quake for whatever they need to recover—whether it&apos;s
          emergency property repair, daycare, or anything else. Bounce will
          complement your existing insurance by boosting your financial
          resilience.
        </Typography>
      </FAQItem>

      <FAQItem question="Is Bounce available for businesses?">
        <Typography>
          Yes. Any business or non-profit can obtain a policy for each location
          that it owns or operates. A business can choose $10,000, $20,000, or
          $50,000 of coverage. This helps cover the financial gap caused by slow
          responding business interruption policies and those holding relatively
          high excesses on traditional commercial policies.
        </Typography>
      </FAQItem>

      <FAQItem question="Can someone have multiple properties covered by Bounce?">
        <Typography>
          Yes. You can purchase Bounce for any locations that you live in or
          own.
        </Typography>
      </FAQItem>

      <FAQItem question="Can I buy Bounce for someone else?">
        <Typography>
          Yes. The person paying the premium can be different from the person
          receiving the payment.
        </Typography>
      </FAQItem>

      <FAQItem question="Can a married couple or roommates each have separate coverage even if they live at the same address?">
        <Typography>Yes.</Typography>
      </FAQItem>

      <FAQItem question="How old does a person have to be to qualify for Bounce's coverage option?">
        <Typography>18 years or older.</Typography>
      </FAQItem>

      <FAQItem question="Can Bounce be purchased in addition to other earthquake insurance policies?">
        <Typography>
          Yes. Bounce can be purchased as additional coverage for someone who
          already has a traditional insurance policy. A Bounce policy can be a
          valuable complement to conventional house and contents insurance
          because it pays quickly, has no deductible, and covers any extra
          expense, not just property damage.
        </Typography>
      </FAQItem>
    </div>
  );
};

const JumpstartAvailabilityFaqs = () => {
  return (
    <div>
      <FAQItem question="Is Jumpstart available to renters?">
        <Typography>
          Yes. Jumpstart is available to both renters and homeowners. Many
          renters will choose Jumpstart because the payment covers any extra
          expense, not just damage to your contents—moving expenses,
          daycare—whatever you need.
        </Typography>
      </FAQItem>

      <FAQItem question="Is Jumpstart available to homeowners?">
        <Typography>
          Yes. Jumpstart is available to both homeowners and renters. Many
          homeowners will choose Jumpstart because it can provide a quick cash
          infusion after a quake for whatever they need to recover—whether
          it&apos;s property repair, daycare, or anything else. Even if you
          already have other earthquake insurance, you can also buy Jumpstart.
        </Typography>
      </FAQItem>

      <FAQItem question="Is Jumpstart available for businesses?">
        <Typography>
          Yes! Any business or non-profit can obtain a policy for each location
          that it owns or operates. A business can choose either $10,000 or
          $20,000 of coverage.
        </Typography>
      </FAQItem>

      <FAQItem question="Can someone have multiple properties covered by Jumpstart?">
        <Typography>
          Yes. You can purchase Jumpstart for any locations that you live in or
          own.
        </Typography>
      </FAQItem>

      <FAQItem question="Can I buy Jumpstart for someone else?">
        <Typography>
          Yes. The person paying the premium can be different from the person
          receiving the payment.
        </Typography>
      </FAQItem>

      <FAQItem question="Can a married couple or roommates each have separate coverage even if they live at the same location?">
        <Typography>Yes.</Typography>
      </FAQItem>

      <FAQItem question="How old does a person have to be to qualify for Jumpstart's coverage option?">
        <Typography>18 years or older.</Typography>
      </FAQItem>

      <FAQItem question="Can Jumpstart be purchased in addition to other earthquake insurance policies?">
        <Typography>
          Yes. Jumpstart can be purchased as additional coverage for someone who
          already has a conventional insurance policy. A Jumpstart policy can be
          a valuable complement to conventional earthquake insurance because it
          pays quickly, has no deductible, and covers any extra expense, not
          just property damage.
        </Typography>
      </FAQItem>

      <FAQItem question="When can I expect Jumpstart to be available in my state?">
        <Typography>
          We&apos;re available in California, Oregon, and Washington, and
          we&apos;re actively expanding to other states. Stay tuned and follow
          us on{" "}
          <Link href="https://www.facebook.com/yourjumpstart" target="_blank">
            Facebook
          </Link>{" "}
          and{" "}
          <Link href="https://twitter.com/yourjumpstart" target="_blank">
            Twitter
          </Link>
          .
        </Typography>
      </FAQItem>
    </div>
  );
};

const availabilityFaqs = {
  bounce: <BounceAvailabilityFaqs />,
  jumpstart: <JumpstartAvailabilityFaqs />,
};

const BounceClaimsFaqs = ({ config }) => {
  return (
    <div>
      <FAQItem question="What is the Fair Insurance Code?">
        <Typography paragraph>
          Bounce is committed to complying with the Fair Insurance Code as
          published by the Insurance Council of New Zealand.
        </Typography>
        <Typography>This means we will:</Typography>
        <Typography component="div">
          <ul>
            <li>
              provide insurance contracts which are understandable and show the
              legal rights and obligations of both us and you;
            </li>
            <li>explain the meaning of legal or technical words or phrases;</li>
            <li>
              explain the special meanings of words or phrases as they apply in
              the policy;
            </li>
            <li>manage claims quickly, fairly and transparently;</li>
            <li>
              clearly explain the reason(s) why a claim has been declined; and
            </li>
            <li>
              provide you with a written summary of our complaints procedure as
              soon as disputes arise and advise you how to lodge a complaint and
              tell you about the Insurance and Financial Services Ombudsman
              Scheme.
            </li>
          </ul>
        </Typography>
        <Typography>
          You can access a copy of the code{" "}
          <Link
            href="https://www.icnz.org.nz/fair-insurance-code/download-the-code/"
            target="_blank"
          >
            here
          </Link>
          .
        </Typography>
      </FAQItem>

      <FAQItem question="How do you determine payment eligibility?">
        <Typography paragraph>
          The intent of this policy is to cover financial losses and extra
          expenses resulting from an earthquake that exceeds the seismic
          trigger.
        </Typography>
        <Typography paragraph>
          To ensure fast, fair payments, we use seismic data from GeoNet. This
          data allows us to objectively identify areas where customers are
          highly likely to have extra costs as the result of a quake.
        </Typography>
        <Typography paragraph>
          Payment eligibility is based on shaking intensity. If your location is
          subject to shaking with a Peak Ground Velocity (PGV) of at least 20
          centimetres per second due to the earthquake event, then you are
          eligible to receive payment.
        </Typography>
        <Typography paragraph>
          To ensure responsive cover we apply a stepped payment. If shaking is
          equal to or greater than 20cm/sec but less than 25cm then we pay 10%,
          if the shaking is 25cm/sec but less than 30cm then we pay 40%, and if
          the shaking is 30cm/sec or greater then we pay 100% of sum insured
          amount.
        </Typography>
        <Typography>
          In New Zealand, our PGV threshold of 20cm/sec corresponds to a
          Modified Mercalli Intensity (MMI) of VII, and this is catagorised by
          GeoNet as a &quot;Severe&quot; earthquake. A severe earthquake is
          where people have difficulty standing, furniture moves, there is
          substantial damage to objects, and a few weak buildings are damaged.
        </Typography>
      </FAQItem>

      <FAQItem question="How much will you pay me?">
        <Typography paragraph>
          We recognise that earthquake events are complex systems and can result
          in a wide range of unexpected outcomes. We have applied a
          stepped-payout approach to helping you recover from an earthquake
          event based on the sum insured which is shown on your schedule. The
          amount that we will pay you following an earthquake will be calculated
          as follows:
        </Typography>
        <Typography component="div">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  Highest PGV measured at your nearest GeoNet sensor at any time
                  during the earthquake event:
                </TableCell>
                <TableCell>Less than 20cm/sec</TableCell>
                <TableCell>
                  Equal to or greater than 20cm/sec, but less than 25cm/sec
                </TableCell>
                <TableCell>
                  Equal to or greater than 25cm/sec, but less than 30cm/sec
                </TableCell>
                <TableCell>30cm/sec or higher</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  The amount we will pay to you as a percentage of the sum
                  insured shown on your schedule:
                </TableCell>
                <TableCell>0%</TableCell>
                <TableCell>10%</TableCell>
                <TableCell>40%</TableCell>
                <TableCell>100%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Typography>
      </FAQItem>

      <FAQItem question="How would Bounce have responded to past earthquake events?">
        <Typography paragraph>
          Analysis of past earthquakes highlights that our policy would have
          responded and provided comprehensive payouts in all the major
          earthquakes experienced by New Zealand over the past decade.
        </Typography>
        <Typography>
          <strong>Darfield Earthquake, 4 September 2010:</strong> This quake
          occurred at a depth of 11km (shallow) with a magnitude of 7.2, a
          velocity (PGV) of 94.5cm/s, and a MMI rating of X (Extreme). This
          caused widespread destruction of buildings, substantial displacement
          of land, and significant disruption. The shading is red to indicate
          exceeding 30cm/s, yellow for 25cm/s and green for 20cm/s. Bounce would
          have supported many customers in this event.
        </Typography>
        <Box p={3}>
          <Image aspectRatio={945 / 510} src={Darfield} />
        </Box>
        <Typography>
          <strong>Lyttleton Earthquake, 22 February 2011:</strong> This quake
          occurred at a depth of 6km (shallow) with a magnitude of 6.2, a
          velocity (PGV) of 97.3cm/s, and MMI rating of VIII (Extreme). This
          caused widespread destruction of buildings, substantial displacement
          of land, and significant disruption. The shading is red to indicate
          exceeding 30cm/s, yellow for 25cm/s and green for 20cm/s. Bounce would
          have supported many customers in this event.
        </Typography>
        <Box p={3}>
          <Image aspectRatio={945 / 482} src={Lyttleton} />
        </Box>
        <Typography>
          <strong>S.E Christchurch Earthquake, 13 June 2011:</strong> This quake
          occurred at a depth of 7km (shallow) with a magnitude of 6.0, a
          velocity (PGV) of 95.6cm/s, and an MMI rating of VIII (Extreme). This
          caused further destruction of buildings, some displacement of land,
          and further disruption. The shading is red to indicate exceeding
          30cm/s, yellow for 25cm/s and green for 20cm/s. Bounce would have
          supported many customers in this event.
        </Typography>
        <Box p={3}>
          <Image aspectRatio={945 / 482} src={SoutheastChristchurch} />
        </Box>
        <Typography>
          <strong>East Christchurch Earthquake, 23 December 2011:</strong> This
          quake occurred at a depth of 6km (shallow) with a magnitude of 5.8, a
          velocity (PGV) of 49.6cm/s, and an MMI rating of VIII (Extreme). The
          shading is red to indicate exceeding 30cm/s, yellow for 25cm/s and
          green for 20cm/s. Bounce would have supported many customers in this
          event.
        </Typography>
        <Box p={3}>
          <Image aspectRatio={945 / 731} src={EastChristchurch} />
        </Box>
        <Typography>
          <strong>The Kaikoura Earthquake, 13 November 2016:</strong> This quake
          occurred at a depth of 15km (shallow) with a magnitude of 7.8, a
          velocity (PGV) of 101.7cm/s, and an MMI rating of IX (Extreme). This
          caused widespread destruction of buildings, substantial displacement
          of land, and significant disruption through the Canterbury region,
          Marlborough region, and the Wellington region. The shading is red to
          indicate exceeding 30cm/s, yellow for 25cm/s and green for 20cm/s.
          Bounce would have supported many customers in this event.
        </Typography>
        <Box p={3}>
          <Image aspectRatio={945 / 895} src={Kaikoura} />
        </Box>
        <Typography>
          The map below provides a more granular view of policy response in
          Wellington region as a result of the Kaikoura Earthquake.
        </Typography>
        <Box pt={3} px={3}>
          <Image aspectRatio={945 / 875} src={KaikouraWellington} />
        </Box>
      </FAQItem>

      <FAQItem question="How does Peak Ground Velocity relate to seismic intensity?">
        <Typography paragraph>
          Our policy responds to earthquakes with movement of at least 20
          centimetres per second. This is equivalent to a “Strong” to “Severe”
          earthquake when compared against the GeoNet Simplified New Zealand MMI
          Scale.
        </Typography>
        <Typography paragraph>
          A strong earthquake is felt by all. People and animals are alarmed,
          and many run outside. Walking steadily is difficult. Furniture and
          appliances may move on smooth surfaces, and objects fall from walls
          and shelves. Glassware and crockery break. Slight non-structural
          damage to buildings may occur.
        </Typography>
        <Typography paragraph>
          A severe earthquake causes general alarm. People experience difficulty
          standing. Furniture and appliances are shifted. Substantial damage to
          fragile or unsecured objects. A few weak buildings are damaged.
        </Typography>
        <Typography>
          Refer to{" "}
          <a
            href="https://www.geonet.org.nz/earthquake/intensity"
            rel="noreferrer"
            target="_blank"
          >
            GeoNet
          </a>{" "}
          for more information.
        </Typography>
      </FAQItem>

      <FAQItem question="What’s so special about 20 cm/sec?">
        <Typography paragraph>
          To ensure fast, fair payments, we use data from GeoNet / GNS
          Science—the New Zealand government agency responsible for measuring
          earthquakes. Using GeoNet data allows us to objectively identify areas
          where customers are highly likely to have extra costs as the result of
          a quake.
        </Typography>
        <Typography>
          A quake with movement of 20 cm/sec is the point where there is likely
          to be some damage and extra costs because of a quake.
        </Typography>
      </FAQItem>

      <FAQItem question="Why not use Richter Magnitude?">
        <Typography>
          Using magnitude is quite localised and alone would exclude soft soil
          areas that are far away from the earthquake epicenter, but
          nevertheless could experience intense shaking. Using PGV makes sure
          areas like this get included for payment eligibility.
        </Typography>
      </FAQItem>

      <FAQItem question="How does the claims process work?">
        <Typography paragraph>
          The claim management process is activated by a notification from
          GeoNet strong motion sensors.
        </Typography>
        <Typography paragraph>
          After an earthquake occurs, if your location experiences the
          triggering shaking intensity, we send you a text message that tells
          you that “You’re eligible to a claim payment”.
        </Typography>
        <Typography paragraph>
          To receive a payment, you must complete the Loss Declaration procedure
          by responding to text messages initiated by us or by contacting us
          directly. To facilitate the Loss Declaration process, we will send you
          a text message to confirm that:
        </Typography>
        <Typography component="div">
          <ol>
            <li>You own, reside or work at the insured address; and</li>
            <li>
              You have suffered a covered loss as a result of the earthquake;
              and
            </li>
            <li>
              To the best of your knowledge and belief the covered loss will be
              at least equal to the amount that we pay you.
            </li>
          </ol>
        </Typography>
        <Typography>
          After you respond affirmatively, we initiate payment. We use text
          messaging because it&apos;s likely to be the first form of
          communication to start working after a disaster.
        </Typography>
      </FAQItem>

      <FAQItem question="What happens if I am unsure of my loss?">
        <Typography>
          If you are unable to complete the Loss Declaration because you do not
          believe that your loss and extra expenses will be equal to or more
          than the amount that we will pay you then please contact Bounce by
          emailing us (
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>
          ) or calling us (
          <Link href={`tel:${config.supportPhone}`}>{config.supportPhone}</Link>
          ). We will arrange a partial payment of the amount that you expect to
          incur based on your knowledge and belief at the time. If your loss
          turns out to be more, then we will simply make additional payments up
          to the maximum of your entitlement.
        </Typography>
      </FAQItem>

      <FAQItem question="What if text messages don't work after the earthquake?">
        <Typography>
          You can also provide your affirmative response by calling us (
          <Link href={`tel:${config.supportPhone}`}>{config.supportPhone}</Link>
          ); emailing us (
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>
          ); by logging into your account; or through Facebook messenger.
        </Typography>
      </FAQItem>

      <FAQItem question="Do I need to prove the value of my loss and/or expenses in order to receive payment?">
        <Typography paragraph>
          No. When you are eligible for payment and respond affirmatively to our
          Loss Declaration process that to the best of your knowledge and belief
          you expect your loss and additional expenses to be at least the value
          of our payment to you, then we authorise the payment.
        </Typography>
        <Typography>
          We do request that you keep any relevant receipts as evidence that
          funds were spent on your earthquake recovery.
        </Typography>
      </FAQItem>

      <FAQItem question="What if my losses and expenses turn out to be less than the amount that you pay me?">
        <Typography paragraph>
          Earthquakes big enough to trigger payments will also be big enough to
          cause all sorts of unanticipated expenses, some of which won&apos;t
          become apparent until much later.
        </Typography>
        <Typography paragraph>
          The policy (and the cover amount) has not been designed to insure
          people against all losses they may suffer due to an earthquake. Based
          on our statistical analysis, we chose a payment amount that&apos;s
          typically a fraction of what most people will need.
        </Typography>
        <Typography>
          However, if your expenses and losses are less than the amount that we
          have paid you then please contact us to discuss. We may request that
          you return the excess funds to us if it is obvious that all your
          additional expenses and losses will not reach the amount paid.
        </Typography>
      </FAQItem>

      <FAQItem question="How does Bounce work with other policies I may have?">
        <Typography>
          Your traditional house and contents insurance policies are designed to
          cover significant loss, but Bounce is different. The Bounce insurance
          product is designed to support your financial resilience by assisting
          you with immediate cash flow for those expenses which you are unable
          to recover from other insurance policies. A Bounce cash payment
          following an insured earthquake event is paid in days and helps with
          any immediate financial losses or additional expenses that you may
          have as you start your journey towards recovery. A Bounce policy does
          not replace or duplicate the cover provided by other policies, but
          rather can be a useful complement to traditional insurance because it
          pays quickly, has no excess or deductible, and covers any extra
          expense, not just property damage.
        </Typography>
      </FAQItem>

      <FAQItem question="What else should I know?">
        <Typography component="div">
          <ul className="mtn">
            <li>Payment eligibility is based on shaking intensity.</li>
            <li>
              Specifically, if your location is subject to shaking with a Peak
              Ground Velocity (PGV) of at least 20 centimetres per second due to
              the earthquake event, as determined on the basis of data from the
              nearest GeoNet strong motion sensor, then you are eligible to file
              a claim.
            </li>
            <li>
              Data used to define payment eligibility are as reported by GeoNet
              usually within 24 hours after occurrence.
            </li>
            <li>
              You are entitled to claim for more than one earthquake event which
              may occur during the policy period, but the absolute maximum we
              will pay you for all earthquake events is the Sum Insured shown on
              your schedule.
            </li>
          </ul>
        </Typography>
        <Typography>
          See{" "}
          <Link href="/sample-policy" target="_blank">
            full policy
          </Link>{" "}
          for detailed terms.
        </Typography>
      </FAQItem>
    </div>
  );
};

BounceClaimsFaqs.propTypes = {
  config: PropTypes.object,
};

const JumpstartClaimsFaqs = ({ config }) => {
  return (
    <div>
      <FAQItem question="How do you determine payment eligibility?">
        <Typography paragraph>
          To ensure fast, fair payments, we use data from the United States
          Geological Survey, or USGS—the US government agency responsible for
          measuring earthquakes and a leading authority on seismic activity
          around the world.
        </Typography>
        <Typography paragraph>
          This data allows us to objectively identify areas where customers are
          highly likely to have extra costs as the result of a quake.
        </Typography>
        <Typography paragraph>
          Payment eligibility is based on shaking intensity. Specifically,{" "}
          <Link
            href={`${config.blogUrl}/earthquake-insurance-payout-zone/`}
            target="_blank"
          >
            locations that are coded on the USGS shake map as experiencing
            &quot;severe&quot; shaking
          </Link>
          –peak ground velocity of 30 centimeters per second or more—are
          eligible to receive payment.
        </Typography>
        <Typography>
          The basis of determining payment eligibility is the USGS data
          available 24 hours after the quake occurs.
        </Typography>
      </FAQItem>

      <FAQItem question="What's so special about Peak Ground Velocity?">
        <Typography paragraph>
          Peak Ground Velocity (PGV) measures shaking intensity, as opposed to
          strict magnitude. PGV is more accurate because it captures the
          increased shaking intensity in soft-soil areas.
        </Typography>
        <Typography paragraph>
          We chose a PGV threshold of shaking that&apos;s intense enough to
          cause disruption - enough to &quot;mess life up&quot; and create extra
          expenses - but still low enough that it&apos;s plausible.
        </Typography>
        <Typography paragraph>
          In California, our PGV threshold of 30 cm/sec corresponds to a
          Modified Mercalli Intensity of VII-1/2 which is roughly equivalent to
          the red zone on a USGS ShakeMap.
        </Typography>
        <Typography paragraph>
          In Oregon and Washington, our PGV threshold of 20 cm/sec corresponds
          to a Modified Mercalli Intensity of VI-1/2 which is roughly equivalent
          to the yellow-orange zone on a USGS ShakeMap.
        </Typography>
        <Typography>
          For shallow earthquakes, the smallest Richter Magnitude to cause this
          level of shaking is about M6. As a point of reference, the M 6.0 Napa
          earthquake in 2014 would have reached the{" "}
          <Link
            href={`${config.blogUrl}/earthquake-insurance-payout-zone/`}
            target="_blank"
          >
            30cm/sec trigger in most of central Napa
          </Link>
          .
        </Typography>
      </FAQItem>

      <FAQItem question="Why not use Richter Magnitude?">
        <Typography paragraph>
          Using magnitude alone would exclude soft soil areas that are far away
          from the earthquake epicenter, but nevertheless could experience
          intense shaking.
        </Typography>
        <Typography paragraph>
          This was the case in the 1989 Magnitude 6.9 Loma Prieta earthquake.
          The epicenter was near Santa Cruz, but some of the hardest-hit areas
          were 90 km away, in parts of San Francisco and Oakland that are built
          on soft soils.
        </Typography>
        <Typography>
          Using PGV makes sure areas like this get included for payment
          eligibility.
        </Typography>
      </FAQItem>

      <FAQItem question="How does the claims process work?">
        <Typography>
          After an earthquake occurs, if your location experiences the
          triggering shaking intensity, we send you a text message that asks if
          you expect to experience extra expenses. After you respond
          affirmatively, we initiate payment. We use text messaging because
          it&apos;s likely to be the first form of communication to start
          working after a disaster.
        </Typography>
      </FAQItem>

      <FAQItem question="What if text messages don't work after the earthquake?">
        <Typography>
          You can also provide your affirmative response by calling us (
          <Link href={`tel:${config.supportPhone}`}>{config.supportPhone}</Link>
          ); emailing us (
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>
          ); by logging into your account; through Facebook messenger; or by
          sending us a letter. You have 60 days to respond after the earthquake.
        </Typography>
      </FAQItem>

      <FAQItem question="Do I need to prove $10,000 of expenses in order to receive payment?">
        <Typography>
          No. When you are eligible for payment and respond affirmatively, we
          authorize the full payment.
        </Typography>
      </FAQItem>

      <FAQItem question="What if my expenses turn out to be less than $10k?">
        <Typography paragraph>
          Earthquakes big enough to trigger payments will also be big enough to
          cause all sorts of unanticipated expenses, some of which won&apos;t
          become apparent until much later.
        </Typography>
        <Typography>
          Based on our statistical analysis, we chose a payment amount
          that&apos;s a fraction of what most people will need. The payment is
          small enough that it&apos;s very unlikely to &quot;make you
          whole.&quot; It&apos;s only intended as a jumpstart (thus the name of
          our company).
        </Typography>
      </FAQItem>

      <FAQItem question="What else should I know?">
        <ul className="mtn">
          <li>Payment eligibility is based on shaking intensity.</li>
          <li>
            Specifically, you will be eligible to claim payment as follows:
            <p>
              In California, if{" "}
              <Link
                href={`${config.blogUrl}/earthquake-insurance-payout-zone/`}
                target="_blank"
              >
                your location on the USGS ShakeMap is in an area of
                &quot;severe&quot; shaking
              </Link>
              —peak ground velocity of 30 centimeters per second or more.
            </p>
            <p>
              In Oregon and Washington, if your location on the USGS ShakeMap is
              in an area of &quot;very strong&quot; shaking—peak ground velocity
              of 20 centimeters per second or more.
            </p>
          </li>
          <li>
            Data used to define payment eligibility are as reported by USGS 24
            hours after occurrence.
          </li>
          <li>
            Payments require your confirmation that you have or will experience
            extra expenses or loss due to the earthquake of $10,000 or more.
          </li>
          <li>
            If you receive a payment from a main shock, you won&apos;t be
            eligible to receive an additional payment in an aftershock, even if
            that aftershock meets the aforementioned criteria.
          </li>
        </ul>
        <Typography>
          See{" "}
          <Link href="/sample-policy" target="_blank">
            full policy
          </Link>{" "}
          for detailed terms.
        </Typography>
      </FAQItem>

      <FAQItem question="How do I make a complaint?">
        <Typography paragraph>
          We would love an opportunity to resolve any issue you might be having.
          Call us at{" "}
          <Link href={`tel:${config.supportPhone}`}>{config.supportPhone}</Link>
          , tap on the{" "}
          <Link onClick={showIntercom} style={{ cursor: "pointer" }}>
            chat
          </Link>{" "}
          box, or email us at{" "}
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>
          . If you need to file a formal complaint with the Department of
          Insurance, instructions for doing so can be found on the relevant
          website.
        </Typography>
        <ul className="mtn">
          <li>
            <Link
              href="http://www.insurance.ca.gov/01-consumers/101-help/"
              target="_blank"
            >
              California
            </Link>
          </li>
          <li>
            <Link
              href="https://dfr.oregon.gov/help/complaints-licenses/Pages/file-complaint.aspx"
              target="_blank"
            >
              Oregon
            </Link>
          </li>
          <li>
            <Link
              href="https://www.insurance.wa.gov/consumer-complaint-center-contact-us"
              target="_blank"
            >
              Washington
            </Link>
          </li>
        </ul>
      </FAQItem>
    </div>
  );
};

JumpstartClaimsFaqs.propTypes = {
  config: PropTypes.object,
};

const claimsFaqs = (config) => {
  return {
    bounce: <BounceClaimsFaqs config={config} />,
    jumpstart: <JumpstartClaimsFaqs config={config} />,
  };
};

const WhoIsLloydsFAQItem = () => {
  return (
    <FAQItem question="Who is Lloyd's?">
      <Typography paragraph>
        Lloyd’s is the world’s leading insurance and reinsurance marketplace.
        Through the collective intelligence and risk-sharing expertise of the
        market’s underwriters and brokers, Lloyd’s helps to create a braver
        world.
      </Typography>
      <Typography paragraph>
        The Lloyd’s market provides the leadership and insight to anticipate and
        understand risk, and the knowledge to develop relevant, new and
        innovative forms of insurance for customers globally.
      </Typography>
      <Typography paragraph>
        It offers the efficiencies of shared resources and services in a
        marketplace that covers and shares risks from more than 200 territories,
        in any industry, at any scale.
      </Typography>
      <Typography paragraph>
        And it promises a trusted, enduring partnership built on the confidence
        that Lloyd’s protects what matters most: helping people, businesses and
        communities to recover in times of need.
      </Typography>
      <Typography paragraph>
        Lloyd’s began with a few courageous entrepreneurs in a coffee shop.
        Three centuries later, the Lloyd’s market continues that proud
        tradition, sharing risk in order to protect, build resilience and
        inspire courage everywhere.
      </Typography>
      <Typography paragraph>
        For information on the financial strength ratings of Lloyd’s, please{" "}
        <Link
          href="https://www.lloyds.com/about-lloyds/investor-relations/ratings/"
          target="_blank"
        >
          click here
        </Link>
        .
      </Typography>
      <Typography>
        For more information on Lloyd’s, visit{" "}
        <Link href="https://www.lloyds.com/" target="_blank">
          www.lloyds.com
        </Link>
        .
      </Typography>
    </FAQItem>
  );
};

const BounceTeamFaqs = () => {
  return (
    <div>
      <FAQItem question="What is the Bounce story?">
        <Typography paragraph>
          Bounce was founded in 2020 by Paul Barton. Paul comes from an
          insurance background and has spent years working with claim teams and
          customers impacted by earthquake events in New Zealand. Paul saw the
          financial hardship and stress that Kiwis faced when trying to re-build
          their lives after a severe earthquake. This prompted Paul to think
          about a smarter way for insurance to be more responsive to the needs
          of Kiwis after an earthquake. Bounce is based on the belief that quick
          recovery is key to mitigate financial hardship and minimise emotional
          stress for individuals and their families, and the wider community.
          From this belief Bounce was formed with a focus on promoting
          individual, and community recovery, by paying claims within days of an
          earthquake event.
        </Typography>
        <Typography>
          Today we are a small team of highly committed specialists.
        </Typography>
      </FAQItem>

      <FAQItem question="What's your mission?">
        <Typography>
          Bounce’s mission is &quot;to increase economic stimulus following an
          earthquake.” Basically, we&apos;re trying to get more money flowing
          into our communities when we need it most.
        </Typography>
      </FAQItem>

      <FAQItem question="What’s your purpose?">
        <Typography>
          Bounce exists to provide Kiwis with a financial boost after an
          earthquake. We created Bounce for Kiwis impacted by an earthquake
          because we saw that there is often a lag between the earthquake event
          and when the insurance payout is made and this creates financial
          hardship and emotional stress.
        </Typography>
      </FAQItem>

      <FAQItem question="Who issues the policy?">
        <Typography paragraph>
          Bounce is a Lloyd’s of London coverholder and holds a binding
          authority agreement to provide earthquake insurance policies to New
          Zealand.
        </Typography>
        <Typography>
          The good news for policy holders is that their policy is 100%
          underwritten at Lloyd’s of London (by the Lloyd’s Syndicate 1840).
        </Typography>
      </FAQItem>

      <WhoIsLloydsFAQItem />

      <FAQItem question="Why doesn’t Bounce have a financial strength rating?">
        <Typography>
          As a Coverholder for Lloyd’s of London, Bounce benefits from a
          partnership with Lloyds through Lloyd’s Syndicate 1840. Lloyd’s
          syndicates benefit from Lloyd’s brand and ratings, its network of
          global insurance licences, and its central fund, which is available at
          the discretion of the Council of Lloyd’s to meet any valid claim that
          cannot be met by the resources of the member. As all Lloyd’s policies
          are ultimately backed by this common security, a single market rating
          can be applied. For information on the financial strength ratings of
          Lloyd’s, please{" "}
          <Link
            href="https://www.lloyds.com/about-lloyds/investor-relations/ratings/"
            target="_blank"
          >
            click here
          </Link>
          .
        </Typography>
      </FAQItem>
    </div>
  );
};

const JumpstartTeamFaqs = () => {
  return (
    <div>
      <FAQItem question="Who makes Jumpstart?">
        <Typography paragraph>
          Jumpstart was founded in 2015 by Kate Stillwell, whose career is all
          about improving our resilience as a society.
        </Typography>
        <Typography paragraph>
          Kate is a former president of Structural Engineers Association of
          Northern California and longtime fellow at the Earthquake Engineering
          Research Institute.
        </Typography>
        <Typography>
          Today we are a small team of highly committed specialists and licensed
          insurance agents, based in Oakland, California.
        </Typography>
      </FAQItem>

      <FAQItem question="Who issues the policy?">
        <Typography>
          Jumpstart is a surplus lines insurance broker working in full
          compliance with applicable insurance regulations. The insurance
          described here is effected with certain Underwriters at Lloyd&apos;s,
          London.
        </Typography>
      </FAQItem>

      <WhoIsLloydsFAQItem />

      <FAQItem question="What's your mission?">
        <Typography>
          Jumpstart&apos;s mission is &quot;to increase economic stimulus
          following natural disasters such as earthquakes.” Basically,
          we&apos;re trying to get more money flowing into our communities when
          we need it most.
        </Typography>
      </FAQItem>

      <FAQItem question="What does being a B-Corporation mean?">
        <Typography paragraph>
          Jumpstart is a Benefit Corporation founded with the social mission to
          increase financial resilience of our communities.
        </Typography>
        <Typography>
          We exist to increase economic stimulus after a natural disaster -
          it&apos;s in our corporate DNA (literally our articles of
          incorporation)—we are here to get more money into people&apos;s hands
          when they need it most.
        </Typography>
      </FAQItem>

      <FAQItem question="How secure is my information?">
        <Typography paragraph>
          We use the strongest browser encryption available, and store all of
          our data on servers in a secure facility.
        </Typography>
        <Typography>
          We protect the privacy of your information and will never share your
          data with any third party without your permission. For more
          information, please review our{" "}
          <Link component={RouterLink} to="/privacy-policy">
            privacy policy
          </Link>
          .
        </Typography>
      </FAQItem>
    </div>
  );
};

const teamFaqs = {
  bounce: <BounceTeamFaqs />,
  jumpstart: <JumpstartTeamFaqs />,
};

const BounceSupportFaqs = ({ config }) => {
  return (
    <div>
      <FAQItem question="Can I view a sample policy?">
        <Typography>
          See{" "}
          <Link href="/sample-policy" target="_blank">
            full policy
          </Link>{" "}
          for detailed terms.
        </Typography>
      </FAQItem>

      <FAQItem question="Where can I get proof of coverage?">
        <Typography paragraph>
          We email your electronic policy when you sign up and again each year.
          In addition, it&apos;s available in your account status. Simply{" "}
          <Link href="/login" target="_blank">
            log in
          </Link>
          . From there, you can print out a PDF if you need a paper copy.
        </Typography>
      </FAQItem>

      <FAQItem question="Can I change insured person, location, credit card, bank account, email, and mobile info on an existing coverage?">
        <Typography paragraph>
          Yes,{" "}
          <Link href="/login" target="_blank">
            log in
          </Link>{" "}
          and navigate to Your{" "}
          <Link href="/account" target="_blank">
            Account
          </Link>{" "}
          page to update information.
        </Typography>
        <Typography>
          Please note, that changing the insured person or location will result
          in a new policy that replaces the current policy. For a new location,
          the new premium amount may differ.
        </Typography>
      </FAQItem>

      <FAQItem question="Do I need to renew my policy every year?">
        <Typography>
          Unless you tell us otherwise, your policy will renew with no action
          required from you. We will send you an annual reminder that your
          policy will renew.
        </Typography>
      </FAQItem>

      <FAQItem question="How do I cancel coverage?">
        <Typography>
          You can cancel at any time with no penalty. Simply call us at{" "}
          <Link href={`tel:${config.supportPhone}`}>{config.supportPhone}</Link>{" "}
          or click on the{" "}
          <Link onClick={showIntercom} style={{ cursor: "pointer" }}>
            Chat
          </Link>{" "}
          box at the bottom right corner of this page. We will cancel your
          policy that day and issue a pro-rated refund.
        </Typography>
      </FAQItem>

      <FAQItem question="I have a question. How can I reach you?">
        <Typography>
          Click on the{" "}
          <Link onClick={showIntercom} style={{ cursor: "pointer" }}>
            Chat
          </Link>{" "}
          box at the bottom right corner of this page, or email us at{" "}
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>{" "}
          and we&apos;ll respond quickly.
        </Typography>
      </FAQItem>

      <FAQItem question="How secure is my information?">
        <Typography paragraph>
          We use the strongest browser encryption available, and store all of
          our data on servers in a secure facility.
        </Typography>
        <Typography>
          We protect the privacy of your information and will never share your
          data with any third party without your permission. For more
          information, please review our{" "}
          <Link component={RouterLink} to="/privacy-policy">
            privacy policy
          </Link>
          .
        </Typography>
      </FAQItem>
    </div>
  );
};

BounceSupportFaqs.propTypes = {
  config: PropTypes.object,
};

const JumpstartSupportFaqs = ({ config }) => {
  return (
    <div>
      <FAQItem question="Can I view a sample policy?">
        <Typography>
          See{" "}
          <Link href="/sample-policy" target="_blank">
            full policy
          </Link>{" "}
          for detailed terms.
        </Typography>
      </FAQItem>

      <FAQItem question="Where can I get proof of coverage?">
        <Typography paragraph>
          We email your electronic policy when you sign up and again each year.
          In addition, it&apos;s available in your account status. Simply{" "}
          <Link href="/login" target="_blank">
            log in
          </Link>
          . From there, you can print out a PDF if you need a paper copy.
        </Typography>
      </FAQItem>

      <FAQItem question="Can I change insured person, location, credit card, bank account, email, and mobile info on an existing coverage?">
        <Typography paragraph>
          Yes,{" "}
          <Link href="/login" target="_blank">
            log in
          </Link>{" "}
          and navigate to Your{" "}
          <Link href="/account" target="_blank">
            Account
          </Link>{" "}
          page to update information.
        </Typography>
        <Typography>
          Please note, that changing the insured person or location will result
          in a new policy that replaces the current policy. For a new location,
          the new premium amount may differ.
        </Typography>
      </FAQItem>

      <FAQItem question="Do I need to renew my policy every year?">
        <Typography>
          Unless you tell us otherwise, your policy will renew with no action
          required from you. We will send you an annual reminder that your
          policy will renew.
        </Typography>
      </FAQItem>

      <FAQItem question="How do I cancel coverage?">
        <Typography>
          You can cancel at any time with no penalty. Simply call us at{" "}
          <Link href={`tel:${config.supportPhone}`}>{config.supportPhone}</Link>{" "}
          or click on the{" "}
          <Link onClick={showIntercom} style={{ cursor: "pointer" }}>
            Chat
          </Link>{" "}
          box at the bottom right corner of this page. We will cancel your
          policy that day and issue a pro-rated refund.
        </Typography>
      </FAQItem>

      <FAQItem question="I have a question. How can I reach you?">
        <Typography>
          Click on the{" "}
          <Link onClick={showIntercom} style={{ cursor: "pointer" }}>
            Chat
          </Link>{" "}
          box at the bottom right corner of this page, or email us at{" "}
          <Link href={`mailto:${config.supportEmail}`} target="_blank">
            {config.supportEmail}
          </Link>{" "}
          and we&apos;ll respond quickly.
        </Typography>
      </FAQItem>
    </div>
  );
};

JumpstartSupportFaqs.propTypes = {
  config: PropTypes.object,
};

const supportFaqs = (config) => {
  return {
    bounce: <BounceSupportFaqs config={config} />,
    jumpstart: <JumpstartSupportFaqs config={config} />,
  };
};

const FAQ = ({ location }) => {
  const config = useContext(ConfigContext);
  const classes = useStyles();

  return (
    <Box align="center">
      <Typography component="h1" variant="h3">
        What is {config.productName}?
      </Typography>
      <Box my={5}>
        <Divider />
      </Box>
      <Box align="left">
        {intros[config.tenantSlug]}

        <HeaderCTA headerName="General" />
        {generalFaqs[config.tenantSlug]}

        <HeaderCTA headerName="Availability" />
        {availabilityFaqs[config.tenantSlug]}

        <HeaderCTA headerName="Claims/Payments" />
        {claimsFaqs(config)[config.tenantSlug]}

        <HeaderCTA headerName="Our Team" />
        {teamFaqs[config.tenantSlug]}

        <HeaderCTA headerName="Support" />
        {supportFaqs(config)[config.tenantSlug]}
      </Box>
    </Box>
  );
};

FAQ.propTypes = {
  location: PropTypes.object,
};

export default FAQ;
