import React, {useContext} from 'react'
import {Box, Typography, Divider, Link} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ConfigContext from '../config-context'
import {Link as RouterLink} from 'react-router-dom'

const Complaints = () => {
  const config = useContext(ConfigContext)
  const classes = useStyles()

  return (
    <Box align="center" className={classes.root} py={10}>
      <Typography variant="h3">
        How to make a complaint
      </Typography>
      <Box my={5}>
        <Divider/>
      </Box>
      <Box align="left">
        <Typography paragraph>
          We aim to provide you with the highest quality service and support. But if you do have a complaint or encounter a problem, please let us know as soon as you can.
        </Typography>
        <Typography paragraph>
          Bounce Insurance Limited (Bounce) is a coverholder with the Lloyd’s of London. Lloyd’s is a member of the Insurance Council of NZ and its New Zealand coverholders adhere to the Fair Insurance Code, which provides you with assurance that we have high standards of service to our customers.
        </Typography>
        <Typography paragraph>
          Our complaints process outlined below aims to address your concerns:
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          Stage One
        </Typography>
        <Typography paragraph>
          Any enquiry or complaint relating to a Lloyd’s policy or claim should be addressed to either Bounce Insurance Limited (“the Coverholder”) or to the administrator handling Your claim in the first instance – in most cases this will resolve Your grievance.
        </Typography>
        <Typography paragraph>
          Please raise your complaint by contacting Bounce at <Link href={`mailto:${config.supportEmail}`} target="_blank">{config.supportEmail}</Link>, or calling us at <Link href={`tel:${config.supportPhone}`}>{config.supportPhone}</Link>, or <RouterLink to="/login" target="_blank">logging in</RouterLink> to your account with us.
        </Typography>
        <Typography paragraph>
          They will respond to Your complaint within 10 business days provided They have all necessary information and have completed any investigation required. Where further information, assessment or investigation is required, They will agree to reasonable alternative timeframes with You. You will also be kept informed of the progress of Your complaint.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          Stage Two
        </Typography>
        <Typography paragraph>
          In the unlikely event that this does not resolve the matter or You are not satisfied with the way Your complaint has been dealt with, You should contact:
        </Typography>
        <Typography paragraph>
          Lloyd&apos;s Underwriters&apos; General Representative in New Zealand
        </Typography>
        <Typography paragraph>
          Mr Scott Galloway<br/>
          C/O - Hazelton Law<br/>
          PO Box 5639<br/>
          Wellington New Zealand<br/>
          Telephone: +64 4 472 7582<br/>
          Email: <Link href="mailto:idrnz@lloyds.com" target="_blank">idrnz@lloyds.com</Link>
        </Typography>
        <Typography paragraph>
          When You lodge your dispute with us, we will usually require the following information:
        </Typography>
        <Typography component="ul" paragraph>
          <li>Name, address and telephone number of the policyholder;</li>
          <li>Details of the policy concerned (policy and/or claim reference numbers, etc);</li>
          <li>Details of the insurance intermediary through whom the policy was obtained;</li>
          <li>Reasons why You are dissatisfied;</li>
          <li>Copies of any supporting documentation You believe may assist us in addressing Your dispute appropriately.</li>
        </Typography>
        <Typography paragraph>
          Following receipt of Your complaint, You will be advised whether Your dispute will be handled by Lloyd’s Australia or the Lloyd’s Complaints team in the UK, or what other avenues are available to You:
        </Typography>
        <Typography component="ul" paragraph>
          <li>Where Your complaint is eligible for referral to the Insurance & Financial Services Ombudsman (IFSO),  Your complaint will generally be reviewed by a person at Lloyd’s Australia with appropriate authority to deal with Your dispute.</li>
          <li>Where Your complaint is not eligible for referral to the IFSO, Lloyd’s Australia will refer Your complaint to the Lloyd’s Complaints team in the UK if it falls within the jurisdiction of the UK Financial Ombudsman Service, who will review Your complaint and will liaise directly with You.</li>
        </Typography>
        <Typography paragraph>
          For all other matters You will be advised of what other avenues may be available to You.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          How Long Will the Stage Two Process Take?
        </Typography>
        <Typography paragraph>
          Your complaint will be acknowledged in writing within 5 business days of receipt, and You will be kept informed of the progress of our review of Your complaint at least every 10 business days.
        </Typography>
        <Typography paragraph>
          The length of time required to resolve a particular dispute will depend on the individual issues raised, however in most cases You will receive a full written response to Your complaint within 10 business days of receipt, provided we have received all necessary information and have completed any investigation required.
        </Typography>
        <Typography paragraph>
          If the matter cannot be resolved at stage two and/or it has been more than two months since the complaint was made, You will be advised of the reasons for this via a &apos;deadlock&apos; letter and advised of Your right to elevate the matter to IFSO.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          External Dispute Resolution
        </Typography>
        <Typography paragraph>
          If Your complaint is not resolved in a manner satisfactory to You or we do not resolve Your complaint within two months of receiving it at Stage 1, You may refer the matter to IFSO. IFSO can be contacted by post PO Box 10-845, Wellington, phone 0800 888 202 or +64 4 499 7612, email <Link href="mailto:info@ifso.nz" target="_blank">info@ifso.nz</Link> or via their website www.ifso.nz.
        </Typography>
        <Typography paragraph>
          IFSO is an independent body that operates nationally in New Zealand and aims to resolve disputes between You and Your insurer. Your dispute must be referred to IFSO within 3 months of the date of our deadlock letter. Determinations made by IFSO are binding upon us.
        </Typography>
        <Typography paragraph>
          Clients not eligible for referral to IFSO, may be eligible for referral to the Financial Ombudsman Service (UK). Such referral must occur within 6 months of the final decision by the Complaints team at Lloyd&apos;s. Further details will be provided with their final decision to You.
        </Typography>
        <Typography variant="h4" component="h2" paragraph>
          You can contact Lloyd’s in the UK at:
        </Typography>
        <Typography paragraph>
          Complaints<br/>
          Fidentia House<br/>
          Walter Burke Way<br/>
          Chatham Maritime<br/>
          Chatham<br/>
          Kent<br/>
          ME4 4RN<br/>
          Telephone: +44 (0)20 7327 5693<br/>
          Email: <Link href="complaints@lloyds.com" target="_blank">complaints@lloyds.com</Link>
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
})

export default Complaints
