import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";

const ScrollableCard = ({ children }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.scrollableTextArea}>
      {children}
    </Card>
  );
};

ScrollableCard.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  scrollableTextArea: {
    height: 200,
    overflow: "scroll",
  },
}));

export default ScrollableCard;
