import React, {useContext, useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import {Paper} from "@material-ui/core"
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from 'react-router-dom'
import ConfigContext from '../../config-context'
import Address from './steps/address'
import {useStateMachine} from 'little-state-machine'
import {setUser} from '../../actions/user'
import PropertyType from './steps/property-type'
import Price from './steps/price'
import {getPreferredProductPrice} from '../../lib/product-prices'
import Checkout from './steps/checkout'
import Done from './steps/done'

const ChangeAddressApp = () => {
  const config = useContext(ConfigContext)
  const history = useHistory()
  const {action, state} = useStateMachine(setUser)
  const { policyNumber } = useParams()
  const { path, url } = useRouteMatch()
  const [address, setAddress] = useState(null)
  const [checkoutPrice, setCheckoutPrice] = useState(null)
  const [productPrice, setProductPrice] = useState(null)
  const [productPrices, setProductPrices] = useState(null)
  const [endorsement, setEndorsement] = useState(null)

  let policy = state.user.policies.find(p => p.number === policyNumber)
  policy = {...policy, address: state.user.addresses.find(a => a.id === policy.address.id)}

  const [coverageLimit, setCoverageLimit] = useState(policy.coverageLimit)

  const onAddressSubmitSuccess = (address) => {
    setAddress(address)
    history.push(`/account/coverage/${policyNumber}/change-address/2`)
  }

  const onEndorsementSubmitSuccess = (endorsement) => {
    // Will force transition to done step via useEffect
    setEndorsement(endorsement)

    // Update user state
    const endorsements = [...policy.endorsements, endorsement]
    const updatedPolicy = {...policy, endorsements}
    const policyIndex = state.user.policies.map(p => p.number).indexOf(policyNumber)
    let policies = [...state.user.policies]
    policies.splice(policyIndex, 1, updatedPolicy)

    action({...state.user, policies })
  }

  const onPropertyTypeSubmitSuccess = (address) => {
    setAddress(address)
  }

  const handleCoverageChange = (event) => {
    const productId = event.target.value
    const productPrice = productPrices.filter(p => p.productId === productId)[0]
    const coverageLimit = productPrice.coverageLimit
    const preferredProductPrice = getPreferredProductPrice(
      config.pricing,
      productPrices,
      address.addressType,
      coverageLimit
    )

    setCoverageLimit(coverageLimit)
    setProductPrice(preferredProductPrice)
  }

  useEffect(() => {
    if (endorsement) {
      history.push(`/account/coverage/${policyNumber}/change-address/5`)
    }
  }, [endorsement])

  if (!endorsement && location.pathname === `/account/coverage/${policyNumber}/change-address/5`) {
    return <Redirect to={`/account/coverage`} />
  }

  if (endorsement && location.pathname !== `/account/coverage/${policyNumber}/change-address/5`) {
    return <Redirect to={`/account/coverage`} />
  }

  if (!address && location.pathname !== `/account/coverage/${policyNumber}/change-address/1`) {
    return <Redirect to={`/account/coverage/${policyNumber}/change-address/1`} />
  }

  return (
    <Paper elevation={0} variant="outlined">
      <Box p={3}>
        <Switch>
          <Route
            path={`${path}/1`}
            exact
            render={() =>
                <Address
                  address={address}
                  onSubmitSuccess={onAddressSubmitSuccess}
                  policy={policy}
                  user={state.user}
                />
            }
          />
          <Route
            path={`${path}/2`}
            exact
            render={() =>
              <PropertyType
                address={address}
                onSubmitSuccess={onPropertyTypeSubmitSuccess}
              />
            }
          />
          <Route
            path={`${path}/3`}
            exact
            render={() =>
              <Price
                policy={policy}
                productPrice={productPrice}
              />
            }
          />
          <Route
            path={`${path}/4`}
            exact
            render={() =>
              <Checkout
                address={address}
                onSubmitSuccess={onEndorsementSubmitSuccess}
                handleCoverageChange={handleCoverageChange}
                policy={policy}
                productPrice={productPrice}
                productPrices={productPrices}
                user={state.user}
                checkoutPrice={checkoutPrice}
              />
            }
          />
          <Route
            path={`${path}/5`}
            exact
            render={() =>
              <Done
                endorsement={endorsement}
              />
            }
          />
        </Switch>
      </Box>
    </Paper>
  )
}

export default ChangeAddressApp