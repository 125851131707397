import React, { useContext } from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import ConfigContext from "../config-context";

export const TextMaskCustom = (props) => {
  const config = useContext(ConfigContext);
  const { inputRef, ...other } = props;

  let mask = config.phoneMask.map((el) => {
    const regexp = /\/(.*)\//;
    const match = el.match(regexp);
    if (match && match[1]) {
      return new RegExp(match[1]);
    } else {
      return el;
    }
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
      guide={false}
      showMask
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func,
};