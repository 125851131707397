import {createContext} from 'react'

// configContext
const ConfigContext = createContext({
  errorStatusCode: null,
  errorWithStatusCode: null,
  complaints: false,
  supportPhone: null,
  supportEmail: null,
  productName: "Jumpstart",
  latLngBounds: null,
  redirectFaq: null,
  marketingHost: null,
  tenantSlug: null,
  blogUrl: null,
  fairInsuranceCode: null,
  disclaimerText: null,
  licenseTextInline: false,
  licenseText: "",
  brokersNav: false,
  phoneMask: null,
  countryCallingCode: "+1",
  phonePattern: "",
  seo: {
    default: null,
  },
  pricing: {
    RESIDENTIAL: "max",
    residential: "max",
    business: "max",
    BUSINESS: "max",
    commercial: "max"
  },
  enableSamplePolicy: false,
  postalCodeName: "Zip Code",
  postalCodePattern: null,
  priceSearchText: null,
});

export default ConfigContext
