import React from 'react'
import PropTypes from "prop-types";
import Step from '../components/step'
import PropertyTypeForm from 'components/property-type-form'

const PropertyType = ({address, onSubmitSuccess}) => {
  return (
    <Step title="What type of address are you insuring?">
      <PropertyTypeForm
        address={address}
        onSubmitSuccess={onSubmitSuccess}
      />
    </Step>
  )
}

PropertyType.propTypes = {
  address: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
}

export default PropertyType