export const formatPhone = (phone, formatStr, countryCallingCode) => {
  var cleaned = phone.replace(new RegExp(`^\\+${countryCallingCode}`), '')
  cleaned = ("" + cleaned).replace(/\D/g, "");

  for ( var i = 0, l = cleaned.length; i < l; i++ ) {
    formatStr = formatStr.replace("N", cleaned[i])
  }

  return formatStr.replace("N", '')
}
