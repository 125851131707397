import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { setOnboarding } from "actions/onboarding";
import { getSession, setSession } from "actions/session";
import { setUser } from "actions/user";
import { setQuote } from "actions/quote";
import Slide from "onboarding/components/slide";
import { PATHS } from "../onboarding-app";
import UserForm from "../../components/user-form";
import { useClientRequest, useClientMutation } from "api";
import { Box, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { HelpAlert } from "components/help-alert";

const Name = ({ history, nextPath }) => {
  const { actions, state } = useStateMachine({
    setOnboarding,
    setSession,
    setUser,
    setQuote,
  });
  const location = useLocation();
  const toPath = location.state?.toPath ?? nextPath;
  const [error, setError] = useState(null);
  const [screenError, setScreenError] = useState(null);

  const { loading: sessionLoading } = useClientRequest(
    "/api/v2/auth/getSession",
    {
      name: "get_session",
      onCompleted: (data) => {
        actions.setSession(data);
      },
    }
  );

  const {
    request: updateSession,
    loading: updateSessionLoading,
    error: updateSessionError,
  } = useClientMutation("/api/v2/jumpstart/setSessionData", {
    name: "set_session",
    method: "POST",
    onCompleted: (data) => {
      // onSubmitSuccess(user);
      actions.setSession(data);
    },
    onError: (e) => {
      setScreenError(e.toString());
    },
  });

  // UPDATE quote
  const {
    request: updateQuote,
    loading: updateQuoteLoading,
  } = useClientMutation("/api/v4/direct/quotes", {
    method: "PUT",
    name: "update_quote",
    onCompleted: (data) => {
      if (!data.quote_number) {
        return;
      }

      actions.setQuote(data);
      history.push(toPath);
    },
    onError: (e) => {
      setScreenError(e.toString());
    },
  });

  const onCompleted = async (user) => {
    if (!user) {
      return;
    }

    // Track lead
    window.fbq && window.fbq("track", "Lead");
    window.gtag && window.gtag("event", "sign_up", { method: "email" });
    window.heap && window.heap.track("Lead");
    window.heap &&
      window.heap.addUserProperties({ "Zip Code": state.onboarding.quoteZip });

    try {
      await updateSession({
        body: {
          ...getSession(state),
          ...user,
        },
      });

      actions.setUser({
        id: state.session?.id,
        ...user,
      });

      actions.setOnboarding({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        userId: state.session?.id,
        ackTerms: true,
      });

      if (state.quote?.quote_number) {
        await updateQuote({
          body: {
            ...state.quote,
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
          },
        });
      } else {
        history.push(toPath);
      }
    } catch (e) {
      setError(typeof e === "string" ? e : e.toString());
    }
  };

  const onUserAlreadyExists = (email) => {
    history.push({ pathname: PATHS.LOGIN, state: { email: email } });
  };

  // TODO: move into a user key in onboarding
  const user = {
    id: state.onboarding.userId,
    email: state.onboarding.email,
    firstName: state.onboarding.firstName,
    lastName: state.onboarding.lastName,
  };

  const loading = useMemo(() => {
    return updateQuoteLoading || updateSessionLoading || sessionLoading;
  }, [updateQuoteLoading, updateSessionLoading, sessionLoading]);

  return (
    <Slide
      data-test="contact__title"
      title={`Who is the primary contact for this policy?`}
    >
      <UserForm
        onSubmitSuccess={onCompleted}
        onUserAlreadyExists={onUserAlreadyExists}
        showAckTerms
        submitText="Next"
        user={user}
        loading={loading}
        ackTerms={state.onboarding.ackTerms}
        error={error}
      />
      <Box display="flex" flexDirection="column" maxWidth={640} mx="auto" mt={4}>
        {screenError && (
          <>
            <Box>
              <Alert severity="error">
                <>
                  <AlertTitle>Error</AlertTitle>
                  <Typography>{screenError}</Typography>
                </>
              </Alert>
            </Box>
            <Box my={2}>
              <HelpAlert />
            </Box>
          </>
        )}
      </Box>
    </Slide>
  );
};

Name.propTypes = {
  history: PropTypes.object,
  nextPath: PropTypes.string,
};

export default Name;
