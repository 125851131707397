import React, {useEffect} from 'react'
import PropTypes from "prop-types";
import {useStateMachine} from 'little-state-machine'
import {Redirect} from 'react-router-dom'
import {setOnboarding} from 'actions/onboarding'
import {PATHS} from '../onboarding-app'

const BuildingTypeDeeplink = ({ history, location, addressType }) => {
  const { actions, state } = useStateMachine({setOnboarding})

  useEffect(() => {
    actions.setOnboarding({
        address: {
          ...state.onboarding.address,
          addressType
        },
        coverageLimit: null,
        productId: null,
        productPrices: null,
        checkoutPrice: null
      })
  })

  return <Redirect to={ { pathname: `/${PATHS.BASE}`, state: location.state } } />
}

BuildingTypeDeeplink.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  addressType: PropTypes.string,
}

export default BuildingTypeDeeplink