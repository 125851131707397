import { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { numberToCurrency } from "../lib/money";
import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";

export const price = (checkoutPrice, coupon, interval) => {
  const _interval = interval.toLowerCase();

  if (coupon) {
    return checkoutPrice[`discounted${capitalize(_interval)}lyTotal`];
  } else {
    return checkoutPrice[`${_interval}lyTotal`];
  }
};

const useStyles = makeStyles((theme) => ({
  discountedPrice: {
    paddingRight: theme.spacing(1),
  },
  regularPrice: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    textDecoration: "line-through",
  },
}));

const PriceLabel = ({
  checkoutPrice,
  coupon,
  interval,
  showDiscountTerms,
  transactionFee,
  verbose,
}) => {
  const classes = useStyles();
  const _interval = interval.toLowerCase();

  const label = useMemo(() => {
    const _interval = interval.toLowerCase();
    const priceValue = price(checkoutPrice, null, _interval);
    const priceLabel = numberToCurrency(
      transactionFee ? transactionFee + priceValue : priceValue,
      {
        truncateZeroCents: true,
      }
    );
    const feeLabel = numberToCurrency(transactionFee);

    if (verbose) {
      switch (_interval) {
        case "month":
          return `${priceLabel}/${_interval} (includes a ${feeLabel} credit card convenience fee), 12 monthly charges, cancel any month`;
        case "year":
          return `${priceLabel}/${_interval} (includes a ${feeLabel} credit card convenience fee), charged now, full year of coverage`;
      }
    }

    return `${priceLabel}/${_interval}`;
  }, [checkoutPrice, interval, verbose, transactionFee]);

  // if (!coupon) {
  //   return (
  //     <span>
  //       {numberToCurrency(price(checkoutPrice, null, _interval), {
  //         truncateZeroCents: true,
  //       })}
  //       /{_interval}
  //     </span>
  //   );
  // }

  if (!coupon) {
    return <span data-test="price-label">{label}</span>;
  }

  return (
    <span>
      <span className={classes.regularPrice} color="text.secondary">
        {numberToCurrency(price(checkoutPrice, null, _interval), {
          truncateZeroCents: true,
        })}
      </span>
      <span className={classes.discountedPrice}>
        {numberToCurrency(price(checkoutPrice, coupon, _interval), {
          truncateZeroCents: true,
        })}
        /{_interval}
      </span>
      {showDiscountTerms && <span>({checkoutPrice.discountTerms})</span>}
    </span>
  );
};

PriceLabel.propTypes = {
  checkoutPrice: PropTypes.object,
  coupon: PropTypes.object,
  interval: PropTypes.string,
  showDiscountTerms: PropTypes.bool,
  verbose: PropTypes.bool,
  transactionFee: PropTypes.number,
};

export default PriceLabel;
